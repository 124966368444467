<template>
	<v-text-field
		v-model="kilometraje"
		:label="labelKilometraje"
		type="number"
		suffix="Km"
		hide-spin-buttons
		clearable
        placeholder="Kilometraje"
        append-outer-icon="mdi-refresh"
		:loading="kilometrajeMinLoading"
		:disabled="disabled || kilometrajeMinLoading"
		:error-messages="errorKilometraje"
        @click:append-outer="getUltimoKilometrajeReportado()"
		@focus="errorKilometraje = null"
	></v-text-field>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: String,
            default: null
        },
        idMaquinaInstancia: {
            type: Number,
            default: null
        },
        kilometrajeMin: {
            type: Number,
            default: null,
        },
        loadingKilometrajeMin: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        kilometrajeMinLoading: {
            get(){
                return this.loadingKilometrajeMin;
            },
            set(newValue){
                this.$emit('update:loadingKilometrajeMin',newValue);
            }
        },
        errorKilometraje: {
            get(){
                return this.errorMessages
            },
            set(newValue){
                this.$emit('update:errorMessages',newValue);
            }
        },
        minKilometraje: {
            get(){
                return this.kilometrajeMin;
            },
            set(newValue){
                this.$emit('update:kilometrajeMin',newValue);
            }
        },
        kilometraje: {
            get(){
                return this.value;
            },
            set(newValue){
                this.$emit('input',newValue);
            }
        },
        labelKilometraje(){
			return this.minKilometraje > 0
				? `Kilometraje mínimo: ${this.$utils.strings.formatearCifra(this.minKilometraje)}`
				: "Kilometraje";
		},
    },
    mounted(){
        this.getUltimoKilometrajeReportado();
    },
    methods: {
        getUltimoKilometrajeReportado(idMaquinaInstancia = this.idMaquinaInstancia){
			if(!idMaquinaInstancia) return;
			this.kilometrajeMinLoading = true;

			axios
				.get(`/Mantenimientos/GetUltimoKilometrajeReportado`,{
					params: { idMaquinaInstancia }
				})
				.then( (response) => {
					this.minKilometraje = response.data;
				})
				.catch( (error) => {
					console.log(error);
				})
				.finally( () => {
					this.kilometrajeMinLoading = false;
				});
		},
    }
};
</script>