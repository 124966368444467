<template>
	<v-dialog
		v-model="value"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card color="grey lighten-4">
			<v-toolbar dark color="primary">
				<v-btn icon dark @click="$emit('input', false)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title class="white--text"
					>Proyeccion de Máquinas en Tiempo Real</v-toolbar-title
				>
			</v-toolbar>
			<v-card-text>
				<v-container fluid>
					<template v-if="loading">
						<v-row>
							<v-col
								cols="12"
								class="
									d-flex
									flex-column
									align-center
									justify-center
								"
								style="height: 80vh"
							>
								<v-progress-circular
									:size="90"
									:width="7"
									color="primary"
									indeterminate
								></v-progress-circular>
								<p class="text-h4 text--secondary">Cargando</p>
							</v-col>
						</v-row>
					</template>
					<template v-if="!loading">
						<v-row>
							<v-col
								v-for="(dato, index) in datos"
								:key="index"
								class="d-flex flex-column py-2 px-1"
							>
								<OEEMaquinaCardaDummy
									:id="`card-${index}`"
									:key="`maquina-card-${index}`"
									:data="dato"
								/>
							</v-col>
						</v-row>
					</template>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import OEEMaquinaCardaDummy from "./Proyeccion/OEEMaquinaCardDummy.vue";

export default {
	components: { OEEMaquinaCardaDummy },
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			statusColors: [
				{
					id: 1,
					color: "green",
					apexCode: "#0b2e0e",
				},
				{
					id: 2,
					color: "red",
					apexCode: "#470d0d",
				},
				{
					id: 3,
					color: "blue",
					apexCode: "#0d1747",
				},
			],
			defaultChartOptions: {
				chart: {
					type: "radialBar",
				},
				colors: [],
				plotOptions: {
					radialBar: {
						hollow: {
							size: "65%",
						},
						dataLabels: {
							show: false,
						},
					},
				},
			},
			tiposStatus: [
				{
					grupoStatus: 1,
					text: "Ciclo Normal"
				},
				{
					grupoStatus: 2,
					text: "Sin Material"
				},
				{
					grupoStatus: 2,
					text: "Frenos"
				},
				{
					grupoStatus: 1,
					text: "Sobreproducción"
				},
				{
					grupoStatus: 2,
					text: "Paro de Emergencia"
				},
				{
					grupoStatus: 3,
					text: "Inactivo"
				},
			],
			datos: [],
		};
	},
	watch: {
		value() {
			if (this.value) {
				this.initialize();
			}
		},
	},
	methods: {
		getRandomNumber: (maximo,minimo) => Math.floor(Math.random() * (maximo - minimo + 1)) + minimo,
		noTrim: (num) => ("0"+num).slice(-2),
		initialize() {
			this.getDatos();
		},
		getColorStatus(idStatus) {
			return (
				this.statusColors.find(
					(statusColor) => statusColor.id == idStatus
				) || this.statusColors[2]
			);
		},
		procesarArregloRespuesta(arreglo) {
			arreglo.forEach((item) => {
				item.chartOptions = JSON.parse(
					JSON.stringify(this.defaultChartOptions)
				);
				const colorStatus = this.getColorStatus(item.status);
				item.color = colorStatus.color;
				item.chartOptions.colors.push(colorStatus.apexCode);
			});
		},
		getRandomStatus(idStatus){
			const tiposStatusFiltrados = this.tiposStatus.filter( tipo => tipo.grupoStatus == idStatus );
			const randomIndex = this.getRandomNumber(tiposStatusFiltrados.length - 1,0);
			return tiposStatusFiltrados[randomIndex].text;
		},
		randomGenerator(cantidad = 1) {
			const dummy = [];
			for (let index = 0; index < cantidad; index++) {
				const idStatus = this.getRandomNumber(this.statusColors.length,1);
				const obj = {
					idMaquina: index + 1,
					nombreMaquina: `CNC ${
						this.getRandomNumber(200,40)
					}`,
					tiempo: `${
						this.getRandomNumber(200,10)
					}m`,
					status: idStatus,
					oee: [this.getRandomNumber(100,50)],
					cantidad: this.getRandomNumber(1200,100),
					unidad: "Piezas",
					detalles: [
						{
							title: "Status",
							text: this.getRandomStatus(idStatus),
						},
						{
							title: "Duración",
							text: `00:${this.noTrim(this.getRandomNumber(59,5))}`,
						},
						{
							title: "Fecha Inicio",
							text: "02/10 09:51",
						},
						{
							title: "Orden de Trabajo",
							text: `1${this.getRandomNumber(1000,2999)}`,
						},
						{
							title: "Artículo",
							text: "Poste Estanteria 221",
						},
						{
							title: "Fase",
							text: "Subensamble Poste",
						},
						{
							title: "PPH %",
							text: this.getRandomNumber(99,10),
						},
						{
							title: "Uso %",
							text: this.getRandomNumber(99,10),
						},
					],
					intervalo: ["12:00","12:30"],
					chartOptions: {}
				};
				dummy.push(obj);
			}

			return dummy;
		},
		getDatos() {
			this.loading = true;
			setTimeout(() => {
				const response = this.randomGenerator(12);/* [
					{
						idMaquina: 1,
						nombreMaquina: "CNC 190",
						tiempo: "32m",
						status: 1,
						oee: [70],
						cantidad: 280,
						unidad: "Piezas",
						detalles: [
							{
								title: "Status",
								text: "Ciclo Normal",
							},
							{
								title: "Duración",
								text: "00:30",
							},
							{
								title: "Fecha Inicio",
								text: "02/10 09:51",
							},
							{
								title: "Orden de Trabajo",
								text: "12095",
							},
							{
								title: "Artículo",
								text: "Poste Estanteria 221",
							},
							{
								title: "Fase",
								text: "Subensamble Poste",
							},
							{
								title: "PPH %",
								text: "12",
							},
							{
								title: "Uso %",
								text: "54",
							},
						],
						intervalo: ["09:00", "09:30"],
						chartOptions: {},
					},
				]; */
				this.procesarArregloRespuesta(response);
				this.datos = response;
				this.loading = false;
			}, 2000);
		},
	},
};
</script>