import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"color":"grey lighten-4"}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,{staticClass:"white--text"},[_vm._v("Proyeccion de Máquinas en Tiempo Real")])],1),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[(_vm.loading)?[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"height":"80vh"},attrs:{"cols":"12"}},[_c(VProgressCircular,{attrs:{"size":90,"width":7,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"text-h4 text--secondary"},[_vm._v("Cargando")])],1)],1)]:_vm._e(),(!_vm.loading)?[_c(VRow,_vm._l((_vm.datos),function(dato,index){return _c(VCol,{key:index,staticClass:"d-flex flex-column py-2 px-1"},[_c('OEEMaquinaCardaDummy',{key:("maquina-card-" + index),attrs:{"id":("card-" + index),"data":dato}})],1)}),1)]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }