<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones', 'r')">
		<v-dialog v-model="dialogDesglose" persistent max-width="1300px">
			<v-card>
				<v-card-title>Detalles de la Inspección</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<p class="text-subtitle-1">Comprobaciones</p>
								<ComprobacionesRegistros
									v-if="dialogDesglose"
									:comprobaciones="editedItem.comprobaciones"
									:loading="loading"
								/>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" md="12" sm="12">
								<div class="text-subtitle-1">Recomendaciones</div>
								<v-textarea
									auto-grow
									rows="1"
									hide-details
									readonly
									outlined
									:value="editedItem.recomendacion || 'N/A'"
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text color="success" @click="cerrarDesglose">
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idsMantenimientosInspeccion"
					label="Código o Nombre"
					:items="inspeccionesNombreCodigo"
					:item-text="nombreInspeccion"
					item-value="idMantenimientoInspeccion"
					hide-details
					clearable
					multiple
					small-chips
					deletable-chips
					:disabled="loading"
					@change="getInspecciones(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idMantenimientoCategoria"
					label="Categoría Mantenimiento"
					:items="categoriasMantenimiento"
					item-text="nombre"
					item-value="idMantenimientoCategoria"
					hide-details
					clearable
					:disabled="loading"
					@change="getInspecciones(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idMaquinaCategoria"
					:items="categorias"
					item-value="idMaquinaCategoria"
					item-text="categoria"
					label="Categoría Máquina"
					hide-details
					clearable
					:disabled="loading"
					@change="onChangeCategoria"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idMaquina"
					label="Modelo"
					:items="maquinasFiltradas"
					:item-text="marcaModelo"
					item-value="idMaquina"
					:menu-props="{
						maxWidth: 'min-content'
					}"
					hide-details
					clearable
					:disabled="loading || loadingMaquinas"
					:loading="loadingMaquinas"
					@change="changeMaquina"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
							<v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
							<v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="3">
				<MaquinaInsAutocomplete
					v-model="filtros.idMaquinaIns"
					:items="instanciasFiltradas"
					item-value="idMaquinaIns"
					item-text="nombre"
					label="Máquina"
					clearable
					hide-details
					:disabled="loading || loadingInstancias"
					:loading="loadingInstancias"
					@change="getInspecciones(1)"
				/>
			</v-col>
			<v-col cols="3">
				<v-autocomplete
					v-model="filtros.idResponsable"
					:items="personas"
					:item-text="nombrePersona"
					item-value="idPersona"
					label="Responsable"
					clearable
					hide-details
					:disabled="loading"
					@change="getInspecciones(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<v-select
					v-model="filtros.tipoAsignacion"
					label="Tipo de Asignación"
					:items="$utils.mantenimientos.tiposAsignacion"
					hide-details
					clearable
					:disabled="loading"
					@change="onChangeTipoAsignacion"
				></v-select>
			</v-col>
			<v-col cols="3" class="pt-4">
				<v-autocomplete
					v-model="filtros.realizadoPor"
					:items="getItemsRealizadoPor"
					:item-text="getTextRealizadoPor"
					:item-value="getValueRealizadoPor"
					label="Realizado Por"
					clearable
					hide-details
					multiple
					small-chips
					deletable-chips
					:placeholder="filtros.tipoAsignacion == null ? 'Seleccione un tipo de asignación' : null"
					:no-data-text="filtros.tipoAsignacion == null ? 'Seleccione un tipo de asignación' : $vuetify.noDataText"
					:disabled="loading"
					@change="getInspecciones(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="3">
				<VueCtkDateTimePicker
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					style="padding-top: 7px"
					@input="getInspecciones(1)"
				/>
			</v-col>
			<v-col cols="3">
				<VueCtkDateTimePicker
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					style="padding-top: 7px"
					@input="getInspecciones(1)"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" class="py-0">
				<v-btn
					color="blue darken-1"
					text
					:disabled="loading || !esDescargable || downloading"
					@click="descargarReporte"
				>
					Descargar Reporte de <br />
					Inspecciones Realizadas
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:headers="headersFiltrados"
					:items="inspecciones"
					:loading="loading"
					loading-text="Cargando... Espere, Por Favor."
					:server-items-length="inspecciones_total"
					:items-per-page="inspecciones_items_per_page"
					:page="inspecciones_actual_page"
					@update:page="getInspecciones"
					@update:items-per-page="
						(ipp) => (inspecciones_items_per_page = ipp)
					"
					:footer-props="{
						disableItemsPerPage: true,
						disablePagination: this.loading,
					}"
					:disable-sort="true"
				>
					<template v-slot:[`item.tipoAsignacion`]="{ item }">
						{{ $utils.mantenimientos.getNombreTipoAsignacion(item.tipoAsignacion) }}
					</template>
					<template v-slot:[`item.realizadoPor`]="{ item }">
						<template v-if="item.tipoAsignacion == 1">
							<ul class="py-1">
								<li
									v-for="(mecanico, idx) in item.mecanicos"
									:key="'mec-' + idx"
									style="font-size: 14px !important"
									:class="{'pb-1': item.mecanicos.length > 1}"
								>
									{{ mecanico.nombre }} - ${{mecanico.costo.toFixed(2)}}
								</li>
							</ul>
						</template>
						<template v-else>
							{{ $utils.mantenimientos.findNombreProveedor(item.idMantenimientoProveedor,proveedores) }}
						</template>
					</template>
					<template v-slot:[`item.horasTrabajadas`]="{ item }">
						{{ item.horasTrabajadas == null ? "N/A" : $utils.strings.formatearCifra(item.horasTrabajadas) }}
					</template>
					<template v-slot:[`item.kilometraje`]="{ item }">
						{{ item.kilometraje == null ? "N/A" : $utils.strings.formatearCifra(item.kilometraje) }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									:disabled="loading"
									@click="verDesglose(item)"
								>
									mdi-eye
								</v-icon>
							</template>
							<span class="white--text">Ver Detalles</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import MaquinaInsAutocomplete from './MaquinaInsAutocomplete.vue';
import ComprobacionesRegistros from './ComprobacionesRegistros.vue';
import qs from "qs";

export default {
	components: { MaquinaInsAutocomplete, ComprobacionesRegistros },
	data() {
		return {
			inspeccionesNombreCodigo: [],
			proveedores: [],
			personas: [],
			categorias: [],
			categoriasMantenimiento: [],
			downloading: false,
			inspecciones_total: 0,
			inspecciones_items_per_page: 10,
			inspecciones_actual_page: 1,
			loading: false,
			loadingInstancias: false,
			loadingMaquinas: false,
			instancias: [],
			maquinas: [],
			filtros: {
				idMaquina: null,
				idMaquinaIns: null,
				inicio: null,
				fin: null,
				idMantenimientoCategoria: null,
				idMaquinaCategoria: null,
				idResponsable: null,
				tipoAsignacion: null,
				realizadoPor: [],
				idsMantenimientosInspeccion: []
			},
			headerComprobaciones: [
				{
					text: "Comprobación",
					align: "center",
					sort: false,
					value: "comprobacion",
				},
				{
					text: "Satisfactoria",
					align: "center",
					sort: false,
					value: "satisfactoria",
				},
				{
					text: "Comentario",
					align: "center",
					sort: false,
					value: "comentario",
				},
				{
					text: "Evidencias",
					align: "center",
					sort: false,
					value: "documentos",
				},
				{
					text: "Reparación",
					align: "center",
					sort: false,
					value: "reparacion",
				},
			],
			inspecciones: [],
			headers: [
				{
					text: "Código",
					align: "center",
					sort: false,
					value: "codigo",
					width: "150px",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "nombre",
					width: "150px",
				},
				{
					text: "Categoría Mantenimiento",
					align: "center",
					sortable: false,
					value: "categoriaMantenimiento",
					width: "150px",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
					width: "150px",
				},
				{
					text: "Fin",
					align: "center",
					sort: false,
					value: "fin",
					width: "150px",
				},
				{
					text: "Categoría Máquina",
					align: "center",
					sortable: false,
					value: "categoriaMaquina",
					width: "150px",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquina",
					width: "150px",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
					width: "150px",
				},
				{
					text: "Tipo Asignación",
					align: "center",
					sort: false,
					value: "tipoAsignacion",
					width: "150px",
				},
				{
					text: "Realizado Por",
					align: "center",
					sort: false,
					value: "realizadoPor",
					width: "150px",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
					width: "10%"
				},
			],
			dialogDesglose: false,
			editedItem: null,
			defaultEditedItem: {
				comprobaciones: [],
			},
		};
	},
	computed: {
		getValueRealizadoPor(){
			if(this.filtros.tipoAsignacion == 1) return 'idPersona';
			else if(this.filtros.tipoAsignacion == 2) return 'idMantenimientoProveedor';
			else return 'value';
		},
		getTextRealizadoPor(){
			if(this.filtros.tipoAsignacion == 1) return this.nombrePersona;
			else if(this.filtros.tipoAsignacion == 2) return this.$utils.mantenimientos.getNombreProveedor;
			else return 'text';
		},
		getItemsRealizadoPor(){
			if(this.filtros.tipoAsignacion == null) return [];
			if(this.filtros.tipoAsignacion == 1) return this.personas;
			if(this.filtros.tipoAsignacion == 2) return this.proveedores;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		headersFiltrados(){
			if(!this.esIndasa) return this.headers;
			let finalHeaders = this.headers.slice();
			const indexInsert = finalHeaders.findIndex( header => header.value == 'realizadoPor' );
			finalHeaders.splice(indexInsert + 1, 0, {
				text: "Horas Trabajadas",
				align: "center",
				sort: false,
				value: "horasTrabajadas",
				width: "150px",
			},{
				text: "Kilometraje",
				align: "center",
				sort: false,
				value: "kilometraje",
				width: "150px",
			});
			return finalHeaders;
		},
		esDescargable() {
			return this.inspecciones.length > 0;
		},
		instanciasFiltradas() {
			let instancias = this.instancias.slice();
			if(this.filtros.idMaquinaCategoria != null){
				instancias = instancias.filter( (instancia) => instancia.idMaquinaCategoria == this.filtros.idMaquinaCategoria );
			}
			if (this.$utils.isValid(this.filtros.idMaquina)) {
				instancias = instancias.filter((instancia) => instancia.idMaquina == this.filtros.idMaquina);
			}
			return instancias;
		},
		maquinasFiltradas() {
			if (this.$utils.isValid(this.filtros.idMaquinaCategoria)) {
				if (this.filtros.idMaquinaCategoria == 0) {
					return this.instancias.filter((instancia) => instancia.idMaquinaCategoria == null);
				} else {
					return this.instancias.filter((instancia) => instancia.idMaquinaCategoria == this.filtros.idMaquinaCategoria );
				}
			}
			else return this.instancias;
		},
		...mapState(["token","nombreEmpresa"]),
	},
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreInspeccion: ({ codigo, nombre }) => `[${codigo}] - ${nombre}`.trim(),
		marcaModelo: ({ marca, modelo }) => `${marca} - ${modelo}`,
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		onChangeTipoAsignacion(){
			this.filtros.realizadoPor = [];
			this.getInspecciones(1);
		},
		onChangeCategoria() {
			this.filtros.idMaquina = null;
			this.filtros.idMaquinaIns = null;
			this.getInspecciones(1);
		},
		descargarReporte() {
			const datetime = moment().format("YYYYMMDDHHmmss");
			this.downloading = true;
			axios({
				url: "/Mantenimientos/ReporteXLSXGetInspeccionesTerminadas",
				params: {
					...this.filtros,
					token: this.token,
				},
				method: "GET",
				responseType: "blob",
			})
				.then((response) => {
					let blob = new Blob([response.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					let a = document.createElement("a");
					let blobURL = URL.createObjectURL(blob);
					a.download = `reporte-inspecciones-realizadas-${datetime}.xlsx`;
					a.href = blobURL;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.downloading = false;
				});
		},
		changeMaquina() {
			this.filtros.idMaquinaIns = null;
			this.getInspecciones(1);
		},
		cerrarDesglose() {
			this.dialogDesglose = false;

			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
			}, 250);
		},
		verDesglose(item) {
			this.editedItem = structuredClone(item);

			this.dialogDesglose = true;
		},
		initialize() {
			this.getInspecciones(1);
			this.getMaquinas();
			this.getMaquinaInstancias();
			this.getProveedoresMantenimiento();
			this.getCategoriasMantenimiento();
			this.getPersonas();
			this.getInspeccionesNombreCodigo();
		},
		getInspeccionesNombreCodigo(){
			axios
				.get('/Mantenimientos/GetInspeccionesNombreCodigo')
				.then( (response) => {
					this.inspeccionesNombreCodigo = response.data;
				})
				.catch( (error) => {
					console.log(error);
				});
		},
		getPersonas() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getCategoriasMantenimiento(){
			axios
				.get('/MantenimientoCategorias')
				.then( (response) => {
					this.categoriasMantenimiento = response.data;
				})
				.catch( (error) => {
					this.categoriasMantenimiento = [];
					console.log(error);
				});
		},
		getProveedoresMantenimiento(){
			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch( (error) => {
					this.proveedores = [];
					console.log(error);
				});
		},
		getMaquinaInstancias() {
			this.loadingInstancias = true;
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					this.instancias = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingInstancias = false;
				});
		},
		getMaquinas() {
			this.loadingMaquinas = true;

			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((m) => m.esMaquina);
					this.categorias = this.maquinas.map((elemento) => ({
						idMaquinaCategoria:
							elemento.idMaquinaCategoria == null
								? 0
								: elemento.idMaquinaCategoria,
						categoria: elemento.categoria,
					}));
				})
				.catch(() => {
					this.maquinas = [];
					console.log(error);
				})
				.finally(() => {
					this.loadingMaquinas = false;
				});
		},
		getInspecciones(page) {
			this.inspecciones_actual_page = page;
			this.loading = true;
			axios
				.get("/Mantenimientos/GetInspeccionesTerminadas", {
					params: {
						...this.filtros,
						paginar: true,
						page: this.inspecciones_actual_page,
						size: this.inspecciones_items_per_page,
					},
					paramsSerializer: (params) =>
						qs.stringify(params, { indices: false, skipNulls: true }),
				})
				.then((response) => {
					this.inspecciones = response.data.result;
					this.inspecciones_total = response.data.total;
				})
				.catch((error) => {
					this.inspecciones = [];
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>