<template>
  <!-- <v-container> -->
  <v-row justify-sm="center">
    <v-card class="mt-4" :width="anchoMaximo" fill>
      <v-tabs
        show-arrows
        v-model="tab"
        :background-color="colorTabs"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab
          href="#tab-1"
          v-if="mostrarVista('puestos')"
          v-tooltip.bottom="modoAyuda && $help.rh.puestos"
          @click="updatePuestos()"
        >
          Puestos
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-tab>

        <v-tab
          v-if="mostrarVista('departamentos')"
          href="#tab-2"
          v-tooltip.bottom="modoAyuda && $help.rh.departamentos"
          @click="updateDepartamentos()"
        >
          Departamentos
          <v-icon>mdi-account-supervisor</v-icon>
        </v-tab>

        <v-tab
          href="#tab-3"
          v-if="mostrarVista('horarios')"
          v-tooltip.bottom="modoAyuda && $help.rh.horarios"
          @click="updateHorarios()"
        >
          Horarios
          <v-icon>mdi-clock</v-icon>
        </v-tab>

        <v-tab
          href="#tab-4"
          v-if="mostrarVista('turnos')"
          v-tooltip.bottom="modoAyuda && $help.rh.turnos"
          @click="updateTurnos()"
        >
          Turnos
          <v-icon>mdi-account-clock</v-icon>
        </v-tab>

        <v-tab
          href="#tab-5"
          v-if="mostrarVista('habilidades')"
          v-tooltip.bottom="modoAyuda && $help.rh.habilidades"
          @click="updateHabilidades()"
        >
          Habilidades
          <v-icon>mdi-brain</v-icon>
        </v-tab>

        <v-tab
          href="#tab-6"
          v-if="mostrarVista('equipospredefinidos')"
          v-tooltip.bottom="modoAyuda && $help.rh.equipospredefinidos"
          @click="updateEquiposPredefinidos()"
        >
          Equipos Predefinidos<br />de Operadores
          <v-icon>mdi-account-group</v-icon>
        </v-tab>

        <v-tab
          href="#tab-7"
          v-if="mostrarVista('vendedores')"
          v-tooltip.bottom="modoAyuda && $help.rh.vendedores"
          @click="updateVendedores()"
        >
          Vendedores
          <v-icon>mdi-briefcase</v-icon>
        </v-tab>

        <v-tab
          href="#tab-8"
          v-if="mostrarVista('operadores')"
          v-tooltip.bottom="modoAyuda && $help.rh.operadores"
          @click="updateOperadores()"
        >
          Operadores
          <v-icon>mdi-account-circle</v-icon>
        </v-tab>

        <v-tab
          href="#tab-9"
          v-if="mostrarVista('personas')"
          v-tooltip.bottom="modoAyuda && $help.rh.personas"
          @click="updatePersonas()"
        >
          Personas
          <v-icon>mdi-account-group</v-icon>
        </v-tab>

        <v-tab
        v-if="
          mostrarVista('brigadas') ||
          mostrarVista('brigadap') ||
          mostrarVista('brigadape') ||
          mostrarVista('briarbol')
        "  
        v-tooltip.bottom="modoAyuda && $help.rh.brigadasrh.title"
        href="#tab-10"
        @click="updateBrigadas()"
        >
          Brigadas
          <v-icon>mdi-ambulance</v-icon>
        </v-tab>

        <v-tab
          href="#tab-11"
          v-if="mostrarVista('organigrama')"
          v-tooltip.bottom="modoAyuda && $help.rh.organigrama"
          @click="updateOrganigrama()"
        >
          Organigrama
          <v-icon>mdi-sitemap</v-icon>
        </v-tab>

        <v-tab
          v-if="mostrarVista('interfazrelojchecador')"
          href="#tab-12"
          v-tooltip.bottom="modoAyuda && $help.rh.relojchecador"
          @click="updatePersonasRelojChecador()"
        >
          Interfaz con<br>Reloj Checador
          <v-icon>mdi-check-network-outline</v-icon>
        </v-tab>

        <v-tab
          v-if="mostrarVista('descansooperadores')"
          href="#tab-13"
          v-tooltip.bottom="modoAyuda && $help.rh.descansoOperadores"
        >
          Descanso<br>Operadores
          <v-icon>mdi-bed-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="'tab-1'" v-if="mostrarVista('puestos')">
          <v-card flat>
            <v-card-text>
              <RHPuesto ref="puestos"></RHPuesto>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="2" :value="'tab-2'">
          <v-card flat>
            <v-card-text>
              <ListaDepartamentos ref="departamentos" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="3" :value="'tab-3'" v-if="mostrarVista('horarios')">
          <v-card flat color="gray">
            <v-card-text>
              <configuracionHorario ref="horarios"></configuracionHorario>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="4" :value="'tab-4'" v-if="mostrarVista('turnos')">
          <v-card flat color="gray">
            <v-card-text>
              <configuracionTurno ref="turnos"></configuracionTurno>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          :key="5"
          :value="'tab-5'"
          v-if="mostrarVista('habilidades')"
        >
          <v-card flat color="gray">
            <v-card-text>
              <configuracionHabilidad
                ref="habilidades"
              ></configuracionHabilidad>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          :key="6"
          :value="'tab-6'"
          v-if="mostrarVista('equipospredefinidos')"
        >
          <v-card flat>
            <v-card-text>
              <recursosEquiposPredefinidos
                ref="equipospredefinidos"
              ></recursosEquiposPredefinidos>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="7" :value="'tab-7'" v-if="mostrarVista('vendedores')">
          <v-card flat>
            <v-card-text>
              <VentasVendedores ref="vendedores"></VentasVendedores>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="8" :value="'tab-8'" v-if="mostrarVista('operadores')">
          <v-card flat>
            <v-card-text>
              <recursosOperario ref="operarios"></recursosOperario>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="9" :value="'tab-9'" v-if="mostrarVista('personas')">
          <v-card flat>
            <v-card-text>
              <RHPersona ref="personas"></RHPersona>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="10" :value="'tab-10'">
          <v-card flat>
            <v-card-text>
              <Brigadas ref="brigadas"></Brigadas>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item :key="11" :value="'tab-11'" v-if="mostrarVista('organigrama')">
          <v-card flat>
            <v-card-text class="pa-0">
              <Organigrama ref="hierarchychart" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          :key="12"
          :value="'tab-12'"
          
        >
          <v-card flat>
            <v-card-text>
              <PersonaChecador ref="relojchecador" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item
          :key="13"
          :value="'tab-13'"
          
        >
          <v-card flat>
            <v-card-text>
              <PersonaDescanso/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-row>
  <!-- </v-container> -->
</template>

<script>
import Puesto from "./RH/Puesto.vue";
import Persona from "./RH/Persona.vue";
import Horario from "./Configuracion/Horario.vue";
import Turno from "./Configuracion/Turno.vue";
import Habilidad from "./Configuracion/Habilidad.vue";
import EquipoPredefinido from "./Recursos/Equipos.vue";
import Vendedores from "./Ventas/Vendedores";
import Operario from "./Recursos/Operario.vue";
import PersonaRejoChecador from "./RH/PersonaRejoChecador.vue";
import { mapState } from "vuex";
import Organigrama from './Seguridad/Organigrama.vue';
import PersonaDescanso from "./RH/PersonaDescanso.vue";
import ListaDepartamentos from './RH/ListaDepartamentos.vue';
import Brigadas from './RH/Brigadas.vue';

export default {
  data() {
    return {
      tab: null,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  methods: {
    updateOrganigrama(){
      if (typeof this.$refs.hierarchychart === "undefined") return;
      this.$refs.hierarchychart.initialize();
    },
    updateTurnos() {
      if (typeof this.$refs.turnos === "undefined") return;
      this.$refs.turnos.initialize();
    },
    updateHabilidades() {
      if (typeof this.$refs.habilidades === "undefined") return;
      this.$refs.habilidades.initialize();
    },
    updateDepartamentos(){
      if (typeof this.$refs.departamentos === "undefined") return;
      this.$refs.departamentos.initialize();
    },
    updatePuestos() {
      if (typeof this.$refs.puestos === "undefined") return;
      this.$refs.puestos.initialize();
    },
    updatePersonas() {
      if (typeof this.$refs.personas === "undefined") return;
      this.$refs.personas.initialize();
    },
    updateHorarios() {
      if (typeof this.$refs.horarios === "undefined") return;
      this.$refs.horarios.initialize();
    },
    updateEquiposPredefinidos() {
      if (typeof this.$refs.equipospredefinidos === "undefined") return;
      this.$refs.equipospredefinidos.initialize();
    },
    updateVendedores() {
      if (typeof this.$refs.vendedores === "undefined") return;
      this.$refs.vendedores.initialize();
    },
    updateOperadores() {
      if (typeof this.$refs.operarios === "undefined") return;
      this.$refs.operarios.initialize();
    },

    updatePersonasRelojChecador() {
      if (typeof this.$refs.relojchecador === "undefined") return;
      this.$refs.relojchecador.initialize();
    },
    updateBrigadas(){
      if (typeof this.$refs.brigadas === "undefined") return;
      this.$refs.brigadas.initialize();
    }
  },
  computed: {
    ...mapState(["anchoMaximo","colorTabs","modoAyuda"]),
  },
  components: {
    RHPersona: Persona,
    RHPuesto: Puesto,
    configuracionHorario: Horario,
    configuracionTurno: Turno,
    configuracionHabilidad: Habilidad,
    recursosEquiposPredefinidos: EquipoPredefinido,
    VentasVendedores: Vendedores,
    recursosOperario: Operario,
    PersonaChecador: PersonaRejoChecador,
    Organigrama,
    PersonaDescanso,
    ListaDepartamentos,
    Brigadas
  },
};
</script>
<style >
</style>