<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('reporterendimiento','r')">
    <v-row>
      <!-- <v-col cols="12" md="12" sm="12">
        <datePicker
          v-model="fecha"
          label="Fecha (Obligatorio)"
          format="YYYY-MM-DD"
          clearable
          maxToday
          @input="ejecutar"
        ></datePicker>
      </v-col> -->
      <!-- @input="getListas()" -->
      <v-col cols="6">
        <datePicker
          v-model="inicio"
          format="YYYY-MM-DD"
          :maxDate="fin"
          label="Desde (Opcionales)"
          clearable
          maxToday
          @input="getDatos()"
        ></datePicker>
      </v-col>
      <v-col cols="6">
        <datePicker
          v-model="fin"
          format="YYYY-MM-DD"
          :minDate="inicio"
          label="Hasta (Opcionales)"
          clearable
          maxToday
          @input="getDatos()"
        ></datePicker>
      </v-col>
     <!--  <v-col cols="4">
        <v-btn
					color="primary"
					@click="getDatos()"
					style="width: 150px"
					>Buscar</v-btn
				>
      </v-col> -->
    </v-row>
    <v-row v-if="realizarPeticion" class="px-0 ma-0">
      <v-col cols="12" class="ma-0 text-right text-subtitle-2">
        Proxima Actualización en {{tiempo}}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
			color="primary"
			indeterminate
		></v-progress-linear>
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </v-col>
</template>


<script>
import axios from "axios";
import VTextFieldDatetime from '@/components/VTextFieldDatetime.vue';

export default {
  components:{
    'v-text-field-datetime': VTextFieldDatetime,
  },
  data: () => ({
    categories:[],
    series:[],
    fecha: null,
    intervalo: null,
    loading: false,
    segundos: 0,
    inicio: null,
    fin: null,
    realizarPeticion : false
  }),
  computed:{
    chartOptions(){
      return {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        dataLabels: {
          enabled: true
        },
        xaxis: {
          categories: this.categories,
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
      }
    },
    urlDatos(){
      return `/Reportes/ReporteInactividadMaquina?desde=${this.inicio}&hasta=${this.fin}`;
    },
    /* realizarPeticion(){
      return this.$utils.isValid(this.fecha);
    }, */
    tiempo(){
      return this.$utils.strings.toTime(this.segundos);
    }
  },
  mounted(){
    this.getDatos();
    this.intervalo = setInterval(() => {
      if(this.segundos == 0){
        this.getDatos();
      }
      else{
        this.segundos -= 1;
      }
    },1000);
  },
  destroyed(){
    clearInterval(this.intervalo);
    this.intervalo = null;
  },
  methods:{
    ejecutar(){
      /* if(this.realizarPeticion)
      {
        this.getDatos();
      } */
    },
    getDatos(){
      if(this.inicio == null) return;
      if(this.fin == null) return;

      this.loading = true;
      this.segundos = 60;
      this.realizarPeticion = true;
      axios
      .get(this.urlDatos)
      .then(response => {
        this.series = response.data.series;
        this.categories = response.data.categories;
        this.loading = false;
      })
    }
  },
}
</script>