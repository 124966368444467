<template>
  <v-container>
    <v-dialog
      v-model="show"
      :key="show"
      fullscreen
      persistent
    >
      <v-overlay :value="addElement" opacity="0.90" z-index="206">
        <v-dialog v-model="addElement" persistent scrollable max-width="600px">
          <v-card>
            <v-card-title>
              Agregar {{name}} 
            </v-card-title>
            <v-card-text>
              <v-container>
                  <v-row>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="addItem.nombre" label="Nombre del Artículo"></v-text-field>
                    </v-col>
                    <v-col justify="center" cols="12" sm="12" md="12">
                      <v-text-field v-model="addItem.codigo" label="Código del Artículo"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <p style="font-size: 16px !important">Salida Automática</p>
                      <v-checkbox
                        v-model="addItem.salidaAutomatica"
                        color="success"
                        class="mt-0"
                        hide-details
                      >
                        <template v-slot:label>
                          <p class="mb-0 text--primary">{{addItem.salidaAutomatica?'Activada':'Desactivada'}}</p>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="addItem.idUnidad"
                        :items="unidades"
                        label="Unidad"
                        item-text="nombre"
                        item-value="idUnidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingUnidad"
                      @click="getUnidades()"
                    >
                      mdi-reload
                    </v-icon>
                  </v-row>
                  <v-row>
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="addItem.idArticuloCategoria"
                        :items="categorias"
                        label="Categoría"
                        item-text="nombre"
                        item-value="idArticuloCategoria"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingCategoria"
                      @click="getCategorias()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="addItem.idArticuloClase"
                        :items="clases"
                        label="Clase"
                        item-text="clase"
                        item-value="idArticuloClase"
                        :loading="loadingClases"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingClases"
                      @click="getClases()"
                      >mdi-reload</v-icon
                    >
                    <v-col justify="center" cols="11" sm="11" md="11">
                      <v-autocomplete
                        v-model="addItem.idArticuloFamilia"
                        :items="familias"
                        label="Familia de Productos"
                        item-text="nombre"
                        item-value="idArticuloFamilia"
                        :multiple="multiplesFamilias"
                      ></v-autocomplete>
                    </v-col>
                    <v-icon
                      class="mr-2"
                      :disabled="loadingFamilia"
                      @click="getFamilias()"
                    >
                      mdi-reload
                    </v-icon>
                    <v-col v-if="conversion" justify="center" cols="12" sm="12" md="12" class="no-padding">
                      <v-row v-if="unidadConversion.numDimensiones > 1" class="no-padding">
                        <v-col justify="center" :cols="colSize" :sm="colSize" :md="colSize" class="no-padding">
                          <v-text-field
                            v-model="addItem.ejeX"
                            type="number"
                            min="0"
                            label="Largo"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="unidadConversion.numDimensiones >= 2" justify="center" :cols="colSize" :sm="colSize" :md="colSize" class="no-padding">
                          <v-text-field
                          v-model="addItem.ejeY"
                            type="number"
                            min="0"
                            label="Ancho"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="unidadConversion.numDimensiones == 3" justify="center" :cols="colSize" :sm="colSize" :md="colSize" class="no-padding">
                          <v-text-field
                          v-model="addItem.ejeZ"
                            type="number"
                            min="0"
                            label="Alto"
                            @input="factorConversion()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="no-padding">
                        <v-col justify="center" cols="12" sm="12" md="12" class="no-padding">
                          <v-text-field
                            v-model="addItem.factorConversion"
                            type="number"
                            min="0"
                            :readonly="unidadConversion.numDimensiones > 1"
                            :label="'Factor de Conversion ('+unidadConversion.abreviatura+')'"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <ul>
                    <li class="red--text" v-for="ex in elementErrores" :key="ex">{{ ex }}</li>
                  </ul>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeElement();">Cancelar</v-btn>
              <v-btn color="red" text @click="saveElement()" :disabled="savingElement" :loading="savingElement">
                Guardar
                <template v-slot:loader>
                  <v-progress-circular
                    indeterminate
                    :width="2"
                    :size="24"
                    color="red"
                  ></v-progress-circular>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-overlay>
      <v-dialog
        v-model="showAddImage"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title>
            Agregar Imágen
          </v-card-title>
          <v-card-text>
            <v-col cols="12" md="12" sm="12">
              <v-file-input
              v-model="fileImage"
              @change="showImage"
              accept="image/*"
              label="Imagen"
            ></v-file-input>
            </v-col>
            <v-col cols="12" md="12" sm="12" >
              <img
                :src="previewb64"
                style="max-width: 100%;"
              />
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeShowImage()">Cancelar</v-btn>
            <v-btn color="red darken-1" text @click="pushImage()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showRemoveImageB64"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title class="headline">¿Está seguro que desea eliminar esta imagen?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showRemoveImageB64 = false">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="deleteImageB64()">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="showRemoveImage"
        max-width="500px"
        persistent
      >
        <v-card>
          <v-card-title class="headline">¿Está seguro que desea eliminar esta imagen?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showRemoveImage = false">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="deleteImage()">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <TiempoHistorico ref="historico" />
      <v-overlay :value="cargando" opacity="0.96">
        <v-row justify="center" align="center">
          <div class="text-center ma-12">
            <v-progress-circular class="text-center" indeterminate size="64"></v-progress-circular>
            <br>
            <br>
            <p class="text-center display-1 font-italic">Cargando</p>
          </div>
        </v-row>
      </v-overlay>
      <v-snackbar
        v-model="alerta"
        color="error"
      >
        <ul>
          <li v-for="e in errores" :key="e">{{e}}</li>
        </ul>
        <v-btn
          color="white"
          text
          @click="alerta = false"
        >
          Cerrar
        </v-btn>
      </v-snackbar>
      <v-card>
        <v-card-title>  
          Diseñar Receta de {{name}}      
        </v-card-title>
        <v-card-text>
          <v-col cols="12" sm="12" md="12">
            <v-row class="d-flex" justify="center" style="background:#2D848A; padding-top:10px; padding-bottom:10px;">
              <v-col sm="3" style="color:white" class="align-self-center">
                <v-autocomplete
                  v-show="mode != 2"
                  dark
                  v-model="editedItem.idArticulo"
                  :items="elementos"
                  :label="name+' Esperado'"
                  :item-text="nombreElementoCodigo"
                  item-value="idArticulo"
                  :disabled="mode == 2"
                  dense
                  hide-details
                ></v-autocomplete>
                <v-text-field
                  v-if="mode == 2"
                  v-model="recipe.nombre"
                  :label="name+' Esperado'"
                  dark
                  readonly
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-icon
                dark
                class="mr-2"
                v-show="mode != 2"
                :disabled="loading || mode == 2"
                @click="getElementos()"
              >
                mdi-reload
              </v-icon>
              <v-col sm="2" class="align-self-center">
                <v-autocomplete
                  :disabled="mode == 2"
                  dark
                  v-model="idUnidad"
                  :items="unidades"
                  label="Unidad"
                  item-text="nombre"
                  item-value="idUnidad"
                  readonly
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" class="align-self-center">
                <v-text-field
                  v-model="editedItem.descripcion"
                  label="Descripción"
                  dark
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="align-self-center">
                <v-text-field
                  v-model="editedItem.revision"
                  label="[Opcional] Revisión"
                  dark
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-sheet class="pt-0 pb-2">
                  <v-text-field
                    v-model="buscar"
                    label="Buscar"
                    light
                    hide-details
                    clearable
                    append-icon="search"
                    clear-icon="mdi-close-circle-outline"
                  ></v-text-field>
                </v-sheet>
                <v-list 
                  dark
                  class="listaRecetas"
                >
                <v-alert
                  v-if="JSON.stringify(items) === '[]'"
                  color="blue-grey"
                  text
                >
                  <div class="text-center" style="font-size: 14px">Ningún elemento coincide con la búsqueda</div>
                </v-alert>
                  <v-list-group
                    sub-group
                    v-for="item in items"
                    :key="item.id"
                    :class="`${item.background} darken-2`"
                    active-class="white--text"
                    :value="item.active"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title 
                          style="font-size: 16px" 
                        >
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <!-- <template > -->
                      <v-list-item
                        v-for="child in item.children"
                        :key="child.id"
                        link
                        light
                        :class="`${item.background} lighten-3`"
                        style="padding-left: 32px;"
                        @click="insertItem(item,child)"
                      >
                        <v-list-item-icon style="margin-right: 16px;">
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title style="font-size:14px">{{ child.name }} </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-divider
                        v-if="index < item.children.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>                     -->
                  </v-list-group>
                </v-list>
              </v-col>
              <v-col cols="8">
                <div class="d-flex justify-space-between">
                  <v-btn
                    v-show="modoOrdenar && pos > 1"
                    color="teal"
                    icon
                    @click="ordenAtras()"
                  >
                    <v-icon x-large>mdi-arrow-left-bold-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="modoOrdenar && pos < editedItem.numPasos"
                    color="teal"
                    icon
                    @click="ordenFrente()"
                  >
                    <v-icon x-large>mdi-arrow-right-bold-circle</v-icon>
                  </v-btn>
                  <v-btn
                    v-show="!modoOrdenar"
                    :disabled="editedItem.numPasos < 2"
                    color="error"
                    class="white--text"
                    @click="borrarPaso()"
                  >
                    Borrar Paso
                    <v-icon
                      right
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-show="!modoOrdenar"
                    color="primary"
                    class="ml-auto white--text"
                    @click="editedItem.numPasos += 1"
                  >
                    Agregar Paso
                    <v-icon
                      right
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="editedItem.numPasos < 2"
                    color="teal"
                    class="white--text"
                    :class="[modoOrdenar && 'ml-auto']"
                    @click="modoOrdenar = !modoOrdenar"
                  >
                    {{ !modoOrdenar ? 'Ordenar' : 'Listo' }}
                    <v-icon
                      right
                    >
                      mdi-sort
                    </v-icon>
                  </v-btn>
                </div>
                <div class="text-center">
                  <h4 v-if="permisoVista('recetas','$')">Costo del {{name}}: {{costoElementoLabel()}}</h4>
                </div>
                <v-stepper v-model="pos" min-width="50%" @change="onStepChange">
                  <v-stepper-header v-if="numPasos > 1">
                    <template v-for="paso in numPasos">
                      <v-divider v-if="paso > 1" :key="paso"></v-divider>
                      <v-stepper-step
                        :key="'s'+paso"
                        :step="paso"
                        editable
                        :color="modoOrdenar ? 'teal':'primary'"
                        :style=" fondoPaso(paso) "
                      >
                        Paso {{paso}}
                        <span 
                          v-if="editedItem.info[paso - 1].idArea !== -1"
                        >
                          {{ getNombreArea(editedItem.info[paso - 1].idArea) }}
                        </span>
                      </v-stepper-step>
                    </template>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content v-for="paso in numPasos" :key="paso" :step="paso">
                      <div class="text-center">
                        <h5 v-if="numPasos> 1 && permisoVista('recetas','$')" >Costo del Paso: {{costoPasoLabel(paso)}}</h5>
                      </div>
                      <div class="text-center">
                        <h5 v-if="numPasos> 1 && permisoVista('recetas','$')" >Costo de Destajo: ${{getCostoDestajoPaso(paso).toFixed(2)}}</h5>
                      </div>
                      <v-row>
                        <v-col :cols="cantidadObjetivoQR ? 4 : 8" >
                          <v-select
                            v-model="editedItem.info[paso - 1].idArea"
                            :items="areasFiltradas(paso)"
                            label="Área"
                            item-text="nombre"
                            item-value="idArea"
                            @change="forzaractuali"
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="editedItem.info[paso - 1].fraccion"
                            label="Cantidad Minima a Reportar"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="cantidadObjetivoQR" cols="4">
                          <v-text-field
                            v-model="editedItem.info[paso - 1].cantidadObjetivo"
                            label="[Opcional] Cantidad Objetivo QR"
                            type="number"
                            hide-spin-buttons
                            hide-details
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="pa-0 px-1 d-flex align-center">
                          <template v-if="!hayMoldesEnReceta">
                            <v-col cols="2" sm="2" md="2" class="padding-0px">
                              <v-switch
                                v-model="editedItem.info[paso - 1].switch1"
                                :label="editedItem.info[paso - 1].switch1 ? `Tiempo por ${getUnidadActual(editedItem.info[paso - 1].switch1)}` : 'Tiempo Especifico'"
                                hide-details
                                :disabled="editedItem.idArticulo == -1"
                                @change="onChangeSwitchTiempo(paso);getUnidadActual()"
                              ></v-switch>
                            </v-col>
                            <template v-if="!editedItem.info[paso - 1].switch1">
                              <v-col cols="3" sm="3" md="3" class="padding-0px">
                                <time-picker
                                  v-model="editedItem.info[paso - 1].tiempoMin"
                                  label="Tiempo Ideal (HH:MM:SS)"
                                  @input="checkTiempo($event,paso);validarSubpasos(paso-1)"
                                  :error="editedItem.info[paso - 1].errorTiempo"
                                ></time-picker>
                              </v-col>
                              <v-col cols="3" sm="3" md="3" class="padding-0px">
                                <time-picker
                                  v-model="editedItem.info[paso - 1].tiempoMax"
                                  label="Tiempo Máximo (HH:MM:SS)"
                                  @input="checkTiempo($event,paso)"
                                  :error="editedItem.info[paso - 1].errorTiempo"
                                ></time-picker>
                              </v-col>
                            </template>
                            
                            <template v-if="editedItem.info[paso - 1].switch1">
                              <v-col cols="2" sm="2" md="2" class="padding-0px mr-n3">
                                <v-text-field
                                  v-model="editedItem.info[paso - 1].numPiezas"
                                  :label="`# de ${getUnidadActual(editedItem.info[paso - 1].switch1)}`"
                                  @keyup="convertirHorasMinutos(paso - 1)"
                                  type="number"
                                  hide-spin-buttons
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1" sm="1" md="1" class="d-flex py-n5">
                              <v-subheader>Cada</v-subheader>
                              </v-col>
                              <v-col cols="2" sm="2" md="2" class="padding-0px py-n5">
                                  <v-text-field
                                  v-model="editedItem.info[paso - 1].minutos"
                                  @keyup="convertirHorasMinutos(paso - 1)"
                                  type="number"
                                  hide-spin-buttons
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2" sm="2" md="2" class="padding-0px">
                                <v-select
                                  v-model="editedItem.info[paso - 1].tiempo"
                                  :items="tiempo"
                                  item-text="value"
                                  item-value="id"
                                  label="Tiempo"
                                  hide-details
                                  @change="convertirHorasMinutos(paso - 1)"
                                ></v-select>
                              </v-col>
                            </template>
                          </template>

                          <template v-if="hayMoldesEnReceta">
                            <v-col cols="4" class="padding-0px">
                              <time-picker
                                v-model="editedItem.info[paso - 1].tiempoMin"
                                label="Tiempo Ciclo (HH:MM:SS)"
                                @input="onInputTiempoCiclo($event, paso)"
                                :error="editedItem.info[paso - 1].errorTiempo"
                              ></time-picker>
                            </v-col>
                          </template>
                          
                          <v-col :cols="hayMoldesEnReceta ? 4 : 3" class="padding-0px">
                            <time-picker
                              v-model="editedItem.info[paso - 1].tiempoLimpieza"
                              label="Tiempo Limpieza (HH:MM:SS)"
                            ></time-picker>
                          </v-col>
                          <br>
                          <template v-if="!hayMoldesEnReceta">
                            <div class="mt-n5 ml-3 d-flex justify-center" v-if="editedItem.info[paso - 1].switch1" :key="keyC">
                            {{`Tiempo estimado de fabricación por ${getUnidadActual(editedItem.info[paso - 1].switch1)} ${convertirHorasMinutos(paso - 1) != undefined ?convertirHorasMinutos(paso - 1) : ''}`}}
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-btn
                          class="my-0"
                          text
                          v-if="getIdPaso(paso) > 0"
                          color="primary"
                          @click="showDialogDesv(paso)"
                        >
                          Tiempo Promedio Histórico
                        </v-btn>
                        <v-col cols="12" sm="12" md="12">
                          <v-card
                            class="md-12"
                            color="grey lighten-2"
                          >
                            <drag-box-2
                              :list="editedItem.receta[paso-1]"
                              :item-text="etiqueta"
                              :maxInt="editedItem.info[paso-1].tiempoMin"
                              :group="{name:'paso'+paso,pull:false,put:true}"
                              replace
                              @repaint="changeList"
                              @update="changeList"
                            ></drag-box-2>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row v-if="moldes">
                        <v-col cols="12">
                          <v-expansion-panels>
                            <v-expansion-panel ref="moldepanel" :disabled="!habilitarMoldes">
                              <v-expansion-panel-header>
                                Moldes
                                <span v-if="!habilitarMoldes" class="pl-2">
                                  {{ editedItem.idArticulo == -1 ? '(Seleccione un artículo)' : '(Agregue al menos una máquina o herramienta)' }}
                                </span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <MoldesReceta
                                  v-model="editedItem.info[paso-1].moldes"
                                  :idArticulo="editedItem.idArticulo"
                                  :receta="editedItem.receta[paso-1]"
                                />
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12" sm="12">
                          <v-textarea
                            v-model="editedItem.info[paso-1].nota"
                            label="Notas"
                            auto-grow
                            rows="1"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <div>
                        <v-expansion-panels v-model="panels" multiple>
                          <v-expansion-panel>
                            <v-expansion-panel-header>Subpasos</v-expansion-panel-header>
                            <v-expansion-panel-content cols="12" sm="12" md="12">
                              <SubpasosReceta
                                :key="`subrec-${paso-1}`"
                                :paso="editedItem.info[paso - 1]"
                                :ref="`recsubs${paso-1}`"
                              />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                          <v-expansion-panel>
                            <v-expansion-panel-header>Imágenes del Paso</v-expansion-panel-header>
                            <v-expansion-panel-content cols="12" sm="12" md="12">
                              <v-slide-group
                                class="pa-4"
                                show-arrows
                                center-active
                              >
                                <v-slide-item
                                  v-slot:default="{ toggle }"
                                >
                                  <v-card
                                    color="grey lighten-4"
                                    class="ma-4 overflow-y-auto overflow-x-hidden"
                                    height="150"
                                    width="150"
                                    @click="toggle; addImage(paso);"
                                  >
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-scale-transition>
                                      <v-icon
                                        color="black"
                                        size="48"
                                      >mdi-plus</v-icon>
                                    </v-scale-transition>
                                  </v-row>
                                  </v-card>
                                </v-slide-item>
                                <v-slide-item
                                  v-for="(img, idx) in editedItem.info[paso-1].imagenes"
                                  :key="'pos'+idx"
                                >
                                  <v-card
                                    color="grey lighten-4"
                                    class="ma-4 overflow-y-auto overflow-x-hidden"
                                    height="150"
                                    width="150"
                                    @click="removeImage(idx,paso)"
                                  >
                                    <v-img
                                      :src="img.base64"
                                      aspect-ratio="1.0"
                                    ></v-img>
                                  </v-card>
                                </v-slide-item>
                                <v-slide-item
                                  v-for="(img, idx) in editedItem.info[paso-1].imagenesb64"
                                  :key="'pre'+idx"
                                >
                                  <v-card
                                    color="grey lighten-4"
                                    class="ma-4 overflow-y-auto overflow-x-hidden"
                                    height="150"
                                    width="150"
                                    @click="removeImageB64(idx)"
                                  >
                                    <v-img
                                      :src="img"
                                      aspect-ratio="1.0"
                                    ></v-img>
                                  </v-card>
                                </v-slide-item>
                              </v-slide-group>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                      <br>
                      <div class="d-flex">
                        <v-btn
                          v-if="pos > 1"
                          color="error"
                          @click="pos = (pos-1)>=1?pos-1:pos"
                        >
                          Anterior
                        </v-btn>

                        <v-btn
                          v-if="pos < numPasos"
                          class="ml-auto"
                          color="primary"
                          @click="pos = ((pos+1)<=numPasos)?pos+1:pos"
                        >
                          Siguiente
                        </v-btn>
                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
            
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close();" :disabled="saving">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import dragtable from '@/components/DragTable';
import dragbox2 from '@/components/DragBox2';
import timepicker from '@/components/TimePicker';
import TiempoHistorico from './TiempoHistorico.vue';
import SubpasosReceta from './SubpasosReceta.vue';
import MoldesReceta from './MoldesReceta.vue';
import { mapState } from 'vuex';

  export default {
    components: {
      'drag-table':dragtable,
      'drag-box-2':dragbox2,
      'time-picker':timepicker,
      TiempoHistorico,
      SubpasosReceta,
      MoldesReceta
    },
    props:{
      show:{
        Type: Boolean,
        default: false,
      },
      number:{
        type:Number,
        default: 0,
      },
      name:{
        type:String,
        default: "",
      },
      mode:{
        type: Number,
        default: 0,
      },
      recipe: {
        type: Object,
        default: {},
      }
    },
    data() {
      return {
        render:0,
        stepper: 1,
        idArea:null,
        abreviatura: null,
        tiempo: [{id: 1, value: "Minutos"},{id: 2, value: "Horas"}],
        loadingClases: false,
        clases: [],
        panels: [],
        modoOrdenar: false,
        pasoAreas: [],
        open: [],
        buscar: '',
        elementErrores: [],
        elementAlert: false,
        addItem: {
          nombre: '',
          codigo:'',
          idUnidad:-1,
          idArticuloTipo: -1,
          idArticuloCategoria:-1,
          idArticuloFamilia: [],
          ejeX:null,
          ejeY:null,
          ejeZ:null,
          factorConversion:null,
          salidaAutomatica: false,
          idArticuloClase: null
        },
        defaultAddItem: {
          nombre: '',
          codigo:'',
          idUnidad:-1,
          idArticuloTipo: -1,
          idArticuloCategoria:-1,
          idArticuloFamilia: [],
          ejeX:null,
          ejeY:null,
          ejeZ:null,
          factorConversion:null,
          salidaAutomatica: false,
          idArticuloClase: null
        },
        categorias: [],
        loadingCategoria: false,
        loadingTipo: false,
        tipos:[],
        familias: [],
        loadingFamilia: false,
        multiplesFamilias: true,
        savingElement: false,
        conversion: false,
        unidadConversion:{
          idUnidad:-1,
          nombre:'',
          abreviatura:'',
          numDimensiones:0,
        },
        defaultUnidadConversion:{
          idUnidad:-1,
          nombre:'',
          abreviatura:'',
          numDimensiones:1,
        },
        addElement: false,
        saving: false,
        desconocidosTipos:[],
        desconocidos:[],
        loaded: 0,
        lista:[],
        idUnidad: -1,
        pos: 1,
        elementos:[],
        unidades: [],
        recursos:[],
        areas:[],
        maquinas:[],
        herramientas:[],
        puestos:[],
        loadingDesconocidos: false,
        loadingMaquina:false,
        loadingPuesto:false,
        loadingRecurso:false,
        loadingUnidad: false,
        loadingArea: false,
        loading: false,
        editedItem: {
          idArticulo:-1,
          dimLote: 1,
          numPasos: 1,
          descripcion:"",
          revision: null,
          receta:[[]],
          info: [
            {
              idArea: -1,
              fraccion: null,
              tiempoMin: 0,
              tiempoMax: 0,
              tiempoLimpieza:0,
              errorTiempo: false,
              imagenesb64:[],
              imagenes:[],
              imagenesEliminar:[],
              nota:null,
              subpasos: [],
              subsvalidos: true,
              costoDestajo: 0,
              switch1: false,
              numPiezas: null,
              minutos: null,
              tiempo: null,
              piezasTpm:null,
              moldes: [],
              cantidadObjetivo: null
            }
          ]
        },
        defaultItem: {
          idArticulo: -1,
          dimLote: 1,
          numPasos: 1,
          descripcion:"",
          revision: null,
          receta:[[]],
          info: [
            {
              idArea: -1,
              fraccion: null,
              tiempoMin: 0,
              tiempoMax: 0,
              tiempoLimpieza:0,
              errorTiempo: false,
              imagenesb64:[],
              imagenes:[],
              imagenesEliminar:[],
              nota:null,
              subpasos: [],
              subsvalidos: true,
              costoDestajo: 0,
              switch1: false,
              numPiezas: null,
              minutos: null,
              tiempo: null,
              piezasTpm:null,
              moldes: [],
              cantidadObjetivo: null
            }
          ]
        },
        infoDefault:{
          idArea: -1,
          fraccion: null,
          tiempoMin: 0,
          tiempoMax: 0,
          tiempoLimpieza:0,
          errorTiempo: false,
          imagenesb64:[],
          imagenes:[],
          imagenesEliminar:[],
          nota:null,
          subpasos: [],
          subsvalidos: true,
          costoDestajo: 0,
          switch1: false,
          numPiezas: null,
          minutos: null,
          tiempo: null,
          piezasTpm:null,
          moldes: [],
          cantidadObjetivo: null
        },
        showAddImage:false,
        showRemoveImageB64:false,
        posImagenB64: 0,
        showRemoveImage:false,
        posImagen:0,
        fileImage:null,
        previewb64:"",
        pasoImagen:-1,
        alerta:false,
        errores:[],
        subvalidos: [],
        colors: ['blue','cyan','green','indigo','brown','lime','orange','pink','purple','red','teal','yellow'],
        keyC:0,
        forze: 0,
      }
    },

    computed: {
      hayMoldesEnReceta(){
        return this.editedItem.info[this.pos - 1].moldes.length > 0;
      },
      habilitarMoldes(){
        if(!this.moldes) return false;
        if(this.editedItem.idArticulo == -1) return false;
        return this.editedItem.receta[this.pos - 1].some( recetaItem => recetaItem.hasOwnProperty('idMaquina') );
      },
      items(){
        this.forze;
        console.log(this.stepper)
        this.idArea = 0;

        if(this.editedItem.info[this.stepper - 1] != undefined)
          this.idArea = this.editedItem.info[this.stepper - 1].idArea;
        else
          this.idArea = this.editedItem.info[0].idArea;
          
        const recursos = this.recursos.map( (recurso,index) => ({
          background: this.getColor(index),
          active: false,
          id: "a"+recurso.id,
          name: this.toUpperCaseAfterSpace(recurso.nombre),
          children: this.articulosFiltrados(recurso.id).map( element => ({ ...element,name: element.codigo+' - '+element.nombre }))
        }));
        const maquinas = [{
          background: this.getColor(this.recursos.length),
          active: false,
          id: 'a100',
          name: 'Máquinas',
          children: this.maquinasFiltradas(this.idArea).map( element => ({ ...element,name: element.marca+' - '+element.modelo }))
        }];
        const herramientas = [{
          background: this.getColor(this.recursos.length+1),
          active: false,
          id: 'a101',
          name: 'Herramientas',
          children: this.herramientasFiltradas().map( element => ({ ...element,name: element.marca+' - '+element.modelo }))
        }];
        const operadores = [{
          background: this.getColor(this.recursos.length+2),
          active: false,
          id: 'a102',
          name: 'Operadores',
          children: this.puestosFiltrados().map( element => ({ ...element,name: element.nombre }))
        }];
        const desconocidos = this.desconocidosTipos.map( (desconocido,idx) => ({
          background: this.getColor(this.recursos.length+2+1+idx),
          active: false,
          id: 'a'+(102+1+idx),
          name: this.toUpperCaseAfterSpace(desconocido.nombre),
          children: this.desconocidosFiltrados(desconocido.idDesconocido).map( element => ({ ...element,name: element.nombre }))
        }));

        const resultado = [ ...recursos,...maquinas,...herramientas,...operadores,...desconocidos ]
                            .filter( elemento => {
                              if( JSON.stringify(elemento) !== '{}' ){
                                if(JSON.stringify(elemento.children) !== '[]') return elemento;
                              }
                            });
        const filtrado = resultado.filter(item => {
            if(!this.buscar) return resultado;
            const hijos = item.children.filter( child => child.name.toLowerCase().includes( this.buscar.toLowerCase() ))
          
            if( hijos.length > 0 ){
              item.children = hijos;
              item.active = true;
              return item;
            }
        });
        //Otra lina nueva
        return filtrado;

      },
      cargando(){
        return (
          this.loadingDesconocidos ||
          this.loadingMaquina ||
          this.loadingPuesto ||
          this.loadingRecurso ||
          this.loadingUnidad ||
          this.loadingArea ||
          this.loading
        );
      },
      idArticulo(){
        return this.editedItem.idArticulo;
      },
      numPasos(){
        if(parseInt(this.editedItem.numPasos) <= 0 || isNaN(parseInt(this.editedItem.numPasos)) ) return 1;
        else return parseInt(this.editedItem.numPasos);
      },
      numTablas(){
        let i = 0;
        i += this.recursos.length;
        i += (this.maquinas.length)?1:0;
        i += (this.herramientas.length)?1:0;
        i += this.desconocidosTipos.length;
        return i;
      },
      columnTabla(){
        switch(this.numTablas){
          case 1:
            return 12;
          case 2:
            return 6;
          case 3:
            return 4;
          case 4:
            return 3;
          default:
            return 3;
        }
      },
      idArticuloCategoria(){
        return this.addItem.idArticuloCategoria;
      },
      colSize(){
        return (12/this.unidadConversion.numDimensiones);
      },
      ...mapState(['moldes', "cantidadObjetivoQR"])
    },
    watch: {
      habilitarMoldes(){
        if(!this.moldes) return;
        if(this.habilitarMoldes) return;
        const { isActive, toggle } = this.$refs.moldepanel[this.pos - 1];
        if(isActive) toggle();
      },
      show (){
        if(this.show){
          this.initialize();
        }
      },
      idArticuloCategoria(){
        if(this.idArticuloCategoria < 1) return;
        
        let cat = this.categorias.find(categoria => categoria.idArticuloCategoria == this.idArticuloCategoria);

        if(cat != null) {
          this.conversion = cat.conversion;
          if(cat.unidad != null) this.unidadConversion = cat.unidad;
          else this.unidadConversion = Object.assign({}, this.defaultUnidadConversion);
        }
        else{
          this.conversion = false;
          this.unidadConversion = Object.assign({}, this.defaultUnidadConversion);
        }
      },
      idArticulo(){
        if(this.editedItem.idArticulo == null){
          this.addElement = true;
          this.addItem.idArticuloTipo = this.number;
          this.cambioTipo(this.number);
          return;
        }

        if(this.editedItem.idArticulo == -1){
          this.idUnidad = -1;
          return;
        }
        let elemento = this.elementos.find(element => element.idArticulo == this.editedItem.idArticulo);
        if(elemento == null){
          this.idUnidad = -1;
          return;
        }
        this.idUnidad = elemento.idUnidad;
        if(typeof elemento.idArticulo !== 'undefined' && this.show)this.getRecursos(elemento.idArticulo);
      },
      numPasos(){
        this.actualizarNumPasos();
      },
      loaded(){
        this.buscar = '';
        if(this.loaded < 6 || this.mode == 0) return;
        
        if(this.loaded == 6  & this.mode > 0){
          this.editedItem.idArticulo = this.recipe.idArticulo;
          this.editedItem.dimLote = this.recipe.cantidad;
          this.editedItem.numPasos = this.recipe.numPasos;
          this.editedItem.descripcion = this.recipe.descripcion;
          this.editedItem.revision = this.recipe.revision;
          this.actualizarNumPasos();
        }

        if(this.loaded == 7 && this.mode > 0){
          let me = this;
          let pos = -1;
          this.recipe.pasos.forEach(function(paso){
            pos += 1;
            me.editedItem.info[pos].idArea = paso.idArea;
            me.editedItem.info[pos].fraccion = paso.fraccion;
            me.editedItem.info[pos].tiempoMin = paso.tiempoMin;
            me.editedItem.info[pos].tiempoMax = paso.tiempoMax;
            me.editedItem.info[pos].tiempoLimpieza = paso.tiempoLimpieza;
            me.editedItem.info[pos].nota = paso.nota;
            me.editedItem.info[pos].costoDestajo = paso.costoDestajo;
            me.editedItem.info[pos].subpasosSeriados = paso.subpasosSeriados;
            me.editedItem.info[pos].subpasos = paso.subpasos.sort( ( a,b ) => a.posicion - b.posicion );
            me.editedItem.info[pos].switch1 = paso.tipoTiempo;
            me.editedItem.info[pos].moldes = paso.moldes;
            me.editedItem.info[pos].cantidadObjetivo = paso.cantidadObjetivo;

            if(me.moldes && paso.moldes.length > 0){
              me.$refs.moldepanel[pos].toggle();
            }

            if(paso.tipoTiempo){
              me.editedItem.info[pos].numPiezas = paso.numPiezas;
              me.editedItem.info[pos].minutos = paso.minutos;
              me.editedItem.info[pos].tiempo = paso.tiempo;
            }
            
            
            if(me.editedItem.info[pos].subpasos.length > 0) me.panels = [0];
            else me.panels = [];
            me.editedItem.info[pos].imagenes = paso.imagenes;

            paso.ingredientes.forEach(function(ingrediente){
              if(ingrediente.idArticulo != null){
                let articulo = null;

                for (const [index, tipo] of me.recursos.entries()) {
                  articulo = tipo.articulos.find(articulo => articulo.idArticulo == ingrediente.idArticulo);
                  if(typeof articulo === 'object') break;
                }

                if(typeof articulo !== 'object') return;

                articulo = Object.assign({},articulo);
                
                if(articulo.conversion){
                  if(articulo.numDimensiones == null){
                    articulo.cantidad = ingrediente.cantidad;
                    if(ingrediente.ejeX > 0) articulo._ejeX = ingrediente.ejeX;
                  }
                  if(articulo.numDimensiones == 1){
                    articulo.cantidad = ingrediente.fraccion * articulo.factorConversion;
                  }
                  if(articulo.numDimensiones == 2){
                    articulo.cantidad = ingrediente.cantidad;
                    articulo._ejeX = ingrediente.ejeX;
                    articulo._ejeY = ingrediente.ejeY;
                  }
                  if(articulo.numDimensiones == 3){
                    articulo.cantidad = ingrediente.cantidad;
                    articulo._ejeX = ingrediente.ejeX;
                    articulo._ejeY = ingrediente.ejeY;
                    articulo._ejeZ = ingrediente.ejeZ;
                  }
                }
                else articulo.cantidad = ingrediente.cantidad;

                articulo.remplazar = ingrediente.remplazar;
                //articulo.remplazarMaquina = ingrediente.remplazarMaquina;
                articulo.critico = ingrediente.critico;
                articulo.merma = ingrediente.merma;
                me.editedItem.receta[pos].push(articulo);
              }
              
              else if(ingrediente.idMaquina != null){
                let maquinat = me.maquinas.find(maquina => maquina.idMaquina == ingrediente.idMaquina);
                if(typeof maquinat === 'undefined') maquinat = me.herramientas.find(herramienta => herramienta.idMaquina == ingrediente.idMaquina);
                if(typeof maquinat === 'undefined') return;
                let maquina = Object.assign({},maquinat);
                maquina.cantidad = ingrediente.cantidad;
                maquina.tiempo = ingrediente.tiempo;
                me.editedItem.receta[pos].push(maquina);
                maquina.remplazarMaquina = ingrediente.remplazarMaquina;
              }
              
              else if(ingrediente.idPuesto != null){
                let puesto = Object.assign({},me.puestos.find(puesto => puesto.idPuesto == ingrediente.idPuesto));
                if(typeof puesto === 'undefined') return;
                puesto.cantidad = ingrediente.cantidad;
                puesto.tiempo = ingrediente.tiempo;
                me.editedItem.receta[pos].push(puesto);
              }

              else if(ingrediente.idDesconocidoIns != null){
                let desconocido = null;
                for (const [index, tipo] of me.recursos.entries()) {
                  desconocido = Object.assign({},me.desconocidos[tipo.idDesconocido].find(desconocido => desconocido.idDesconocidoIns == ingrediente.idDesconocidoIns));
                  if(typeof articulo === 'object') break;
                };
                if(typeof desconocido === 'undefined') return;
                desconocido.cantidad = ingrediente.cantidad;
                if(ingrediente.tiempo != null)desconocido.tiempo = ingrediente.tiempo;
                me.editedItem.receta[pos].push(desconocido);
              }

            });
          });
        }
      },
    },

    created () {
      this.forze++;
    },

    methods: {
      forzaractuali(){
        this.forze++;
        //Solo agregue la lina pa ver su se destraba el error
      },
      onStepChange(newStep) {
        console.log("Linea 1333: "+newStep)
      this.stepper = newStep;
      this.idArea = this.editedItem.info[this.stepper - 1].idArea;
      this.maquinasFiltradas(this.idArea)
    },
      marcaModelo: element => element.marca+' - '+element.modelo,
      onChangeSwitchTiempo(paso){
        this.editedItem.info[paso - 1].tiempoMin = 0;
        this.editedItem.info[paso - 1].tiempoMax = 0;
        this.editedItem.info[paso - 1].numPiezas = null;
        this.editedItem.info[paso - 1].minutos = null;
        this.editedItem.info[paso - 1].tiempo = null;
        this.editedItem.info[paso - 1].errorTiempo = false;
      },
      onInputTiempoCiclo(value, paso){
        this.editedItem.info[paso - 1].tiempoMax = value;
        this.checkTiempo(value, paso);
        this.validarSubpasos(paso - 1);
      },
      getUnidadActual(estatus){
        if(!estatus)
          return ""
        else  
          return this.unidades.filter(a=> a.idUnidad == this.idUnidad)[0].abreviatura;
                
      },
      getClases(){
        this.loadingClases = true;
        axios
          .get('/Articulos/ListarArticuloClase')
          .then( response => {
            this.clases = response.data;
          })
          .catch( error => {
            console.log(error);
          })
          .finally( () => {
            this.loadingClases = false;
          })
      },
      conflictoSubpaso(error){
        if(error.response){
          if(error.response.status == 409){
            this.errores.push(error.response.data.msg.split(": ")[1])
            this.alerta = true;
          }
        }
      }, 
      validarSubpasos(paso){
        if(this.$refs['recsubs'+paso] != undefined){
          if(this.$refs['recsubs'+paso].length > 0){
            this.$refs['recsubs'+paso][0].calcularTiempos();
          }
        }
      },
      subpasosValidos(value,pos){
        this.subvalidos[pos] = value;
      },
      nombreElementoCodigo: (elemento) => 
        elemento.codigo!=null 
        ? `[${elemento.codigo}] - ${elemento.nombre}` 
        : `${elemento.nombre}`,
      codigoNombre: element => element.codigo+' - '+element.nombre,
      nombrePuesto: element => element.nombre,

      fondoPaso(paso){
        if (this.modoOrdenar && this.pos === paso){
          return 'background-color: #00968836'
        }
        else if( !this.modoOrdenar && this.pos === paso ){
          return 'background-color: rgba(25, 118, 210, 0.27)'
        }
      },
      reordenarArreglo( arr, old_index, new_index ){
        if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
      },
      ordenAtras(){
        if ( this.pos > 1 ){
          this.reordenarArreglo( this.editedItem.receta,this.pos-1,this.pos-2 );
          this.reordenarArreglo( this.editedItem.info,this.pos-1,this.pos-2);
          this.pos -= 1;
        }
      },
      ordenFrente(){
        if ( this.pos < this.editedItem.numPasos ){
          this.reordenarArreglo( this.editedItem.receta,this.pos-1,this.pos );
          this.reordenarArreglo( this.editedItem.info,this.pos-1,this.pos );
          this.pos += 1;
        }
      },
      getNombreArea(idArea){
        return this.areas.find( area => area.idArea === idArea )?.nombre
      },
      
      borrarPaso(){
        this.editedItem.receta.splice( this.pos-1,1 );
        this.editedItem.info.splice( this.pos-1,1 );
        this.editedItem.numPasos -= 1;
      },

      showDialogDesv(paso){
        this.$refs.historico.mostrar(this.getIdPaso(paso));
      },

      getIdPaso(paso){
        try {
          if(paso >= 1) return this.recipe.pasos[paso -1].idPaso          
          else return 0;
        } catch (error) {
          return 0;
        }
      },
      
      initialize(){
        if(!this.show) return;
        this.loaded = 0;
        this.getElementos();
        this.getUnidades();
        this.getAreas();
        this.getMaquinas();
        this.getPuestos();
        this.getDesconocidos();
        this.getClases();
        this.getCategorias();
        this.getFamilias();
        this.getTipos();
      },
      validFloat(value){
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
      },
      cambioTipo(event){
        if(event != 3){
          if(this.ultimoTipo == 3){
            this.multiplesFamilias = true;
            let x = this.editedItem.idArticuloFamilia;
            this.editedItem.idArticuloFamilia = [];
            if(x != null && typeof x !== 'undefined')this.editedItem.idArticuloFamilia.push(x);
          }
        }
        else{
          this.multiplesFamilias = false;
          let x = this.editedItem.idArticuloFamilia;
          if(typeof this.editedItem.idArticuloFamilia !== 'undefined' && x.length > 0){
            this.editedItem.idArticuloFamilia = x[0];
          }
          else{
            this.editedItem.idArticuloFamilia = -1;
          }
        }
        this.ultimoTipo = event;
      },
      getCategorias(){
        let me = this;
        me.loadingCategoria = true;
        axios
        .get("/ArticuloCategorias")
        .then(response =>  {
          me.categorias = response.data;
          me.categorias.push({
            idArticuloCategoria: null,
            nombre: "SIN ASIGNAR",
            descripcion: "SIN ASIGNAR",
            condicion: true,
          });
          me.loadingCategoria = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getFamilias(){
        let me = this;
        me.loadingFamilia = true;
        axios
        .get("/ArticuloFamilias")
        .then(response =>  {
          me.familias = response.data;
          me.loadingFamilia = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getTipos(){
        let me = this;
        me.loadingTipo = true;
        axios
        .get("/ArticuloTipos")
        .then(response =>  {
          me.tipos = response.data;
          me.tipos.push({
            idArticuloTipo: null,
            nombre: "SIN ASIGNAR",
            vacio: false,
            descripcion: "SIN ASIGNAR",
            receta: false,
            condicion: true,
          });
          me.loadingTipo = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getUnidades(){
        let me = this;
        me.loadingUnidad = true;
        axios
          .get("/Unidades")
          .then(response => {
            me.unidades = response.data;
            me.loadingUnidad = false;
            me.loaded += 1;
          })
          .catch(error =>{
            console.log(error);
          })
      },
      getElementos(){
        let me = this;
        me.loading = true;
        axios
          .get("/Articulos/Tipo/"+me.number+"?precio=false")
          .then(response => {
            response.data.unshift({
              idArticulo: null,
              nombre: "Agregar Nuevo "+this.name
            });
            me.elementos = response.data;
            me.loading = false;
            me.loaded += 1;
          })
          .catch(error =>{
            console.log(error);
          });
      },
      getRecursos(idArticulo){
        if(typeof idArticulo === 'undefined') return;
        let me = this;
        me.loadingRecurso = true;
        axios
          .get("/Articulos/Familia/"+idArticulo)
          .then(response => {
            me.recursos = response.data;
            me.loadingRecurso = false;
            me.loaded += 1;
          })
          .catch(error =>{
            console.log(error);
          });
      },
      getAreas(){
        let me = this;
        me.loadingArea = true;
        axios
          .get("/Areas")
          .then(response => {
            me.areas = response.data.filter(function(element){
              return element.idArea > 1;
            });
            me.loadingArea = false;
            me.loaded += 1;
          })
          .catch(error =>{
            console.log(error);
          });
      },
      getMaquinas () {
        let me = this;
        me.loadingMaquina = true;
        axios
        .get("/Maquinas")
        .then(response =>  {
          me.maquinas = response.data.filter(function(el) {
            return (el.esMaquina == true);
          });
          me.herramientas = response.data.filter(function(el) {
            return (el.esMaquina == false);
          });
          me.loaded += 1;
          me.loadingMaquina = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getPuestos(){
        let me = this;
        me.loadingPuesto = true;
        axios
        .get("/Puestos")
        .then(response =>  {
          me.puestos = response.data;
          me.loadingPuesto = false;
          me.loaded += 1;
         })
        .catch(error => {
          console.log(error);
        });
      },
      getDesconocidos(){
        let me = this;
        me.loadingDesconocidos = true;
        axios
        .get("/desconocidos/DesconocidoInstancias")
        .then(response =>  {
          me.desconocidosTipos = response.data.tipos;
          me.desconocidos = response.data.instancias.reduce((r, a) => {
            r[a.idDesconocido] = [...r[a.idDesconocido] || [], a];
            return r;
          }, {});
          me.loadingDesconocidos = false;
          me.loaded += 1;
        });
      },
      actualizarNumPasos(){
        if(this.pos > this.numPasos && this.pos > 1){
          this.pos = this.numPasos;
        }
        if(this.editedItem.receta.length > this.numPasos && this.numPasos > 0){
          this.editedItem.receta = this.editedItem.receta.splice(0,this.numPasos);
          this.editedItem.info = this.editedItem.info.splice(0,this.numPasos);
        }
        else{
          while(this.editedItem.receta.length < this.numPasos){
            this.editedItem.receta.push([]);
            this.infoDefault.imagenesb64 = [];
            this.infoDefault.imagenes = [];
            this.infoDefault.imagenesEliminar = [];
            this.infoDefault.subpasos = [];
            this.infoDefault.moldes = []
            this.editedItem.info.push(Object.assign({},this.infoDefault));
          }
        }
      },
      areasFiltradas(id){
        try {
        let ids = this.editedItem.info
        .filter(function(element,index){
          return (index + 1 ) != id
        })
        .map(function(element){
          return element.idArea;
        }).filter(function(element){
          return element != -1;
        });

        return this.areas.filter(function(element){
          return ids.indexOf(element.idArea) == -1;
        });
        } catch (error) {
            console.error(error.message);
            console.error(error);
        }

      },
      renderTiem(){
        this.render++;
      },
      articulosFiltrados(id){
        let me = this;
        let coleccion = me.recursos.find(function(element){
          return element.id == id;
        });
        
        if(me == null) return [];

        let ids = this.editedItem.receta[this.pos-1].filter(function(element){
          return typeof element.idArticulo !== 'undefined';
        })
        .map(function(element){
          return element.idArticulo;
        });        

        return coleccion.articulos.filter(function(element){
          return ids.indexOf(element.idArticulo) == -1;
        });
      },
      maquinasFiltradas(step){
        console.log("IdArea: "+step)
        let ids = this.editedItem.receta[this.pos-1].filter(function(element){
          return typeof element.idMaquina !== 'undefined' && element.esMaquina;
        })
        .map(function(element){
          return element.idMaquina;
        });
        
        this.render++;
         return this.maquinas.filter(function(maquina) {
            return (maquina.idsAreas === null || maquina.idsAreas.includes(step)) &&
          ids.indexOf(maquina.idMaquina) == -1;
          });
      },
      herramientasFiltradas(){
        let ids = this.editedItem.receta[this.pos-1].filter(function(element){
          return typeof element.idMaquina !== 'undefined' && !element.esMaquina;
        })
        .map(function(element){
          return element.idMaquina;
        });        

        return this.herramientas.filter(function(element){
          return ids.indexOf(element.idMaquina) == -1;
        });
      },
      puestosFiltrados(){
        let ids = this.editedItem.receta[this.pos-1].filter(function(element){
          return typeof element.idPuesto !== 'undefined';
        })
        .map(function(element){
          return element.idPuesto;
        });        

        return this.puestos.filter(function(element){
          return ids.indexOf(element.idPuesto) == -1;
        });
      },
      desconocidosFiltrados(idDesconocido){
        let ids = this.editedItem.receta[this.pos-1].filter(function(element){
          return typeof element.idDesconocidoIns !== 'undefined';
        })
        .map(function(element){
          return element.idDesconocidoIns;
        });        

        return this.desconocidos[idDesconocido].filter(function(element){
          return ids.indexOf(element.idDesconocidoIns) == -1;
        });
      },
      insertItem(padre, child){
        var item = Object.assign({}, child);
        item.cantidad = 1;
        item.tiempo = 1;
        if(this.$utils.isValid(item.idArticulo)) item.critico = true;
        this.editedItem.receta[this.pos-1].push(item);
        // padre.children.splice( padre.children.indexOf( child ), 1 );
      },
      changeList(value){
        this.rPasos = (this.rPasos+1)%100;
      },
      checkTiempo(value,paso){
        if( this.editedItem.info[paso - 1].tiempoMin === '' || this.editedItem.info[paso - 1].tiempoMax === ''){
          this.editedItem.info[paso - 1].errorTiempo = true;
        }
        else{ 
          if(parseInt(this.editedItem.info[paso - 1].tiempoMin) > parseInt(this.editedItem.info[paso - 1].tiempoMax)) this.editedItem.info[paso - 1].errorTiempo = true;
          else this.editedItem.info[paso - 1].errorTiempo = false;
        }
        this.rPasos = (this.rPasos+1)%100;
      },
      toUpperCaseAfterSpace(string){
        let s = string.toLowerCase();
        s = this.setCharAt(s,0,s[0].toUpperCase());
        for(var i = 0; i<s.length; i++){
          if( s[i] == ' ') s = this.setCharAt(s,i+1,s[i+1].toUpperCase());
        }
        return s;
      },
      setCharAt(str,index,chr) {
        if(index > str.length-1) return str;
        return str.substr(0,index) + chr + str.substr(index+1);
      },
      clone: function(el){
        return Object.assign({}, el);
      },
      getColor(pos){
        return this.colors[pos % this.colors.length];
      },
      etiqueta: element => {
        if(typeof element.marca !== "undefined" && typeof element.idPuesto === 'undefined'){
          return element.categoria+' - '+element.marca+' - '+element.modelo;
        }
        else if (typeof element.idPuesto !== 'undefined'){
          return element.nombre;
        }
        else if(typeof element.codigo !== 'undefined'){
          return element.codigo+' - '+element.nombre
        }
        else{
          return "Hola";
        }
      },
      calcularFraccion(num,x,y,z){
        if(num == 1){
          return this.validFloat(x);
        }
        if(num == 2){
          return this.validFloat(x) * this.validFloat(y);
        }
        if(num == 3){
          return this.validFloat(x) * this.validFloat(y) * this.validFloat(z);
        }
      },
      costoPaso(numPaso){
        if(typeof this.editedItem.receta[numPaso - 1] === 'undefined') return "0.00";

        let costoMin = 0.0;
        let costoMax = 0.0;
        let me = this;
        this.editedItem.receta[numPaso - 1].forEach(function(element){
          if(typeof element.idDesconocido === 'undefined' && typeof element.idArticulo !== 'undefined'){
            if(element.costo == null) {
              return;
            }
            if(!element.conversion){
              costoMin += element.costo.min * me.validFloat(element.cantidad) * (1 + (me.validFloat(element.merma)/100));
              costoMax += element.costo.max * me.validFloat(element.cantidad)* (1 + (me.validFloat(element.merma)/100));
            }
            else{
              switch(element.numDimensiones){
                case 1:
                  costoMin += (me.validFloat(element.cantidad) / element.factorConversion) * element.costo.min * (1 + (me.validFloat(element.merma)/100));
                  costoMax += (me.validFloat(element.cantidad) / element.factorConversion) * element.costo.max * (1 + (me.validFloat(element.merma)/100));
                  break;
                case 2:
                  costoMin += ( me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * me.validFloat(element.cantidad) * (1 + (me.validFloat(element.merma)/100));
                  costoMax += ( me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * me.validFloat(element.cantidad) * (1 + (me.validFloat(element.merma)/100));
                  
                  break;
                case 3:
                  costoMin += ( me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.min * me.validFloat(element.cantidad) * (1 + (me.validFloat(element.merma)/100));
                  costoMax += ( me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ) / element.factorConversion) * element.costo.max * me.validFloat(element.cantidad) * (1 + (me.validFloat(element.merma)/100));
                  break;
              }
            }
          }
          else if(typeof element.idDesconocido === 'undefined' && typeof element.costo !== 'undefined'){
            costoMin += element.costo * me.validFloat(element.cantidad);
            costoMax += element.costo * me.validFloat(element.cantidad);
          }
          else if(typeof element.idDesconocido === 'undefined' && typeof element.costoHora !== 'undefined'){
            costoMin += element.cantidad * (element.costoHora / 3600) * element.tiempo;
            costoMax += element.cantidad * (element.costoHora / 3600) * element.tiempo;
          }
          else if(typeof element.idDesconocido !== 'undefined'){
            if(element.tipoCosto){
              costoMin += ( element.cantidad * element.costo );
              costoMax += ( element.cantidad * element.costo );
            }
            else{
              costoMin += (element.tiempo / 3600.0) * element.costo * element.cantidad;
              costoMax += (element.tiempo / 3600.0) * element.costo * element.cantidad;
            }
          }
        });

        return [costoMin,costoMax];
      },
      costoElemento(){
        if(this.numPasos > 1){
          let costoMin = 0.0;
          let costoMax = 0.0;
          for(var i = 1; i <= this.numPasos; i++){
            let costo =  this.costoPaso(i);
            costoMin += costo[0];
            costoMax += costo[1];
          }
          return [costoMin,costoMax];
        }
        else{
          if(this.numPasos == 1) return this.costoPaso(1);
          else return [0.0,0.0];
        }
      },
      getCostoDestajoPaso(numpaso){
        let sumatoriaDestajo = 0;
        sumatoriaDestajo += this.editedItem.info[ numpaso - 1 ].costoDestajo;
        this.editedItem.info[ numpaso - 1 ].subpasos.forEach(subpaso => {
          sumatoriaDestajo += subpaso.costoDestajo;
        })
        return sumatoriaDestajo;
      },
      getCostoDestajoTotal(){
        let sumatoriaDestajo = 0;
        this.editedItem.info.forEach( (info) => {
          sumatoriaDestajo += info.costoDestajo;
          info.subpasos.forEach((subpaso) => {
            sumatoriaDestajo += subpaso.costoDestajo;
          })
        });
        return sumatoriaDestajo;
      },
      costoElementoLabel(){
        const costoDestajoTotal = this.getCostoDestajoTotal();
        let costo = this.costoElemento();
        if(costo[0] == costo[1]) return "$"+(costo[0]+costoDestajoTotal).toFixed(2); 
        else return "$"+(costo[0]+costoDestajoTotal).toFixed(2)+" ~ "+"$"+(costo[1]+costoDestajoTotal).toFixed(2);
      },
      costoPasoLabel(numPaso){
        const costoDestajoPaso = this.getCostoDestajoPaso(numPaso);
        let costo = this.costoPaso(numPaso);
        if(costo[0] == costo[1]) return "$"+(costo[0]+costoDestajoPaso).toFixed(2); 
        else return "$"+(costo[0]+costoDestajoPaso).toFixed(2)+" ~ "+"$"+(costo[1]+costoDestajoPaso).toFixed(2);
      },
      validate(){
        let me = this;

        me.errores= [];

        if(me.editedItem.idArticulo < 0){
          me.errores.push("Falta seleccionar un "+me.name+" para realizar");
        }

        if(isNaN(parseInt(me.editedItem.dimLote)) || parseInt(me.editedItem.dimLote) < 1 ){
          me.errores.push("Falta indicar una cantidad de "+me.name+" a producir");
        }

        for (let i = 0; i < me.editedItem.numPasos; i++) {
          if( me.editedItem.info[i].idArea < 1 ) me.errores.push("Falta seleccionar una Área en el Paso "+ (i+1));

          if( !!me.editedItem.info[i].cantidadObjetivo ){
            if(
              isNaN(parseFloat(me.editedItem.info[i].cantidadObjetivo)) ||
              parseFloat(me.editedItem.info[i].cantidadObjetivo) <= 0
            ){
              this.errores.push("La Cantidad Objetivo QR debe ser mayor a 0 en el Paso "+ (i+1));
            }
          }

          if(this.editedItem.info[i].switch1){
            if(isNaN(parseFloat(this.editedItem.info[i].numPiezas))){
              this.errores.push(`Debe indicar el número de ${this.getUnidadActual(this.editedItem.info[i].switch1)} en Tiempo por Pza en el Paso ${i+1}`)
            }
            else if (parseFloat(this.editedItem.info[i].numPiezas) <= 0){
              this.errores.push(`Debe indicar un número de ${this.getUnidadActual(this.editedItem.info[i].switch1)} válido en Tiempo por Pza en el Paso ${i+1}`);
            }

            if(isNaN(parseInt(this.editedItem.info[i].minutos))){
              this.errores.push(`Debe indicar la cantidad de tiempo en Tiempo por Pza en el Paso ${i+1}`);
            }
            else if (parseInt(this.editedItem.info[i].minutos) <= 0){
              this.errores.push(`Debe indicar una cantidad de tiempo válido en Tiempo por Pza en el Paso ${i+1}`);
            }
            
            if(this.editedItem.info[i].tiempo == null){
              this.errores.push(`Debe seleccionar una unidad en Tiempo por Pza en el Paso ${i+1}`);
            }
          }
          
          if( this.editedItem.info[i].fraccion == null || isNaN(this.editedItem.info[i].fraccion) || this.editedItem.info[i].fraccion == "" ){
            this.errores.push("Debe indicar una cantidad minima a reportar valida en el Paso "+ (i+1));
          }else if(parseFloat(this.editedItem.info[i].fraccion) <= 0){
            this.errores.push("La cantidad minima a reportar debe ser mayor a 0 en el Paso "+ (i+1));
          }

          if(!this.editedItem.info[i].subsvalidos){
            this.errores.push("La suma de los tiempos de los Subpasos obligatorios no debe ser mayor al Tiempo Ideal en el Paso " + (i+1));
          }

          if(this.editedItem.info[i].subpasos.length > 0){
            let numObligatorios = 0;
            this.editedItem.info[i].subpasos.forEach( sub => {
              if(sub.obligatorio) numObligatorios += 1;
            });

            if(numObligatorios == 0){
              this.errores.push("Debe de existir al menos un Subpaso obligatorio en el Paso " + (i+1));
            }
          }

          this.editedItem.receta[i].forEach( (item,index) => {
            if( !!item.merma ){
              if(isNaN(item.merma)){
                this.errores.push(`El porcentaje de la merma es inválido en el ingrediente #${index+1} del Paso ${i+1}`);
              }
              else if(parseFloat(item.merma) < 0){
                this.errores.push(`El porcentaje de la merma es inválido en el ingrediente #${index+1} del Paso ${i+1}`);
              }
            }
          })
        
          if( me.editedItem.info[i].errorTiempo) me.errores.push("Tiempo Erroneo en el Paso " + (i+1));

          if (me.editedItem.receta[i].length < 1 ) me.errores.push("Falta agregar ingredientes al Paso "+(i+1));

          else{
            if (me.editedItem.receta[i].filter(function(element){
              return (isNaN(parseFloat(element.cantidad)) || parseFloat(element.cantidad) <= 0); 
            }).length > 0)me.errores.push("Falta ingresar una Cantidad en el Paso "+(i+1));
          }
        }

        if(me.errores.length > 0) me.alerta = true;
        else me.alerta = false;
        
        return (me.errores.length == 0);

      },
      getPasos(mode){
        let me = this;
        let pasos = [];
        for(var i = 0; i < me.editedItem.numPasos; i++){
          let paso = {
            IdArea: me.editedItem.info[i].idArea,
            cantidadObjetivo: !!this.editedItem.info[i].cantidadObjetivo ? parseFloat(this.editedItem.info[i].cantidadObjetivo) : null,
            Fraccion: parseFloat(me.editedItem.info[i].fraccion),
            NumPaso: (i + 1),
            TiempoMin: me.editedItem.info[i].tiempoMin,
            TiempoMax: this.hayMoldesEnReceta ? me.editedItem.info[i].tiempoMin : me.editedItem.info[i].tiempoMax,
            TiempoLimpieza: me.editedItem.info[i].tiempoLimpieza,
            Nota: me.editedItem.info[i].nota,
            subPasos: this.editedItem.info[i].subpasos,
            subpasosSeriados: this.editedItem.info[i].subpasosSeriados,
            idsMoldes: this.editedItem.info[i].moldes.map( molde => molde.idMolde ),
            numPiezas: parseFloat(this.editedItem.info[i].numPiezas),
            minutos: parseInt(this.editedItem.info[i].minutos),
            tiempo: this.editedItem.info[i].tiempo,
            tipoTiempo: this.editedItem.info[i].switch1,
            Ingredientes:me.editedItem.receta[i].map(function(element){
              return {
                IdArticulo: (typeof element.idArticulo === 'undefined')?null:element.idArticulo,
                IdMaquina: (typeof element.idMaquina === 'undefined')?null:element.idMaquina,
                IdPuesto: (typeof element.idPuesto === 'undefined')?null:element.idPuesto,
                IdDesconocidoIns: (typeof element.idDesconocidoIns === 'undefined')?null:element.idDesconocidoIns,
                Cantidad: (element.conversion && element.numDimensiones == 1)? 1 : me.validFloat(element.cantidad),
                Tiempo: (typeof element.tiempo === 'undefined')?null:element.tiempo,
                Remplazar: (typeof element.remplazar === 'undefined')?false:element.remplazar,
                RemplazarMaquina: (typeof element.remplazarMaquina === 'undefined')?false:element.remplazarMaquina,
                Critico: !!element.critico,
                Merma: !!element.merma ? parseFloat(element.merma) : null,
                Proporcional: element.conversion,
                Fraccion: (element.conversion)? (  (element.numDimensiones == 1)? (me.validFloat(element.cantidad)/element.factorConversion) : me.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ)  ) :null,
                EjeX: (element.conversion)? ( element.numDimensiones == 1 ? (me.validFloat(element.cantidad)/element.factorConversion) : me.validFloat(element._ejeX) ):null,
                EjeY: (element.conversion)?me.validFloat(element._ejeY):null,
                EjeZ: (element.conversion)?me.validFloat(element._ejeZ):null,
              }
            }),
            Imagenes:me.editedItem.info[i].imagenesb64.map(function(element){
              return {
                Mime: element.substring(5,element.indexOf(";")),
                Data: element.substring(element.indexOf("base64,")+7)
              }
            })
          };
          if(mode > 0){
            paso.ImagenesEliminar = me.editedItem.info[i].imagenesEliminar;
          }
          pasos.push(paso);
        }
        return pasos;
      },
      save(){
        let me = this;
        
        if(!this.validate()) return;

        me.saving = true;
        let obj = {
          IdArticulo: me.editedItem.idArticulo,
          Cantidad: me.editedItem.dimLote,
          NumPasos: me.editedItem.numPasos,
          Descripcion: me.editedItem.descripcion,
          revision: this.editedItem.revision,
          Pasos: me.getPasos(me.mode),
        }

        if(me.mode == 0 || me.mode == 3){
          axios
          .post("/Recetas/Crear",obj)
          .then(response =>  {
            me.saving = false;
            me.close();
          })
          .catch(error => {
            this.conflictoSubpaso(error);
            me.saving = false;
          });
        }
        else if(me.mode == 1){
          axios
          .put("/Recetas/Actualizar/"+this.recipe.idReceta, obj)
          .then(response =>  {
            me.saving = false;
            me.close();
          })
          .catch(error => {
            this.conflictoSubpaso(error);
            me.saving = false;
          });
        }
        else{
          obj.Nombre = this.recipe.nombre;
          obj.Codigo = this.recipe.codigo;
          obj.idReceta = this.recipe.idReceta;
          obj.EjeX = null;
          obj.FactorConversion = null;

          axios
          .post("/Recetas/CrearCompleto",obj)
          .then(response => {
            me.saving = false;
            me.close();
          })
          .catch(error => {
            me.saving = false;
          });
        }
      },
      close(){
        for(var i = 0; i < this.editedItem.numPasos; i++){
          this.editedItem.info[i].imagenesb64.splice(0,this.editedItem.info[i].imagenesb64.length);
          this.editedItem.info[i].imagenes.splice(0,this.editedItem.info[i].imagenes.length);
          this.editedItem.info[i].imagenesEliminar.splice(0,this.editedItem.info[i].imagenesEliminar.length);

          this.editedItem.info.imagenesb64 = [];
          this.editedItem.info.imagenes = [];
          this.editedItem.info.imagenesEliminar = [];
        }
        this.editedItem = this.clone(this.defaultItem);
        this.editedItem.info = [];
        this.editedItem.info.push(this.clone(this.infoDefault));
        this.editedItem.info[0].subpasos = [];
        this.editedItem.info[0].moldes = [];
        this.editedItem.info[0].imagenes = [];
        this.editedItem.info[0].imagenesEliminar = [];
        this.editedItem.info[0].imagenesb64 = [];
        this.loaded = 0;
        this.editedItem.receta = [[]];
        this.recursos = [];
        this.areas = [];
        this.maquinas = [];
        this.herramientas = [];
        this.puestos = [];
        this.panels = [];
        this.modoOrdenar = false;
        this.idArea = null;
        this.stepper = 0;
        //Otra lina nueva
        this.$emit('close', false);
      },
      makeValidation(string, min, max, msgError){
        if (string.length < min || string.length > max)
        {
            return msgError;
        }
        else return null;
      },
      validateElement(){
        this.elementErrores =  [];

        var val = null;
        val = this.makeValidation(this.addItem.nombre,2,200,"El Nombre debe tener al menos 2 caracteres y menos de 200.");
        if(val != null) this.elementErrores.push(val);

        val = this.makeValidation(this.addItem.codigo,2,20,"El Código debe tener al menos 2 caracteres y menos de 20.");
        if(val != null) this.elementErrores.push(val);

        if(this.addItem.idUnidad == -1){
          this.elementErrores.push('Debe de seleccionar una Unidad para el Artículo');
        }

        if(this.addItem.idArticuloCategoria <= 0 || this.addItem.idArticuloCategoria == null){
          this.elementErrores.push('Debe de seleccionar una Categoría de Artículo');
        }

        if(this.addItem.idArticuloTipo <= 0 || this.addItem.idArticuloTipo == null){
          this.elementErrores.push('Debe de seleccionar un Tipo de Artículo');
        }

        if( 
          this.addItem.idArticuloTipo == 3 &&
          this.addItem.idArticuloFamilia.length == 0
        ){
          this.elementErrores.push('Debe de seleccionar al menos una Familia.');
        }

        return (this.elementErrores.length == 0);
      },
      saveElement(){
        if(this.validateElement()){
          this.savingElement = true;

          let t = [];
          if(this.addItem.idArticuloTipo == 3){
            t.push(this.addItem.idArticuloFamilia);
          }

          axios
            .post("/Articulos/CrearCompleto",{
              IdUnidad: this.addItem.idUnidad,
              Nombre: this.addItem.nombre,
              Codigo: this.addItem.codigo,
              salidaAutomatica: this.addItem.salidaAutomatica,
              IdArticuloCategoria: this.addItem.idArticuloCategoria,
              IdArticuloTipo: this.addItem.idArticuloTipo,
              IdsArticuloFamilias: (this.addItem.idArticuloTipo == 3) ? t : this.addItem.idArticuloFamilia,
              idArticuloClase: this.addItem.idArticuloClase,
              FactorConversion: this.addItem.factorConversion,
              EjeX: (this.unidadConversion.numDimensiones == 1) ? this.addItem.factorConversion : this.validFloat(this.addItem.ejeX),
              EjeY: (this.unidadConversion.numDimensiones >= 2) ?this.validFloat(me.addItem.ejeY) : null,
              EjeZ: (this.unidadConversion.numDimensiones == 3) ?this.validFloat(me.addItem.ejeZ) : null,
            })
            .then(response => {
              this.elementos.splice( this.elementos.length - 1, 0, response.data );
              this.editedItem.idArticulo = response.data.idArticulo;
              this.savingElement = false;
              this.addItem = Object.assign({}, this.defaultAddItem);
              this.addElement = false;
            })
            .catch(error => {
              console.log(error);
              this.savingElement = false;
              if (error.response) {
                if(error.response.status == 409){
                  this.elementErrores = [];
                  this.elementAlert = true;
                  this.elementErrores.push("Existe un "+this.name+" con el mismo Nombre o Código");
                }
              }
            });
        }
      },
      closeElement(){
        this.editedItem.idArticulo = -1;
        this.addItem = Object.assign({}, this.defaultAddItem);
        this.addElement = false;
      },
      addImage(paso){
        this.showAddImage = true;
        this.pasoImagen = paso;
      },
      pushImage(){
        this.editedItem.info[this.pasoImagen-1].imagenesb64.push(this.previewb64);
        this.closeShowImage();
      },
      closeShowImage(){
        this.fileImage = null;
        this.previewb64 = "";
        this.showAddImage = false;
      },
      showImage(event){
        this.getBase64(event)
        .then(data =>{
          this.previewb64 = data;
        })
        .catch(error => {
          this.previewb64 = "";
          console.log(error);
        });
      },
      removeImageB64(pos){
        this.posImagenB64 = pos;
        this.showRemoveImageB64 = true;
      },
      deleteImageB64(){
        this.editedItem.info[this.pasoImagen-1].imagenesb64.splice(this.posImagenB64,1);
        this.showRemoveImageB64 = false;
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error);
        });
      },
      removeImage(idx, pos){
        this.pasoImagen = pos;
        this.posImagen = idx;
        this.showRemoveImage = true;
      },
      deleteImage(){
        var filename = this.editedItem.info[this.pasoImagen-1].imagenes[this.posImagen].fileName;
        this.editedItem.info[this.pasoImagen-1].imagenesEliminar.push(filename);
        this.editedItem.info[this.pasoImagen-1].imagenes.splice(this.posImagen,1);
        this.showRemoveImage = false;
      },
      convertirHorasMinutos(posicion){
        if(this.editedItem.info[posicion].tiempo == null) return;
        if(this.editedItem.info[posicion].minutos == null || this.editedItem.info[posicion].minutos == '') return;
        if(this.editedItem.info[posicion].numPiezas == null || this.editedItem.info[posicion].numPiezas == '') return;

        
        var seconds = this.editedItem.info[posicion].minutos * (this.editedItem.info[posicion].tiempo == 1 ? 60 : 3600) / this.editedItem.info[posicion].numPiezas;
        this.editedItem.info[posicion].tiempoMin = seconds
        this.editedItem.info[posicion].tiempoMax = seconds + 2

        var horas = Math.floor(seconds / 3600);
        var minutos = Math.floor((seconds % 3600) / 60);
        var segundosRestantes = seconds % 60;
          
        return (horas < 10 ? '0'+horas : horas) + ':' + (minutos < 10 ? '0'+minutos: minutos) + ':' + (segundosRestantes <9? '0'+ segundosRestantes % 1 == 0 ? segundosRestantes : segundosRestantes.toFixed(0)  : segundosRestantes % 1 == 0 ? segundosRestantes : segundosRestantes.toFixed(0));
      }
    },
  }
</script>

<style scoped>
  .padding-0px{
    padding-top: 0px !important;
  }

  .no-padding{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    margin-top: auto;
    margin-bottom: auto;
  }
  .margin-auto{
    margin: auto !important;
  }

  .listaRecetas {
    background-color:white !important; 
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    max-height: 570px !important;
    max-width: auto !important;
  }

  .v-list-item__title{
    white-space: normal !important;
  }

  .v-stepper__header{
    height: auto;
  }

  .v-divider{
    margin-left: 0px !important;
    margin-right: 5px !important;
  }
</style>