<template>
  <v-col cols="12" class="px-8" v-if="permisoVista('reporteoperacion','r')">
    <v-row :class="(modeSelect||modeDetalle) && 'mb-6'">
      <v-col cols="4" md="4" sm="4">
        <v-autocomplete
          v-model="idsOperadores"
          label="Operadores (Opcional)"
          :items="operadores"
          :item-text="nombrePersona"
          item-value="idPersona"
          multiple
          chips
          dense
          hide-details
          clearable
          @change="operaciones_update_page_action(1)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end">
        <VueCtkDateTimePicker
          id="fecInicio"
          v-model="inicio"
          label="Inicio (Opcional)"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="operaciones_update_page_action(1)"
        />
      </v-col>
      <v-col cols="4" md="4" sm="4" class="align-self-end" :key="'f-'+inicio">
        <VueCtkDateTimePicker
          id="fecFin"
          v-model="fin"
          label="Fin (Opcional)"
          :min-date="inicio"
          format="YYYY-MM-DD HH:mm"
          formatted="YYYY-MM-DD HH:mm"
          noHeader
          overlay
          buttonNowTranslation="Ahora"
          @input="operaciones_update_page_action(1)"
        />
      </v-col>
    </v-row>
    <v-row v-show="!modeDetalle" >
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idsOTS"
          label="Orden de Trabajo (Opcional)"
          :items="ots"
          item-text="clave"
          item-value="idTrabajoOrden"
          multiple
          chips
          dense
          hide-details
          :clearable="!modeDetalle"
          :readonly="modeDetalle"
          @change="operaciones_update_page_action(1)"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="6" md="6" sm="6" class="align-self-end">
        <v-autocomplete
          v-model="idsAreas"
          label="Áreas (Opcional)"
          :items="areas"
          item-text="nombre"
          item-value="idArea"
          multiple
          chips
          dense
          hide-details
          :clearable="!modeDetalle"
          :readonly="modeDetalle"
          @change="operaciones_update_page_action(1)"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <download-btn
        v-if="!modeSelect && !modeDetalle"
        className="mb-6"
        color="blue darken-1"
        text
        @click="descargarReporte"
        label="Descargar Reporte de Operación"
      ></download-btn>
    </v-row>
    <v-data-table
      v-model="value"
      :single-select="true"
      :show-select="modeSelect"
      item-key="idOperacionReal"
      :headers="headersFiltrados"
      :items="operaciones"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
      :server-items-length="operaciones_total"
      :items-per-page="operaciones_items_per_page"
      :page="operaciones_actual_page"
      @update:page="operaciones_update_page_action"
      @update:items-per-page="(ipp) => operaciones_items_per_page = ipp"
      :footer-props="{disableItemsPerPage:true, disablePagination: this.loading }"
      :disable-sort="true"
      @input="rowSelected"
    >
      <template v-slot:item.articuloProducir="{ item }">
        {{item.cantidadProducir.toFixed(2)}} {{item.unidadProducir}} - {{item.articuloProducir}}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{item.cantidad.toFixed(2)}} {{item.unidad}}
      </template>
      <template v-slot:item.articulo="{ item }">
        <p v-if="item.subpaso!=null" class="py-2 mb-0">{{item.subpaso}}<br/><span class="font-weight-bold" style="font-size: 14px !important">{{item.articulo}}</span></p>
        <p v-else class="py-2 mb-0">{{item.articulo}}</p>
      </template>
      <template v-slot:item.cantidadAuxiliar="{ item }">
        {{ 
          item.cantidadAuxiliar != null &&
          item.cantidadAuxiliar != 0
          ? `${item.cantidadAuxiliar.toFixed(2)} ${item.unidadAuxiliarAbreviaturaProducir!=null?item.unidadAuxiliarAbreviaturaProducir:''}`
          : 'N/A'
        }}
      </template>
      <template v-slot:item.cantidadDefecto="{ item }">
        {{item.cantidadDefecto.toFixed(2)}} {{item.unidad}}
      </template>
      <template v-slot:item.inicio="{ item }">
        {{item.inicio.replace('T',' ')}}
      </template>
      <template v-slot:item.fin="{ item }">
        {{item.fin.replace('T',' ')}}
      </template>
      <template v-slot:item.operadores="{ item }">
        <ul>
          <li v-for="(operador,idx) in item.operadores" :key="'op-'+idx">{{operador}}</li>
        </ul>
      </template>
      <template v-slot:item.maquinas="{ item }">
        <p 
          v-if="item.maquinas.length==0" 
          class="mb-0"
          >N/A</p
        >
        <ul v-else>
          <li v-for="(maquina,idx) in item.maquinas" :key="'m-'+idx">{{maquina.nombre}}</li>
        </ul>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from 'vuex';
import DownloadBtn from '../../DownloadBtn.vue';

  export default {
	components: { DownloadBtn },
    props: {
      modeDetalle: {
        type: Boolean,
        default: false
      },
      modeSelect: {
        type: Boolean,
        default: false
      },
      operaciones_items_per_page: {
        type: Number,
        default: 10
      },
      value: {
        type: Array,
        default: []
      },
      idTrabajoOrden: {
        type: Number,
        default: 0,
      },
      idArea: {
        type: Number,
        default: 0,
      },
    },
    data(){
      return {
        selectedOperaciones: [],
        headers:[
          {
            text: "Orden de Trabajo",
            align: "center",
            value: "trabajoOrden"
          },
          {
            text: "Artículo Producir",
            align: "center",
            value: "articuloProducir"
          },
          {
            text: "Área",
            align: "center",
            value: "area"
          },
          {
            text: "Componente",
            align: "center",
            value: "articulo"
          },
          {
            text: "Tiempo Pieza Planeado",
            align: "center",
            value: "tiempoPiezaPlaneado"
          },
          {
            text: "Tiempo Pieza Real",
            align: "center",
            value: "tiempoPiezaReal"
          },
          {
            text: "Cantidad",
            align: "center",
            value: "cantidad"
          },
          {
            text: "Cantidad Auxiliar",
            align: "center",
            value: "cantidadAuxiliar"
          },
          {
            text: "Cantidad Defecto",
            align: "center",
            value: "cantidadDefecto"
          },
          {
            text: "Inicio",
            align: "center",
            value: "inicio"
          },
          {
            text: "Fin",
            align: "center",
            value: "fin"
          },
          {
            text: "Operadores",
            align: "center",
            value: "operadores"
          },
          {
            text: "Máquinas",
            align: "center",
            value: "maquinas"
          },
        ],
        operaciones:[],
        search:"",
        loading: false,
        operaciones_total: null,
        operaciones_actual_page :1,
        operadores:[],
        idsOperadores:[],
        inicio:null,
        fin:null,
        idsOTS:this.idTrabajoOrden!=0?[this.idTrabajoOrden]:[],
        ots:[],
        idsAreas:this.idArea!=0?[this.idArea]:[],
        areas:[],
      }
    },

    computed: {
      ...mapState(["reporteAuxiliar"]),
      headersFiltrados(){
        return this.reporteAuxiliar
          ? this.headers
          : this.headers.filter( head => head.value!="cantidadAuxiliar" )
      },
      operaciones_fetch_url() {
        let url = `/Reportes?page=${this.operaciones_actual_page}&size=${this.operaciones_items_per_page}`;
        if(this.idsOperadores.length > 0){
          url +=('&operadores='+this.idsOperadores.join(','));
        }
        if(this.inicio != null){
          url += '&inicio='+this.inicio;
        }
        if(this.fin != null){
          url += '&fin='+this.fin;
        }
        if(this.idsAreas.length > 0){
          url +=('&areas='+this.idsAreas.join(','));
        }
        if(this.idsOTS.length > 0){
          url +=('&ots='+this.idsOTS.join(','));
        }
        return url;
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      nombrePersona: persona => persona.nombre + " " + persona.paterno + " " + persona.materno,
      rowSelected(selectedItems){
        this.$emit( 'change',selectedItems );
      },
      getOperacionSelected(){
        return this.selectedOperaciones[0];
      },
      getOperadores(){
        axios
        .get("/Personas/Operadores")
        .then(response => {
          this.operadores = response.data;
        })
        .catch(error => {
          console.log(error);
        })
      },
      getAreas(){
        axios
        .get("/Reportes/Areas")
        .then(response => {
          this.areas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
      },
      getOTS(){
        axios
        .get("/Reportes/OTS")
        .then(response => {
          this.ots = response.data;
        })
        .catch(error => {
          console.log(error);
        })
      }, 
      async initialize() {
        this.getOperadores();
        this.getAreas();
        this.getOTS();
        this.loading = true;
        try {
          let response = await this.getOperaciones();
          this.operaciones = response.data.result;
          this.operaciones_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.error(exception);
        }
      },
      async operaciones_update_page_action(page) {
        this.operaciones_actual_page = page;
        this.loading = true;
        try {
          let response = await this.getOperaciones();
          this.operaciones = response.data.result;
          this.operaciones_total = response.data.total;
          this.loading = false;
        } catch (exception) {
          console.log(exception);
        }
      },
      getOperaciones() {
      //* Realizamos peticion HTTP
      return new Promise((resolve, reject) => {
        axios
          .get(this.operaciones_fetch_url)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
      },
  
    descargarReporte(){
      
      var datetime = moment().format('YYYYMMDDHHmmss');

      this.$utils.axios.downloadFile('/Reportes/PorOperacion/xlsx'+this.operaciones_fetch_url+'','GET','ReportePorOperación-'+datetime+'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    },
    },
  }
</script>