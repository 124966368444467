2<template>
	<v-container>
		<v-dialog v-model="dialogRecursos" persistent max-width="500px">
			<v-card>
				<v-card-title>Agregar Evidencia</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="itemRecurso.titulo"
						label="Titulo"
						clearable
						:error-messages="itemRecurso.errorTitulo"
						@focus="$set(itemRecurso,'errorTitulo',null)"
					></v-text-field>
					<v-file-input
						v-model="itemRecurso.idsDocumentos"
						label="Seleccione su archivo"
						accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.svg"
						show-size
						:disabled="subiendoArchivos"
						:error-messages="itemRecurso.errorDocumento"
						@focus="$set(itemRecurso,'errorDocumento',null)"
						@change="cambioFicheroRecurso"
					></v-file-input>
					<v-progress-linear
						v-model="porcentajeCarga"
						v-show="subiendoArchivos"
						height="20"
						class="my-4"
					>
						<span class="font-weight-medium">
							{{ Math.ceil(porcentajeCarga) }}%
						</span>
					</v-progress-linear>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="error"
						:disabled="subiendoArchivos"
						@click="cerrarDialogRecursos"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="success"
						:loading="subiendoArchivos"
						:disabled="subiendoArchivos"
						@click="agregarRecurso"
						>Agregar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-simple-table class="comprobaciones-table">
			<template v-slot:default>
				<thead>
					<tr>
						<th
							class="text-center"
							style="font-size: 16px"
						>
							Comprobaciones
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="tareaMantenimiento.length == 0 || loading">
						<td>
							<v-alert color="blue-grey" text class="mb-0">
								<div
									v-if="tareaMantenimiento.length == 0 && !loading"
									class="text-center"
									style="font-size: 14px"
								>
									No hay comprobaciones.
								</div>
								<div
									v-if="loading"
									class="text-center d-flex justify-center align-center"
									style="font-size: 14px"
								>
									<v-progress-circular
										indeterminate
										color="blue-grey"
										class="mr-2"
									></v-progress-circular>
									Cargando
								</div>
							</v-alert>
						</td>
					</template>
					<template v-if="tareaMantenimiento.length > 0">
						<tr
							v-for="(item, idx) in tareaMantenimiento"
							:key="idx"
							:class="{
								'comprobacion-table-rows-par': (idx + 1) % 2 == 0,
								'comprobacion-table-rows-impar': (idx + 1) % 2 != 0
							}"
						>
							<td :class="{ 'border-bottom-dashed': idx < tareaMantenimiento.length - 1 }">
								<v-container fluid class="px-0">
									<v-simple-table
										:class="{
											'comprobacion-item-rows-par': (idx + 1) % 2 == 0,
											'comprobacion-item-rows-impar': (idx + 1) % 2 != 0
										}"
									>
										<template v-slot:default>
											<thead>
												<tr>
													<th style="width: 50%; height: 0; border-bottom: none"></th>
													<th style="width: 50%; height: 0; border-bottom: none"></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td
														:colspan="item.tipoComprobacion == null ? 2 : 1"
														class="text-center"
													>
														{{ item.comprobacion }}
													</td>
													<td v-if="item.tipoComprobacion != null">
														<template v-if="item.tipoComprobacion == 1">
															<v-text-field
																v-model="item.respuesta"
																label="Respuesta"
																placeholder="Valor númerico"
																type="number"
																hide-spin-buttons
																clearable
																:disabled="disabled"
																:error-messages="item.errorRespuesta"
																@focus="$set(item,'errorRespuesta',null)"
															></v-text-field>
														</template>
														<template v-else-if="item.tipoComprobacion == 2">
															<v-radio-group
																v-model="item.respuesta"
																row
																class="mt-2 mb-1 custom-radio-group"
																hide-details
																:disabled="disabled"
																:error="item.errorRespuesta"
																@change="$set(item,'errorRespuesta',null)"
															>
																<v-radio
																	v-for="opcion,idxOpc in item.opciones"
																	:key="`opcion - ${idxOpc}`"
																	class="mb-2"
																	:label="opcion"
																	:value="opcion"
																></v-radio>
															</v-radio-group>
															<div style="height: 14px">
																<v-slide-y-transition>
																	<p
																		v-if="item.errorRespuesta"
																		class="mb-0 error--text text-center"
																		style="font-size: 12px !important"
																		>{{ item.errorRespuesta }}</p
																	>
																</v-slide-y-transition>
															</div>
														</template>
													</td>
												</tr>
												<tr>
													<td class="text-center">Recursos</td>
													<td class="text-center">
														<template v-if="item.documentos.length == 0 && item.links.length == 0">
															N/A
														</template>
														<v-chip-group v-else column class="centered-chips">
															<v-chip
																v-for="(documento, idx) in item.documentos"
																:key="'docs-'+idx"
																outlined
																color="blue"
																:href="`/api/Documentos/Descargar/${documento}?access_token=${token}`"
															>
																<v-icon left>mdi-file-outline</v-icon>
																{{ $utils.strings.truncateElipsis(documento) }}
																<v-icon right size="18">mdi-download</v-icon>
															</v-chip>
															<v-chip
																v-for="(link, idx) in item.links"
																:key="'links-'+idx"
																outlined
																color="blue"
																:href="link.url.includes('http') ? link.url : `https://${link.url}`"
																target="_blank"
															>
																<v-icon left>mdi-link</v-icon>
																{{ $utils.strings.truncateElipsis(link.titulo) }}
																<v-icon right size="18">mdi-open-in-new</v-icon>
															</v-chip>
														</v-chip-group>
													</td>
												</tr>
												<tr>
													<td class="text-center"> ¿Comprobación satisfactoria? </td>
													<td class="text-center">
														<v-btn-toggle
															v-model="item.satisfactoria"
															class="my-3"
															@change="onChangeSatisfactoria(item)"
														>
															<v-btn
																depressed
																small
																class="ma-0"
																:disabled="disabled"
																:color="item.satisfactoria == 0 ? 'success' : 'grey lighten-1'"
																>Si</v-btn
															>
															<v-btn
																depressed
																small
																class="ma-0"
																:disabled="disabled"
																:color="item.satisfactoria == 1 ? 'error' : 'grey lighten-1'"
																>No</v-btn
															>
														</v-btn-toggle>
														<v-slide-y-transition>
															<p
																v-if="item.errorSatisfactoria"
																class="mb-0 error--text text-center"
																style="font-size: 12px !important"
																>{{ item.errorSatisfactoria }}</p
															>
														</v-slide-y-transition>
													</td>
												</tr>
												<template v-if="item.satisfactoria == 1">
													<tr>
														<td class="text-center">Comentarios</td>
														<td class="py-2">
															<v-textarea
																v-model="item.comentario"
																label="Comentarios"
																auto-grow
																rows="1"
																clearable
																:disabled="disabled"
																single-line
																hide-details
															></v-textarea>
														</td>
													</tr>
													<tr>
														<td class="text-center">Evidencias</td>
														<td>
															<v-col cols="12">
																<v-chip-group column class="centered-chips">
																	<v-chip
																		v-for="(evidencia, idx) in item.idsDocumentos"
																		:key="`evidencia-${idx}`"
																		close
																		outlined
																		:disabled="disabled"
																		color="blue"
																		@click:close="removerRecurso(item,evidencia)"
																	>
																		<v-icon left>mdi-file-outline</v-icon>
																		{{ $utils.strings.truncateElipsis(evidencia.nombre) }}
																	</v-chip>
																</v-chip-group>
																<div
																	class="text-center"
																	:class="{ 'pt-2': item.idsDocumentos.length == 0 }"
																>
																	<v-btn
																		color="light-blue lighten-1"
																		outlined
																		:disabled="disabled"
																		small
																		@click="showDialogRecursos(item.idMantenimientoComprobacion)"
																		>Agregar Evidencia</v-btn
																	>
																</div>
															</v-col>
														</td>
													</tr>
													<tr>
														<td class="text-center">¿Se solucionó?</td>
														<td class="text-center">
															<v-btn-toggle
																v-model="item.resuelto"
																class="my-3"
																@change="onChangeResuelto(item)"
															>
																<v-btn
																	depressed
																	small
																	class="ma-0"
																	:disabled="disabled"
																	:color="item.resuelto == 0 ? 'success' : 'grey lighten-1'"
																	>Si</v-btn
																>
																<v-btn
																	depressed
																	small
																	class="ma-0"
																	:disabled="disabled"
																	:color="item.resuelto == 1 ? 'error' : 'grey lighten-1'"
																	>No</v-btn
																>
															</v-btn-toggle>
															<v-slide-y-transition>
																<p
																	v-if="item.errorResuelto"
																	class="mb-0 error--text text-center"
																	style="font-size: 12px !important"
																	>{{ item.errorResuelto }}</p
																>
															</v-slide-y-transition>
														</td>
													</tr>
													<tr v-if="item.resuelto == 1 && idMantenimientoServicio == null">
														<td class="text-center">Programar Mantenimiento</td>
														<td>
															<v-select
																v-model="item.tipo"
																class=""
																:items="tiposMantenimientos"
																label="Tipo de Mantenimiento"
																item-text="nombre"
																item-value="id"
																:disabled="disabled"
																:error-messages="item.errorTipoMantenimiento"
																@focus="$set(item,'errorTipoMantenimiento', null)"
																@change="$set(item,'idMantenimiento',null)"
															></v-select>
															<MenuDatepicker
																v-model="item.horaInicio"
																label="Fecha Programada"
																clearable
																:disabled="disabled"
																:error-message="item.errorFechaProgramada"
																@focus="$set(item,'errorFechaProgramada', null)"
															/>
															<v-autocomplete
																v-if="item.tipo == 1"
																v-model="item.idMantenimiento"
																class="pb-2"
																:disabled="disabled"
																:items="preventivosFiltrados"
																item-value="idMantenimientoPreventivo"
																:item-text="nombreMantenimiento"
																label="Seleccione un Mantenimiento"
																:error-messages="item.errorMantenimiento"
																@focus="$set(item,'errorMantenimiento', null)"
															></v-autocomplete>
															<v-autocomplete
																v-if="item.tipo == 2"
																v-model="item.idMantenimiento"
																:disabled="disabled"
																:items="correctivosFiltrados"
																item-value="idMantenimientoCorrectivo"
																:item-text="nombreMantenimiento"
																label="Seleccione un Mantenimiento"
																:error-messages="item.errorMantenimiento"
																@focus="$set(item,'errorMantenimiento', null)"
															></v-autocomplete>
														</td>
													</tr>
												</template>
											</tbody>
										</template>
									</v-simple-table>
								</v-container>
							</td>
						</tr>
					</template>
				</tbody>
			</template>
		</v-simple-table>
	</v-container>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import MenuDatepicker from '../../MenuDatepicker.vue';

export default {
	components: { MenuDatepicker },
	props: {
		idMantenimientoServicio: {
			type: Number,
			default: null
		},
		idMantenimientoTarea: {
			type: Number,
			default: 0
		},
		idMantenimientoInspeccion: {
			type: Number,
			default: null
		},
		idMantenimientoCorrectivo: {
			type: Number,
			default: null
		},
		idMantenimientoCalendarioInspeccion: {
			type: Number,
			default: null
		},
		idMaquina: {
			type: Number,
			default: null
		},
		tipoReporte: {
			type: Number,
			required: true
		},
		loadingComprobaciones: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			defaultItemTareaMantenimiento: {
				idsDocumentos: [],
				tipo: null,
				idMantenimiento: null,
				horaInicio: null,
				horaFin: null,
				resuelto: null,
				tipoComprobacion: null,
				respuesta: null,
				errorRespuesta: null,
				errorSatisfactoria: null,
				errorResuelto: null,
				errorTipoMantenimiento: null,
				errorFechaProgramada: null,
				errorMantenimiento: null
			},
			correctivos: [],
			preventivos: [],
			errores: [],
			dialogTerminar: false,
			dialogRecursos: false,
			tareaMantenimiento: [],
			itemRecurso: {
				idMantenimientoComprobacion: -1,
				titulo: null,
				idsDocumentos: null,
				errorTitulo: null,
				errorDocumento: null
			},
			defaultItemRecurso: {
				idMantenimientoComprobacion: -1,
				titulo: null,
				idsDocumentos: null,
				errorTitulo: null,
				errorDocumento: null
			},
			subiendoArchivos: false,
			porcentajeCarga: 0,
			tiposMantenimientos: [
				{
					id: 1,
					nombre: "Preventivo",
				},
				{
					id: 2,
					nombre: "Correctivo",
				},
			],
			validacionItem: {
				status: 0,
				solucionados: 0,
				tipos: 0,
				idMantenimientos: 0,
				fecInicios: 0,
				fecFin: 0,
			},
			defaultValidacionItem: {
				status: 0,
				solucionados: 0,
				tipos: 0,
				idMantenimientos: 0,
				fecInicios: 0,
				fecFin: 0,
			},
		};
	},
	computed: {
		preventivosFiltrados(){
			return this.preventivos.filter( (preventivo) => preventivo.idMaquina == this.idMaquina )
		},
		correctivosFiltrados(){
			return this.correctivos.filter( (correctivo) => correctivo.idMaquina == this.idMaquina )
		},
		loading: {
			get(){
				return this.loadingComprobaciones;
			},
			set(newValue){
				this.$emit('update:loadingComprobaciones',newValue);
			}
		},
		...mapState(["token"]),
	},
	mounted() {
		this.getMantenimientosPreventivos();
		this.getMantenimientosCorrectivos();
		if(this.tipoReporte == 4){
			this.getComprobacionesServicios();
		}
		else if(this.tipoReporte == 3){
			this.getComprobacionesInspecciones();
		}
		else if(this.tipoReporte == 1 || this.tipoReporte == 2){
			this.getComprobacionesMantenimientos();
		}
		else {
			this.getComprobacionesCorrectivos();
		}
	},
	methods: {
		nombreMantenimiento: (item) => `[${item.codigo}] - ${item.nombre}`,
		onChangeResuelto(item){
			this.$set(item,'errorResuelto',null);
			this.$set(item,'tipo',null);
			this.$set(item,'idMantenimiento',null);
			this.$set(item,'horaInicio',null);
			this.$set(item,'horaFin',null);
		},
		onChangeSatisfactoria(item){
			this.$set(item,'errorSatisfactoria',null);
			this.$set(item,'errorResuelto',null);
			this.$set(item,'idsDocumentos',[]);
			this.$set(item,'comentario',null);
			this.$set(item,'tipo',null);
			this.$set(item,'idMantenimiento',null);
			this.$set(item,'horaInicio',null);
			this.$set(item,'horaFin',null);
			this.$set(item,'resuelto',null);
		},
		getMantenimientosPreventivos() {
			axios
				.get("/Mantenimientos/Preventivos")
				.then((response) => {
					this.preventivos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientosCorrectivos() {
			axios
				.get("/Mantenimientos/MantenimientoCorrectivos")
				.then((response) => {
					this.correctivos = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		resetErroresComprobaciones(){
			this.errores = [];
			for (const tarea of this.tareaMantenimiento) {
				for (const key in tarea) {
					if(key.toLocaleLowerCase().includes('error')){
						tarea[key] = null;
					}
				}
			}
		},
		validate() {
			this.resetErroresComprobaciones();

			for (const tarea of this.tareaMantenimiento) {
				if (tarea.tipoComprobacion != null) {
					if(!tarea.respuesta) this.$set(tarea,'errorRespuesta','Requerido');
					else if(tarea.tipoComprobacion == 1){
						const parsedValue = parseFloat(tarea.respuesta);
						if(isNaN(parsedValue)) this.$set(tarea,'errorRespuesta','Invalido');
					}
				}

				if(tarea.satisfactoria == null) this.$set(tarea,'errorSatisfactoria','Requerido');
				else if(tarea.satisfactoria == 1) {
					if(tarea.resuelto == null) this.$set(tarea,'errorResuelto','Requerido');
					else if(tarea.resuelto == 1 && this.idMantenimientoServicio == null){
						if(tarea.tipo == null) this.$set(tarea,'errorTipoMantenimiento','Requerido');
						
						if(tarea.horaInicio == null) this.$set(tarea, 'errorFechaProgramada', 'Requerido');
						
						if(tarea.idMantenimiento == null) this.$set(tarea, 'errorMantenimiento', 'Requerido');
					}
				}
			}

			let hayErrores = this.tareaMantenimiento.some( tarea => {
				for (const key in tarea) {
					if(key.toLocaleLowerCase().includes('error') && tarea[key] != null){
						return true;
					}
				}
				return false;
			});

			return !hayErrores;
		},
		terminarTarea() {
			const item = {
				idMantenimientoTarea: this.idMantenimientoTarea,
				comprobaciones: this.tareaMantenimiento.map((tarea) => ({
					idMantenimientoComprobacion:
						tarea.idMantenimientoComprobacion,
					respuesta: tarea.respuesta,
					satisfactoria: !tarea.satisfactoria,
					comentario: tarea.comentario,
					idsDocumentos: tarea.idsDocumentos.map(
						(doc) => doc.idDocumento
					),
					idMantenimientoPreventivo:
						tarea.tipo == 1 ? tarea.idMantenimiento : null,
					idMantenimientoCorrectivo:
						tarea.tipo == 2 ? tarea.idMantenimiento : null,
					idMantenimientoServicio: tarea.tipo == 3 ? tarea.idMantenimiento : null,
					horaInicio: tarea.horaInicio,
					resuelto: !tarea.resuelto,
				})),
			};

			return item;
		},
		showDialogRecursos(idMantenimientoComprobacion) {
			this.itemRecurso.idMantenimientoComprobacion =
				idMantenimientoComprobacion;
			this.dialogRecursos = true;
		},
		resetComprobaciones(){
			this.resetErroresComprobaciones();
			this.idMantenimientoTarea = null;
			this.idMantenimientoServicio = null;
			this.tareaMantenimiento = [];
			this.idMaquina = null;
		},
		cerrarDialogRecursos() {
			this.itemRecurso = structuredClone(this.defaultItemRecurso);
			this.dialogRecursos = false;
			this.porcentajeCarga = 0;
		},
		resetErroresRecurso(){
			for (const key in this.itemRecurso) {
				if(key.toLocaleLowerCase().includes('error')){
					this.itemRecurso[key] = null;
				}
			}
		},
		validateRecurso(){
			this.resetErroresRecurso();

			if(this.itemRecurso.titulo == null){
				this.$set(this.itemRecurso,'errorTitulo','Requerido');
			}
			else if(this.itemRecurso.titulo.length < 5){
				this.$set(this.itemRecurso,'errorTitulo','Mínimo 5 caracteres');
			}
			
			if(this.itemRecurso.idsDocumentos == null){
				this.$set(this.itemRecurso,'errorDocumento','Requerido');
			}

			const hayErrores = Object.keys(this.itemRecurso).some( (key) => {
				if(key.toLocaleLowerCase().includes('error')){
					return this.itemRecurso[key] != null;
				}
				return false;
			});

			return !hayErrores;
		},
		agregarRecurso() {
			if (!this.validateRecurso()) return;

			let documentos = new FormData();
			const docName = this.itemRecurso.titulo;
			documentos.append("ficheros",this.itemRecurso.idsDocumentos);

			this.subiendoArchivos = true;

			axios
				.post(`/Documentos/Cargar?titulo=${docName}`, documentos, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (progressEvent) => {
						this.porcentajeCarga = parseInt(
							Math.round(
								(progressEvent.loaded /
									progressEvent.total) *
									100
							)
						);
					}
				})
				.then((response) => {
					const documentosSubidos = response.data.map((doc) => ({
						idDocumento: doc.idDocumento,
						nombre: doc.nombre,
					}));

					if (documentosSubidos.length > 0) {
						this.tareaMantenimiento =
							this.tareaMantenimiento.map((item) => {
								if (
									item.idMantenimientoComprobacion ==
									this.itemRecurso
										.idMantenimientoComprobacion
								) {
									item.idsDocumentos =
										item.idsDocumentos.concat(
											documentosSubidos
										);
								}
								return item;
							});
					}
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.subiendoArchivos = false;
					this.cerrarDialogRecursos();
				});
		},
		removerRecurso(itemDelete, resourceDelete) {
			axios
				.put(`/Documentos/Eliminar/${resourceDelete.nombre}`)
				.then(() => {
					this.tareaMantenimiento = this.tareaMantenimiento.map(
						(item) => {
							if (
								item.idMantenimientoComprobacion ==
								itemDelete.idMantenimientoComprobacion
							) {
								item.idsDocumentos = item.idsDocumentos.filter(
									(doc) =>
										doc.idDocumento !=
										resourceDelete.idDocumento
								);
							}
							return item;
						}
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cambioFicheroRecurso(event) {
			if (typeof event === "undefined" || event === null)
				this.itemRecurso.idsDocumentos = null;
			else this.itemRecurso.idsDocumentos = event;
		},
		cerrarDialogTerminar() {
			this.errores = [];
			this.tareaMantenimiento = [];
			// this.$emit("refresh");
		},
		mapComprobaciones(comprobaciones){
			this.tareaMantenimiento = comprobaciones.map((tarea) => structuredClone({
				...this.defaultItemTareaMantenimiento,
				...tarea,
			}));
		},
		getComprobacionesServicios(){
			this.loading = true;
			axios
				.get(`/Mantenimientos/ComprobacionMantenimientoServicio/${this.idMantenimientoServicio}`)
				.then( (response) => {
					this.mapComprobaciones(response.data);
				})
				.catch(console.log)
				.finally( () => {
					this.loading = false;
				});
		},
		getComprobacionesMantenimientos() {
			this.loading = true;
			axios
				.get(
					`/Mantenimientos/TareaMantenimientoComprobaciones/${this.idMantenimientoTarea}`
				)
				.then((response) => {
					this.mapComprobaciones(response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				})
		},
		getComprobacionesInspecciones() {
			this.loading = true;
			axios
				.get("/Mantenimientos/MantenimientoInspeccionComprobaciones", {
					params: {
						idMantenimientoCalendarioInspeccion: this.idMantenimientoCalendarioInspeccion,
						idMantenimientoInspeccion: this.idMantenimientoInspeccion,
					},
				})
				.then((response) => {
					this.mapComprobaciones(response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				})
		},
		getComprobacionesCorrectivos() {
			this.loading = true;
			axios
				.get(`/Mantenimientos/ComprobacionMantenimientoCorrectivo/${this.idMantenimientoCorrectivo}`)
				.then((response) => {
					this.mapComprobaciones(response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				})
		},
	},
};
</script>
<style scoped>
.custom-radio-group >>> .v-messages {
	text-align: center;
}

.custom-radio-group >>> .v-label {
	color: rgba(0,0,0,.87);
}

.custom-radio-group >>> .v-input--radio-group__input {
	justify-content: center;
}

.centered-chips >>> .v-slide-group__content {
	justify-content: center;
}

.border-bottom-dashed {
	border-bottom: medium dashed rgba(0,0,0,.12) !important;
}

.comprobacion-item-rows-impar >>> tr {
	background-color: rgba(0, 0, 0, 0.05);
}

.comprobacion-item-rows-par >>> tr {
	background-color: transparent;
}

.comprobacion-item-rows-impar >>> tr:hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
}

.comprobacion-item-rows-par >>> tr:hover {
	background-color: transparent !important;
}

.comprobacion-table-rows-impar:hover {
	background-color: rgba(0, 0, 0, 0.05) !important;
}

.comprobacion-table-rows-par:hover {
	background-color: transparent !important;
}
</style>