<template>
	<v-container fluid v-if="permisoVista('mantenimiento-historial','r')">
		<v-row>
			<v-col cols="4" style="padding-top: 22px">
				<v-autocomplete
					v-model="filtros.tipo"
					:items="tipos"
					item-value="idTipo"
					item-text="nombre"
					label="Tipo"
					hide-details
					clearable
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4">
				<v-autocomplete
					multiple
					chips
					v-model="filtros.codigosNombres"
					:items="computedCodNom"
					:item-value="codNombres"
					:item-text="lblcodNom"
					label="Código o Nombre"
					hide-details
					clearable
					deletable-chips
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" style="padding-top: 22px">
				<v-autocomplete
					v-model="filtros.idMantenimientoCategoria"
					label="Categoría Mantenimiento"
					:items="categoriasMantenimiento"
					item-text="nombre"
					item-value="idMantenimientoCategoria"
					hide-details
					clearable
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.categoria"
					:items="categorias"
					item-value="idMaquinaCategoria"
					item-text="categoria"
					label="Categoría Máquina"
					hide-details
					clearable
					:disabled="loading"
					@change="changeCategoria"
				></v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.maquina"
					label="Modelo"
					:items="maquinasFiltradas"
					:item-text="marcaModelo"
					item-value="idMaquina"
					:menu-props="{
						maxWidth: 'min-content'
					}"
					hide-details
					clearable
					:disabled="loading"
					@change="changeMaquina"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
							<v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
							<v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<MaquinaInsAutocomplete
					v-model="filtros.instancia"
					:items="instanciasFiltradas"
					item-text="nombre"
					item-value="idMaquinaIns"
					label="Máquina"
					clearable
					hide-details
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="4" md="4" sm="4" class="mt-0" style="padding-top: 10px">
				<v-autocomplete
					v-model="filtros.responsable"
					:items="personas"
					:item-text="nombrePersona"
					item-value="idPersona"
					label="Responsable"
					clearable
					hide-details
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="4" style="padding-top: 10px">
				<v-select
					v-model="filtros.tipoAsignacion"
					label="Tipo de Asignación"
					:items="$utils.mantenimientos.tiposAsignacion"
					hide-details
					clearable
					:disabled="loading"
					@change="onChangeTipoAsignacion"
				></v-select>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="mt-0 pt-0">
				<v-autocomplete
					v-model="filtros.realizadoPor"
					:items="getItemsRealizadoPor"
					:item-text="getTextRealizadoPor"
					:item-value="getValueRealizadoPor"
					label="Realizado Por"
					clearable
					hide-details
					multiple
					chips
					deletable-chips
					:placeholder="filtros.tipoAsignacion == null ? 'Seleccione un tipo de asignación' : null"
					:no-data-text="filtros.tipoAsignacion == null ? 'Seleccione un tipo de asignación' : $vuetify.noDataText"
					:disabled="loading"
					@change="mantenimientos_update_page_action(1)"
				>
				</v-autocomplete>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					v-model="filtros.inicio"
					label="Desde"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="mantenimientos_update_page_action(1)"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-end">
				<VueCtkDateTimePicker
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="mantenimientos_update_page_action(1)"
				/>
			</v-col>
		</v-row>
		<v-dialog v-model="dialogDesglose" max-width="1300px" persistent>
			<v-card>
				<v-card-title> Detalles del Mantenimiento </v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<div class="text-subtitle-1">Costos de Salidas de Inventario</div>
							<v-data-table
								:items="movimientoCostos"
								:headers="headerDesgloseMovimientosFiltrados"
								:loading="loadingDesglose"
								loading-text="Cargando... Espere, Por Favor."
                				hide-default-footer
								:items-per-page="-1"
                				:footer-props="{
									disableItemsPerPage: true,
									disablePagination: true,
								}"
								:disable-sort="true"
							>
								<template v-slot:[`item.articulo`]="{ item }">
									[{{item.articuloCodigo}}] - {{ item.articulo }}
								</template>
								<template v-slot:[`item.cantidad`]="{ item }">
									{{ item.cantidadProporcional.toFixed(2) }} {{item.unidad}}
								</template>
								<template v-slot:[`item.costo`]="{ item }">
									$ {{ item.costoProporcional.toFixed(2) }}
								</template>
							</v-data-table>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<div class="text-subtitle-1">Comprobaciones</div>
							<ComprobacionesRegistros
								v-if="dialogDesglose"
								:comprobaciones="comprobaciones"
								:loading="loading"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<div class="text-subtitle-1">Recomendaciones</div>
							<v-textarea
                                auto-grow
                                rows="1"
                                hide-details
								readonly
								outlined
								:value="recomendacion || 'N/A'"
							></v-textarea>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="red"
						@click="cerrarDesglose"
					>
						Cerrar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<download-btn
			v-if="!seleccionable" 
			color="blue darken-1" 
			text 
			@click="descargarReporte"
			label="Descargar Reporte<br>de Mantenimientos Realizados"
		></download-btn>
		<v-data-table
			v-if="!seleccionable"
			:headers="headersFiltrados"
			:items="mantenimientos"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="mantenimientos_total"
			:items-per-page="mantenimientos_items_per_page"
			:page="mantenimientos_actual_page"
			@update:page="mantenimientos_update_page_action"
			@update:items-per-page="
				(ipp) => (mantenimientos_items_per_page = ipp)
			"
			@update:sort-by="mantenimientos_sort_by_action"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:[`item.maquina`]="{ item }">
				{{ item.maquina || 'N/A' }}
			</template>
			<template v-slot:[`item.maquinaIns`]="{ item }">
				{{ item.maquinaIns || 'N/A' }}
			</template>
			<template v-slot:[`item.tipoAsignacion`]="{ item }">
				{{ $utils.mantenimientos.getNombreTipoAsignacion(item.tipoAsignacion) }}
			</template>
			<template v-slot:[`item.realizadoPor`]="{ item }">
				<template v-if="item.tipoAsignacion == 1">
					<ul class="py-1">
						<li
							v-for="(mecanico, idx) in item.mecanicos"
							:key="'mec-' + idx"
							style="font-size: 14px !important"
							:class="{'pb-1': item.mecanicos.length > 1}"
						>
							{{ mecanico.nombre }} - ${{mecanico.costo.toFixed(2)}}
						</li>
					</ul>
				</template>
				<template v-else>
					{{ $utils.mantenimientos.findNombreProveedor(item.idMantenimientoProveedor,proveedores) }}
				</template>
			</template>
			<template v-slot:[`item.horasTrabajadas`]="{ item }">
				{{ item.horasTrabajadas == null ? "N/A" : $utils.strings.formatearCifra(item.horasTrabajadas) }}
			</template>
			<template v-slot:[`item.kilometraje`]="{ item }">
				{{ item.kilometraje == null ? "N/A" : $utils.strings.formatearCifra(item.kilometraje) }}
			</template>
			<template v-slot:[`item.costoTotal`]="{ item }">
				$ {{item.importeNeto == null ? item.costoTotal.toFixed(2) : (item.importeNeto + item.movimientoCosto).toFixed(2)}}
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" small @click="verDesglose(item)">
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Detalles</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-data-table
			v-model="seleccionados"
			v-if="seleccionable"
			show-select
			:single-select="false"
			item-key="idMantenimientoTarea"
			:headers="headersSeleccion"
			:items="mantenimientos"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:server-items-length="mantenimientos_total"
			:items-per-page="mantenimientos_items_per_page"
			:page="mantenimientos_actual_page"
			@update:page="mantenimientos_update_page_action"
			@update:items-per-page="
				(ipp) => (mantenimientos_items_per_page = ipp)
			"
			@update:sort-by="mantenimientos_sort_by_action"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:[`item.maquina`]="{ item }">
				{{ item.maquina || 'N/A' }}
			</template>
			<template v-slot:[`item.maquinaIns`]="{ item }">
				{{ item.maquinaIns || 'N/A' }}
			</template>
			<template v-slot:[`item.mecanicos`]="{ item }">
				<ul>
					<li
						v-for="(mecanico, idx) in item.mecanicos"
						:key="'mec-' + idx"
					>
						{{ mecanico.nombre }}
					</li>
				</ul>
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState } from "vuex";
import DownloadBtn from '../../DownloadBtn.vue';
import MaquinaInsAutocomplete from './MaquinaInsAutocomplete.vue';
import ComprobacionesRegistros from './ComprobacionesRegistros.vue';

export default {
	components: { DownloadBtn, MaquinaInsAutocomplete, ComprobacionesRegistros },
	props: {
		seleccionable: {
			type: Boolean,
			default: false,
		},
		paros:{
			type: Boolean,
			default: false,
		}
	},
	data: () => ({
		categoriasMantenimiento: [],
		proveedores: [],
		comprobaciones: [],
		headerComprobaciones: [
			{
				text: "Comprobación",
				align: "center",
				sort: false,
				value: "comprobacion",
			},
			{
				text: "Satisfactoria",
				align: "center",
				sort: false,
				value: "satisfactoria",
			},
			{
				text: "Comentario",
				align: "center",
				sort: false,
				value: "comentario",
			},
			{
				text: "Evidencias",
				align: "center",
				sort: false,
				value: "documentos",
			},
			{
				text: "Reparación",
				align: "center",
				sort: false,
				value: "reparacion",
			},
		],
    	dialogDesglose: false,
		loadingDesglose: false,
		recomendacion: "",
		facturaCostos: [],
		movimientoCostos:[],
		seleccionados: [],
		seleccionadosF: [],
		filtros: {
			tipo: null,
			codigosNombres: null,
			categoria: null,
			maquina: null,
			instancia: null,
			responsable: null,
			mecanicos: [],
			inicio: null,
			fin: null,
			tipoAsignacion: null,
			realizadoPor: [],
			idMantenimientoCategoria: null
		},
		tipos: [
			{
				idTipo: 1,
				nombre: "Preventivo",
			},
			{
				idTipo: 2,
				nombre: "Correctivo",
			},
			{
				idTipo: 3,
				nombre: "Servicio",
			},
		],
		codigosNombres: [],
		codigos: [],
		nombresM: [],
		categorias: [],
		maquinas: [],
		instancias: [],
		mantenimientos: [],
		personas: [],
		loading: false,
		mantenimientos_total: 0,
		mantenimientos_items_per_page: 10,
		mantenimientos_actual_page: 1,
		headers: [
			{
				text: "Tipo",
				align: "center",
				sort: false,
				value: "mantenimientoTipo",
				width: "150px",
			},
			{
				text: "Código",
				align: "center",
				sort: false,
				value: "mantenimientoCodigo",
				width: "150px",
			},
			{
				text: "Nombre",
				align: "center",
				sort: false,
				value: "mantenimiento",
				width: "150px",
			},
			{
				text: "Categoría Mantenimiento",
				align: "center",
				sortable: false,
				value: "categoriaMantenimiento",
				width: "150px",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "inicioRealizo",
				width: "150px",
			},
			{
				text: "Termino",
				align: "center",
				sort: false,
				value: "finRealizo",
				width: "150px",
			},
			{
				text: "Categoría Máquina",
				align: "center",
				sortable: false,
				value: "categoriaMaquina",
				width: "150px",
			},
			{
				text: "Modelo",
				align: "center",
				sort: false,
				value: "maquina",
				width: "150px",
			},
			{
				text: "Máquina",
				align: "center",
				sort: false,
				value: "maquinaIns",
				width: "150px",
			},
			{
				text: "Responsable",
				align: "left",
				sort: false,
				value: "responsable",
				width: "150px",
			},
			{
				text: "Tipo Asignación",
				align: "center",
				sort: false,
				value: "tipoAsignacion",
				width: "150px",
			},
			{
				text: "Realizado Por",
				align: "center",
				sort: false,
				value: "realizadoPor",
				width: "150px",
			},
			{
				text: "Costo Total",
				align: "center",
				sort: false,
				value: "costoTotal",
				width: "150px",
			},
			{
				text: "Acciones",
				align: "center",
				sort: false,
				value: "actions",
				width: "10%"
			},
		],
    	headersSeleccion: [
			{
				text: "Tipo",
				align: "center",
				sort: false,
				value: "mantenimientoTipo",
				width: "150px",
			},
			{
				text: "Código",
				align: "center",
				sort: false,
				value: "mantenimientoCodigo",
				width: "150px",
			},
			{
				text: "Nombre",
				align: "center",
				sort: false,
				value: "mantenimiento",
				width: "150px",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "inicioRealizo",
				width: "150px",
			},
			{
				text: "Termino",
				align: "center",
				sort: false,
				value: "finRealizo",
				width: "150px",
			},
			{
				text: "Máquina",
				align: "center",
				sort: false,
				value: "maquina",
				width: "15%",
			},
			{
				text: "Máquina Instancia",
				align: "center",
				sort: false,
				value: "maquinaIns",
				width: "150px",
			},
			{
				text: "Responsable",
				align: "left",
				sort: false,
				value: "responsable",
				width: "17%",
			},
			{
				text: "Realizado Por",
				align: "left",
				sort: false,
				value: "mecanicos",
				width: "17%",
			},
		],
		headerDesgloseFacturas: [
			{
				text: "Proveedor",
				align: "left",
				sort: false,
				value: "proveedor",
				width: "40%"
			},
      {
				text: "Costo Total Factura",
				align: "center",
				sort: false,
				value: "total",
				width: "20%"
			},
      {
				text: "Porcentaje Asignado",
				align: "center",
				sort: false,
				value: "porcentaje",
				width: "20%"
			},
			{
				text: "Costo",
				align: "center",
				sort: false,
				value: "costoProporcional",
				width: "20%"
			},
		],
		headerDesgloseMovimientos: [
			{
				text: "Artículo",
				align: "left",
				sort: false,
				value: "articulo",
				width: "50%"
			},
      {
				text: "Cantidad",
				align: "center",
				sort: false,
				value: "cantidad",
				width: "30%"
			},
      {
				text: "Costo",
				align: "center",
				sort: false,
				value: "costo",
				width: "20%"
			},
		],
	}),
	computed: {
		...mapState(["nombreEmpresa"]),
		instanciasFiltradas() {
			let instancias = this.instancias.slice();
			if(this.filtros.categoria != null){
				instancias = instancias.filter( (instancia) => instancia.idMaquinaCategoria == this.filtros.categoria );
			}
			if (this.$utils.isValid(this.filtros.maquina)) {
				instancias = instancias.filter((instancia) => instancia.idMaquina == this.filtros.maquina);
			}
			return instancias;
		},
		getValueRealizadoPor(){
			if(this.filtros.tipoAsignacion == 1) return 'idPersona';
			else if(this.filtros.tipoAsignacion == 2) return 'idMantenimientoProveedor';
			else return 'value';
		},
		getTextRealizadoPor(){
			if(this.filtros.tipoAsignacion == 1) return this.nombrePersona;
			else if(this.filtros.tipoAsignacion == 2) return this.$utils.mantenimientos.getNombreProveedor;
			else return 'text';
		},
		getItemsRealizadoPor(){
			if(this.filtros.tipoAsignacion == null) return [];
			if(this.filtros.tipoAsignacion == 1) return this.personas;
			if(this.filtros.tipoAsignacion == 2) return this.proveedores;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		headerDesgloseMovimientosFiltrados(){
			return this.permisoVista('mantenimiento-historial','$')
				? this.headerDesgloseMovimientos
				: this.headerDesgloseMovimientos.filter(head=>head.value!='costo')
		},
		headerDesgloseFacturasFiltrados(){
			return this.permisoVista('mantenimiento-historial','$')
				? this.headerDesgloseFacturas
				: this.headerDesgloseFacturas.filter(head=>head.value!='total'&&head.value!='costoProporcional')
		},
		headersFiltrados(){
			let finalHeaders = this.headers.slice();
			if(!this.permisoVista('mantenimiento-historial','$')){
				finalHeaders = finalHeaders.filter(head=>head.value!='costoTotal');
			}
			if(this.esIndasa){
				const indexInsert = finalHeaders.findIndex( header => header.value == 'realizadoPor' );
				finalHeaders.splice(indexInsert + 1, 0, {
					text: "Horas Trabajadas",
					align: "center",
					sort: false,
					value: "horasTrabajadas",
					width: "150px",
				},{
					text: "Kilometraje",
					align: "center",
					sort: false,
					value: "kilometraje",
					width: "150px",
				})
			}
			return finalHeaders;
		},
		computedCodNom() {
			if (this.filtros.tipo === 1)
				return this.codigosNombres.filter(
					(codnom) =>
						codnom.idMantenimientoPreventivo !== null && codnom
				);
			else if (this.filtros.tipo === 2)
				return this.codigosNombres.filter(
					(codnom) =>
						codnom.idMantenimientoCorrectivo !== null && codnom
				);
			else if (this.filtros.tipo == 3){
				return this.codigosNombres.filter( (codnom) => codnom.idMantenimientoServicio != null && codnom)
			}
			else return this.codigosNombres;
		},
		mantenimientos_filtros() {
			var url = "";
			if (this.$utils.isValid(this.filtros.codigosNombres)) {
				const idCorrectivos = this.filtros.codigosNombres.map(
					(item) => {
						if (item.idMantenimientoCorrectivo !== null)
							return item.idMantenimientoCorrectivo;
					}
				);
				const idPreventivos = this.filtros.codigosNombres.map(
					(item) => {
						if (item.idMantenimientoPreventivo !== null)
							return item.idMantenimientoPreventivo;
					}
				);
				const idServicios = this.filtros.codigosNombres.reduce( (acc, item) => {
					if(item.idMantenimientoServicio != null) acc.push(item.idMantenimientoServicio)
					return acc;
				}, []);

				if (
					idCorrectivos !== null &&
					idCorrectivos.length > 0 &&
					!idCorrectivos.includes(undefined)
				)
					idCorrectivos.forEach((element) => {
						url += "&IdsMantenimientosCorrectivos=" + element;
					});
				if (
					idPreventivos !== null &&
					idPreventivos.length > 0 &&
					!idPreventivos.includes(undefined)
				)
					idPreventivos.forEach((element) => {
						url += "&IdsMantenimientosPreventivos=" + element;
					});

				idServicios.forEach((element) => {
					url += "&IdsMantenimientosServicios=" + element;
				});
			}

			if (this.$utils.isValid(this.filtros.tipo)) {
				url += "&TipoMantenimiento=" + this.filtros.tipo;
			}

			if (this.$utils.isValid(this.filtros.idMantenimientoCategoria)) {
				url += "&idMantenimientoCategoria=" + this.filtros.idMantenimientoCategoria;
			}

			if (this.$utils.isValid(this.filtros.categoria)) {
				url += "&IdMaquinaCategoria=" + this.filtros.categoria;
			}

			if (this.$utils.isValid(this.filtros.maquina)) {
				url += "&idMaquina=" + this.filtros.maquina;
			}

			if (this.$utils.isValid(this.filtros.instancia)) {
				url += "&idMaquinaIns=" + this.filtros.instancia;
			}

			if (this.$utils.isValid(this.filtros.responsable)) {
				url += "&idResponsable=" + this.filtros.responsable;
			}
			
			if (this.$utils.isValid(this.filtros.tipoAsignacion)) {
				url += "&tipoAsignacion=" + this.filtros.tipoAsignacion;
			}

			if (
				this.$utils.isValid(this.filtros.realizadoPor) &&
				this.filtros.realizadoPor.length > 0
			) {
				if(this.filtros.tipoAsignacion == 1){
					this.filtros.realizadoPor.forEach(
						(x) => (url += `&idsMecanicos=${x || 0}`)
					);
				}
				else {
					this.filtros.realizadoPor.forEach(
						(x) => (url += `&idsProveedoresMantenimiento=${x || 0}`)
					);
				}
			}

			if (this.$utils.isValid(this.filtros.inicio)) {
				url += "&inicio=" + this.filtros.inicio;
			}

			if (this.$utils.isValid(this.filtros.fin)) {
				url += "&fin=" + this.filtros.fin;
			}

			return url;
		},
		mantenimientos_fetch_url() {
			let url = `/Mantenimientos/MantenimientosRealizados?Paginar=true&page=${this.mantenimientos_actual_page}&size=${this.mantenimientos_items_per_page}`;
			if(this.paros) url += "&filtrarUsadoParos=true";
			return url + this.mantenimientos_filtros;
		},
		maquinasFiltradas() {
			if (this.$utils.isValid(this.filtros.categoria)) {
				if (this.filtros.categoria == 0) {
					return this.instancias.filter((instancia) => {
						return instancia.idMaquinaCategoria == null;
					});
				} else {
					return this.instancias.filter((instancia) => {
						return (
							instancia.idMaquinaCategoria ==
							this.filtros.categoria
						);
					});
				}
			} else return this.instancias;
		}
	},
	mounted() {
		this.initialize();
	},
	methods: {
		lblcodNom: (item) => `[${item.codigo}] - ${item.nombre}`,
		codNombres: (elemento) => elemento,
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		nombrePersona: (persona) =>
			persona.nombre + " " + persona.paterno + " " + persona.materno,
		onChangeTipoAsignacion(){
			this.filtros.realizadoPor = [];
			this.mantenimientos_update_page_action(1);
		},
		cerrarDesglose(){
			this.dialogDesglose = false;
			this.facturaCostos = [];
			this.movimientoCostos = [];
			this.comprobaciones = [];
			this.recomendacion = "";
		},
		verDesglose(item) {
			this.loadingDesglose = true;
			this.dialogDesglose = true;
			this.facturaCostos = item.facturaCostos;
			this.movimientoCostos = item.movimientoCostos;
			this.comprobaciones = item.comprobaciones;
			this.recomendacion = item.recomendacion;
      		this.loadingDesglose = false;
		},
		clickSeleccionados() {
			this.seleccionadosF = this.seleccionados;
			return Object.assign([], this.seleccionadosF);
		},
		resetSeleccionados() {
			this.seleccionados = this.seleccionadosF;
		},
		fullresetSeleccionados() {
			this.seleccionados = [];
		},
		initialize() {
			this.mantenimientos_update_page_action(1);
			this.getMaquinas();
			this.getInstancias();
			this.getPersonas();
			this.getCodigosNombres();
			this.getProveedoresMantenimiento();
			this.getCategoriasMantenimiento();
		},
		getCategoriasMantenimiento(){
			axios
				.get('/MantenimientoCategorias')
				.then( (response) => {
					this.categoriasMantenimiento = response.data;
				})
				.catch( (error) => {
					this.categoriasMantenimiento = [];
					console.log(error);
				});
		},
		getProveedoresMantenimiento(){
			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch( (error) => {
					this.proveedores = [];
					console.log(error);
				});
		},
		changeCategoria() {
			this.filtros.maquina = null;
			this.filtros.instancia = null;
			this.mantenimientos_update_page_action(1);
		},
		changeMaquina() {
			this.filtros.instancia = null;
			this.mantenimientos_update_page_action(1);
		},
		getCodigosNombres() {
			axios
				.get("/Mantenimientos/GetMantenimientosPreventivosCorrectivos")
				.then((response) => {
					this.codigosNombres = response.data;
				})
				.catch((error) => console.log(error));
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((el) =>  el.esMaquina);
					this.categorias = this.maquinas.map((elemento) => ({
						idMaquinaCategoria:
							elemento.idMaquinaCategoria == null
								? 0
								: elemento.idMaquinaCategoria,
						categoria: elemento.categoria,
					}));
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getPersonas() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		mantenimientos_sort_by_action($sort) {},
		async mantenimientos_update_page_action(page) {
			this.mantenimientos_actual_page = page;
			this.loading = true;
			try {
				let response = await this.getMantenimientos();
				this.mantenimientos = response.data.result;
				this.mantenimientos_total = response.data.total;
				this.loading = false;
			} catch (exception) {
				console.log(exception);
			}
		},
		getMantenimientos() {
			//* Realizamos peticion HTTP
			return new Promise((resolve, reject) => {
				axios
					.get(this.mantenimientos_fetch_url)
					.then((response) => resolve(response))
					.catch((error) => reject(error));
			});
		},
		descargarReporte() {
			var datetime = moment().format("YYYYMMDDHHmmss");
			var url = this.mantenimientos_filtros;
			if (url.length > 0 && url[0] == "&") url = "?" + url.substring(1);
			this.$utils.axios.downloadFile(
				"/Mantenimientos/ReporteXLSXMantenimientosRealizados" + url,
				"GET",
				"historial-mantenimientos-" + datetime + ".xlsx",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			);
		},
	},
};
</script>