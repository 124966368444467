<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-historial','r')">
		<v-dialog v-model="dialogTerminar" persistent max-width="60%">
			<v-card>
				<v-card-title>Terminar {{ editedItem.tipo == 4 ? 'Servicio' : 'Mantenimiento' }}</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...editedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<template v-if="esIndasa">
							<v-col :cols="computeColImporteNeto">
								<v-text-field
									v-model="editedItem.importeNeto"
									label="Importe Neto"
									type="number"
									hide-spin-buttons
									prefix="$"
									clearable
									:error-messages="erroresItem.errorImporteNeto"
									@focus="erroresItem.errorImporteNeto = null"
								></v-text-field>
							</v-col>
							<template v-if="!esServicio">
								<v-col
									v-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'transporte',editedItem.idMaquina)"
									cols="6"
								>
									<KilometrajeTextfield
										v-if="dialogTerminar"
										v-model="editedItem.kilometraje"
										:kilometrajeMin.sync="editedItem.kilometrajeMin"
										:idMaquinaInstancia="editedItem.idMaquinaInstancia"
										:error-messages.sync="erroresItem.errorKilometraje"
										:loadingKilometrajeMin.sync="loadingKilometrajeMin"
										:disabled="loadingFinish"
									/>
								</v-col>
								<v-col v-else-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'montacargas',editedItem.idMaquina)" cols="6">
									<v-text-field
										v-model="editedItem.horasTrabajadas"
										label="Horas Trabajadas"
										type="number"
										hide-spin-buttons
										clearable
										:error-messages="erroresItem.errorHorasTrabajadas"
										@focus="erroresItem.errorHorasTrabajadas = null"
									></v-text-field>
								</v-col>
							</template>
						</template>
					</v-row>
					<Comprobaciones
						v-if="dialogTerminar"
						ref="checklist"
						class="mb-4"
						:disabled="loadingFinish"
						:idMantenimientoServicio="editedItem.idMantenimientoServicio"
						:idMantenimientoTarea="editedItem.idMantenimientoTarea"
						:idMaquina="editedItem.idMaquina"
						:tipoReporte="editedItem.tipo"
						:loadingComprobaciones.sync="loadingComprobaciones"
					/>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete-textarea
								v-if="dialogTerminar"
                                v-model="editedItem.recomendacion"
                                label="Descripción o Recomendación"
                                outlined
                                auto-grow
                                rows="1"
								:items="recomendaciones"
								:loading="loadingRecomendaciones"
								:disabled="loadingFinish"
								:error-message="erroresItem.errorRecomendacion"
								@click:refresh="getRecomendaciones"
								@update:error-message="erroresItem.errorRecomendacion = $event"
                            ></v-autocomplete-textarea>
						</v-col>
					</v-row>
					<ul v-if="errores.length > 0" class="my-3">
						<li class="red--text" v-for="ex,idx in errores" :key="`error-${idx}`">
							{{ ex }}
						</li>
					</ul>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="loadingFinish || loadingComprobaciones || loadingKilometrajeMin"
						small
						@click="cerrarDialogTerminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						small
						:disabled="loadingFinish || loadingComprobaciones || loadingKilometrajeMin"
						:loading="loadingFinish"
						@click="terminarTarea"
						>Terminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headers"
			:items="mantenimientos"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:[`item.tipoAsignacion`]="{ item }">
				{{ $utils.mantenimientos.getNombreTipoAsignacion(item.tipoAsignacion) }}
			</template>
			<template v-slot:[`item.realizadoPor`]="{ item }">
				<template v-if="item.tipoAsignacion == 1">
					<ul>
						<li
							v-for="(mecanico, idx) in item.mecanicos"
							:key="'mec-' + idx"
						>
							{{ mecanico }}
						</li>
					</ul>
				</template>
				<template v-else>
					{{ $utils.mantenimientos.findNombreProveedor(item.idMantenimientoProveedor,proveedores) }}
				</template>
			</template>
			<template v-slot:[`item.maquina`]="{ item }">
				{{ (item.marca == null && item.modelo == null) ? 'N/A' : `${item.marca} - ${item.modelo}` }}
			</template>
			<template v-slot:[`item.categoriaMaquina`]="{ item }">
				{{ $utils.mantenimientos.getMaquinaCategoria(maquinas,item.idMaquina) }}
			</template>
			<template v-slot:[`item.clave`]="{ item }">
				{{ item.clave || 'N/A' }}
			</template>
			<template v-slot:[`item.inicioCronometro`]="{ item }">
				<chronometer :time="item.inicioCronometro"></chronometer>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					bottom
					color="black"
					class="white--text"
					v-if="permisoVista('mantenimiento-historial','u')"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							@click="terminarMantenimiento(item)"
						>
							stop
						</v-icon>
					</template>
					<span class="white--text">Terminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import chronometer from "@/components/Chronometer.vue";
import Comprobaciones from '../Comprobaciones.vue';
import VAutocompleteTextarea from '../../../VAutocompleteTextarea.vue';
import { mapState } from 'vuex';
import HeaderInfoMantenimiento from '../Catalogos/HeaderInfoMantenimiento.vue';
import KilometrajeTextfield from '../KilometrajeTextfield.vue';

export default {
	components: {
		chronometer,
		Comprobaciones,
		VAutocompleteTextarea,
		HeaderInfoMantenimiento,
		KilometrajeTextfield,
	},
	data() {
		return {
			loadingKilometrajeMin: false,
			loadingComprobaciones: false,
			maquinas: [],
			editedItem: null,
			defaultEditedItem: {
				tipo: null,
				nombre: null,
				codigo: null,
				nombreCategoria: null,
				recomendacion: null,
				importeNeto: null,
				idMaquina: null,
				kilometraje: null,
				horasTrabajadas: null,
				idProveedor: null,
			},
			proveedores: [],
			erroresItem: {
				errorRecomendacion: null,
				errorImporteNeto: null,
				errorKilometraje: null,
				errorHorasTrabajadas: null,
			},
			loadingRecomendaciones: false,
			recomendaciones: [],
			loading: false,
			errores: [],
			mantenimientos: [],
			headers: [
				{
					text: "Tipo",
					align: "center",
					sort: false,
					value: "tipoMantenimientoNombre",
				},
				{
					text: "Clave",
					align: "center",
					sort: false,
					value: "mantenimientoClave",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "mantenimientoNombre",
				},
				{
					text: "Categoria Mantenimiento",
					align: "center",
					sort: false,
					value: "categoriaMantenimiento",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Tipo Asignación",
					align: "center",
					sort: false,
					value: "tipoAsignacion",
				},
				{
					text: "Realizado Por",
					align: "center",
					sort: false,
					value: "realizadoPor",
				},
				{
					text: "Categoría Máquina",
					align: "center",
					sort: false,
					value: "categoriaMaquina",
				},
				{
					text: "Modelo",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "clave",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
				},
				{
					text: "Tiempo Transcurrido",
					align: "center",
					sort: false,
					value: "inicioCronometro",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
			dialogTerminar: false,
			loadingFinish: false,
		};
	},
	computed: {
		...mapState(['nombreEmpresa']),
		computeColImporteNeto(){
			return this.esServicio || this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'basculas',this.editedItem.idMaquina)
				? 12
				: 6;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		esServicio(){
			return this.editedItem?.idMantenimientoServicio != null || false;
		}
	},
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	methods: {
		nombreMaquina: ({ nombre, marca, modelo }) => {
			let machine = "";
			machine += nombre ? `[${nombre}] - ` : "";
			machine += marca ? `${marca} ` : "";
			machine += modelo ? `${modelo}` : "";
			return machine.trim();
		},
		resetErrores(){
			this.errores = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
		cerrarDialogTerminar(){
			this.dialogTerminar = false;
			setTimeout(() => {
				this.recomendacion = null;
				this.resetErrores();
			}, 250);
		},
		validar(){
			this.resetErrores();

			if(this.esIndasa){
				if(this.editedItem.importeNeto != null){
					const parsedImporteNeto = parseFloat(this.editedItem.importeNeto);
					if(isNaN(parsedImporteNeto)) this.erroresItem.errorImporteNeto = "Invalido";
					else if (parsedImporteNeto < 0) this.erroresItem.errorImporteNeto = "Debe ser mayor o igual a 0";
				}
	
				if (!this.esServicio) {
					if (this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.editedItem.idMaquina)) {
						this.erroresItem.errorKilometraje = this.$utils.mantenimientos.validateKilometraje(this.editedItem);
					}
		
					if(this.editedItem.horasTrabajadas != null){
						const parsedHorasTrabajadas = parseFloat(this.editedItem.horasTrabajadas);
						if(isNaN(parsedHorasTrabajadas)) this.erroresItem.errorHorasTrabajadas = "Invalido";
						else if (parsedHorasTrabajadas < 0) this.erroresItem.errorHorasTrabajadas = "Debe ser mayor o igual a 0";
					}
				}

				if(!this.editedItem.recomendacion) this.erroresItem.errorRecomendacion = "Requerido"
			}

			const comprobacionesValidas = this.$refs.checklist.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.errores = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		terminarTarea(){
			if ( !this.validar() ) return;
			const item = this.$refs.checklist.terminarTarea();
			this.loadingFinish = true;
			axios
				.put("/Mantenimientos/TerminarTareaMantenimiento", {
					idMantenimientoTarea: item.idMantenimientoTarea,
					recomendacion: this.editedItem.recomendacion,
					comprobaciones: item.comprobaciones,
					importeNeto: parseFloat(this.editedItem.importeNeto) || null,
					kilometraje: parseFloat(this.editedItem.kilometraje) || null,
					horasTrabajadas: parseFloat(this.editedItem.horasTrabajadas) || null,
				})
				.then(() => {
					this.loadingFinish = false;
					this.cerrarDialogTerminar();
					this.initialize();
				})
				.catch((error) => {
					this.loadingFinish = false;
					console.log(error);
				});

		},
		initialize() {
			this.getMantenimientos();
			this.getRecomendaciones();
			this.getProveedoresMantenimiento();
			this.getMaquinas();
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((maquina) => maquina.esMaquina);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getProveedoresMantenimiento(){
			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch( (error) => {
					this.proveedores = [];
					console.log(error);
				});
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		getMantenimientos() {
			this.loading = true;
			axios
				.get("/Mantenimientos/ListaTareaMantenimientoEnCurso")
				.then((response) => {
					this.mantenimientos = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				});
		},
		terminarMantenimiento(item) {
			this.editedItem = structuredClone(item);
			this.editedItem.nombre = item.mantenimientoNombre;
			this.editedItem.codigo = item.mantenimientoClave;
			this.editedItem.nombreCategoria = item.categoriaMantenimiento;
			this.editedItem.maquinaIns = item.clave;
			this.dialogTerminar = true;
		},
	},
};
</script>