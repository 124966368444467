<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="1200px" persistent>
      <v-card>
        <v-card-title style="word-break: normal">
          {{ editedItem.idMolde == null ? 'Crear' : 'Editar' }} Molde
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.codigo"
                  label="Código de Molde"
                  :disabled="saving"
                  :error-messages="editedItem.errorCodigo"
                  @focus="$set(editedItem,'errorCodigo',null)"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="pt-4">
                <time-picker
                  v-model="editedItem.tiempoCambioSeg"
                  :disabled="saving || readOnly"
                  label="Tiempo Cambio de Molde (HH:MM:SS)"
                ></time-picker>
              </v-col>
              <v-col cols="4" class="text-center">
                <p class="mb-1"># de Cavidades</p>
                <number-input
                  v-model="editedItem.numCavidades"
                  controls
                  inline
                  size="small"
                  :min="0"
                  center
                  :disabled="saving || readOnly"
                  @change="agregarCavidad()"
                ></number-input>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.idMaquina"
                  label="Máquina"
                  :items="maquinas"
                  item-value="idMaquina"
                  :item-text="nombreMaquina"
                  :disabled="saving || readOnly"
                  :error-messages="editedItem.errorIdMaquina"
                  @focus="editedItem.errorIdMaquina = null"
                  @change="onChangeMaquina"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-file-input
                  v-model="editedItem.imagen"
                  accept="image/png, image/jpeg, image/jpg"
                  :disabled="saving || readOnly"
                  truncate-length="40"
                  label="Imagen (Opcional)"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <h3 class="mt-0 text-center">Cavidades</h3>
                <v-data-table
                  :headers="cavidadesHeaders"
                  :items="editedItem.cavidades"
                  :items-per-page="-1"
                  hide-default-footer
                  no-data-text="Agregue al menos una cavidad"
                >
                  <template v-slot:[`item.clave`]="{ item }">
                    <v-text-field
                      v-model="item.clave"
                      label="Código"
                      single-line
                      :disabled="saving"
                      :error-messages="item.errorClave"
                      @focus="$set(item,'errorClave',null)"
                    ></v-text-field>
                  </template>
                  <template v-slot:[`item.idArticulo`]="{ item }">
                    <v-autocomplete
                      v-model="item.idArticulo"
                      :items="articulos"
                      item-value="idArticulo"
                      :item-text="nombreArticulo"
                      label="Articulo"
                      single-line
                      :loading="loadingArticulos"
                      :disabled="saving || readOnly"
                      :error-messages="item.errorIdArticulo"
                      @focus="$set(item,'errorIdArticulo',null)"
                      @change="onChangeArticulo()"
                    ></v-autocomplete>
                  </template>
                  <template v-slot:[`item.numDisparos`]="{ item }">
                    <template v-if="!readOnly">
                      <v-text-field
                        v-model="item.numDisparos"
                        label="# total de disparos"
                        type="number"
                        hide-spin-buttons
                        single-line
                        :disabled="saving"
                        :error-messages="item.errorNumDisparos"
                        @focus="$set(item,'errorNumDisparos',null)"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{item.numDisparos}}
                    </template>
                  </template>
                  <template v-slot:[`item.numDisparosUltimoMant`]="{ item }">
                    <template v-if="!readOnly">
                      <v-text-field
                        v-model="item.numDisparosUltimoMant"
                        label="# disparos ultimo mantenimiento"
                        hide-spin-buttons
                        type="number"
                        single-line
                        :disabled="saving"
                        :error-messages="item.errorNumDisparosUltimoMant"
                        @focus="$set(item,'errorNumDisparosUltimoMant',null)"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      {{item.numDisparosUltimoMant}}
                    </template>
                  </template>
                  <template v-slot:[`item.activo`]="{ item }">
                    <v-switch :disabled="saving || readOnly" dense v-model="item.activo"></v-switch>
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-slide-y-transition>
                  <p class="mb-0 text-right error--text" v-if="errorCavidades != null">
                    {{ errorCavidades}}
                  </p>
                </v-slide-y-transition>
              </v-col>
              <v-col cols="12">
                <h3 class="mt-0 text-center">Pasos</h3>
                <v-data-table
                  :items="editedItem.pasos"
                  :headers="headersPasos"
                  :items-per-page="-1"
                  hide-default-footer
                  no-data-text="Seleccione al menos un artículo"
                >
                  <template v-slot:[`item.idArticulo`]="{ item }">
                    {{ getArticuloById(item.idArticulo) }}
                  </template>
                  <template v-slot:[`item.idsPasos`]="{ item }">
                    <v-autocomplete
                      v-model="item.idsPasos"
                      :label="editedItem.idMaquina == null ? 'Seleccione primero una máquina' : 'Pasos'"
                      :items="item.recetas"
                      item-value="idPaso"
                      :item-text="nombrePaso"
                      single-line
                      chips
                      multiple
                      deletable-chips
                      :loading="item.loadingRecetas"
                      :disabled="item.loadingRecetas || editedItem.idMaquina == null || saving"
                    ></v-autocomplete>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="saving" color="success" text @click="close">
            Cancelar
          </v-btn>
          <v-btn :loading="saving" :disabled="saving" color="error" text @click="save">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline" style="word-break: normal"
          >¿Está seguro que desea eliminar este molde?</v-card-title
        >
        <v-card-text
          >Esta acción no se podrá revertir y será permanente.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="deleting" color="success" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn :disabled="deleting" :loading="deleting" color="error" text @click="deleteItemConfirm"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-btn color="primary" dark class="mb-2" @click="showDialog()">
          Nuevo Molde
        </v-btn>
      </v-col>
      <v-col cols="4"
        ><v-autocomplete
          v-model="parametros.idArticulo"
          :items="articulos"
          item-value="idArticulo"
          :item-text="nombreArticulo"
          :loading="loadingArticulos"
          label="Articulo"
          @change="getMoldes"
          clearable
        ></v-autocomplete
      ></v-col>
      <v-col cols="4"
        ><v-autocomplete
          v-model="parametros.idMaquina"
          label="Máquina"
          :items="maquinas"
          item-value="idMaquina"
          :item-text="nombreMaquina"
          @change="getMoldes"
          clearable
        ></v-autocomplete
      ></v-col>
      <v-col cols="4">
        <v-text-field
          v-model="parametros.codigo"
          label="Código de Molde"
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="moldes"
          :loading="loadingData"
          :search="parametros.codigo"
        >
          <template v-slot:[`item.imagen`]="{ item }">
            <template v-if="item.imagen != null">
              <Thumbnail
                :lazy="false"
                :lazyUrl="''"
                :url="item.imagen"
              ></Thumbnail>
            </template>
            <template v-else>N/A</template>
          </template>
          <template v-slot:[`item.articulos`]="{ item }">
            <template v-if="item.articulos.length > 0">
              <p class="my-1" v-for="articulo,index in item.articulos" :key="`articulo-${index}`">
                {{ item.articulos.length > 1 ? '•' : '' }} {{ articulo }}
              </p>
            </template>
            <template v-else>N/A</template>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="showDialog(item)"
								>
									mdi-pencil
								</v-icon>
							</template>
							<span class="white--text">Editar</span>
						</v-tooltip>
            <v-tooltip bottom color="black" class="white--text">
							<template v-slot:activator="{ on }">
								<v-icon
									v-on="on"
									small
									class="mr-2"
									@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>
							</template>
							<span class="white--text">Eliminar</span>
						</v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Recargar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Thumbnail from "../Produccion/ThumbnailMoldes.vue";
export default {
  components: {
    Thumbnail,
  },
  data(){
    return {
      deleting: false,
      saving: false,
      dialog: false,
      dialogDelete: false,
      areas: [],
      articulos: [],
      maquinas: [],
      recetas: [],
      headersPasos: [
        {
          text: "Artículo",
          align: "center",
          sortable: false,
          value: "idArticulo",
          width: "50%",
        },
        {
          text: "Pasos",
          align: "center",
          sortable: false,
          value: "idsPasos",
          width: "50%",
        }
      ],
      headers: [
        {
          text: "Imagen",
          align: "center",
          sortable: false,
          value: "imagen",
        },
        { text: "Código del Molde", value: "codigo", align: 'center' },
        { text: "Máquina", value: "maquina", align: 'center' },
        { text: "Cavidades", value: "articulos", align: "center", width: "40%" },
        { text: "# Disparos Totales", value: "numDisparos", align: 'center' },
        { text: "# Disparos Ultimo Mantenimiento ", value: "numDisparosUltimoMant", align: 'center' },
        { text: "Acciones", value: "actions", align: "center", sortable: false },
      ],
      cavidadesHeaders: [
        {
          text: "Código de la Cavidad",
          align: "center",
          sortable: false,
          value: "clave",
        },
        {
          text: "Artículo",
          align: "center",
          sortable: false,
          value: "idArticulo",
          width: "40%"
        },
        {
          text: "# Total de Disparos",
          align: "center",
          sortable: false,
          value: "numDisparos",
        },
        {
          text: "# Disparos desde Ultimo Mantenimiento",
          align: "center",
          sortable: false,
          value: "numDisparosUltimoMant",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "activo",
        },
      ],
      headersRecetaArea: [
        {
          text: "Paso",
          align: "center",
          sortable: false,
          value: "Area",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
        },
      ],
      moldes: [],
      editedItem: {
        idMolde: null,
        codigo: null,
        numCavidades: 0,
        idMaquina: null,
        imagen: null,
        cavidades: [],
        pasos: [],
        cavidadesActivas: null,
        tiempoCambioSeg: 0,
        numDisparos: null,
        numDisparosUltimoMant: null,
        fechaUltimoMant: null,
        errorCodigo: null,
        errorNumCavidades: null,
        errorIdArticulo: null,
        errorIdMaquina: null,
      },
      defaultEditedItem: {
        idMolde: null,
        codigo: null,
        numCavidades: 0,
        idMaquina: null,
        imagen: null,
        cavidades: [],
        pasos: [],
        cavidadesActivas: null,
        tiempoCambioSeg: 0,
        numDisparos: null,
        numDisparosUltimoMant: null,
        fechaUltimoMant: null,
        errorCodigo: null,
        errorNumCavidades: null,
        errorIdArticulo: null,
        errorIdMaquina: null,
      },
      itemsRectArea: [],
      errorCavidades: null,
      cavidadItem: {
        idArticulo: null,
        idMoldeCavidad: null,
        clave: null,
        numDisparos: null,
        numDisparosUltimoMant: null,
        activo: true,
        errorClave: null,
        errorNumDisparos: null,
        errorNumDisparosUltimoMant: null,
      },
      defaultRectAre: {
        id: null,
        idPaso: null,
        Area: null,
      },
      loadingAreas: false,
      loadingArticulos: false,
      loadingMaquinas: false,
      loadingRecetas: false,
      loadingData: false,
      parametros: {
        idArticulo: null,
        idMaquina: null,
        codigo: null,
      },
    }
  },
  computed: {
    readOnly(){
      return this.editedItem.idMolde != null;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    nombrePaso: (paso) => `${paso.receta} - ${paso.area}`,
    onChangeMaquina(){
      this.getPasos(true);
    },
    getArticuloById(idArticulo){
      const articulo = this.articulos.find( (articulo) => articulo.idArticulo == idArticulo );
      return this.nombreArticulo(articulo);
    },
    onChangeArticulo(){
      this.procesarPasos();
    },
    getPasos(hardRefresh = false){
      if(this.editedItem.idMaquina == null) return;
      
      this.editedItem.pasos.forEach( (paso) => {
        if(hardRefresh){
          this.$set(paso,'idsPasos',[]);
          this.getRecetasAreas(paso);
        }
        else {
          if(paso.recetas == undefined || paso.recetas?.length == 0)
            this.getRecetasAreas(paso);
        }
      });
    },
    procesarPasos(){
      const idsArticulo = this.editedItem.cavidades.reduce( (acc,cavidad) => {
        if(cavidad.idArticulo != null) acc.push(cavidad.idArticulo);
        return acc;
      },[]);

      const idsArticulosUnicos = [ ...new Set( idsArticulo ) ];
      const idsArticulosPasos = this.editedItem.pasos.map( (paso) => paso.idArticulo );

      const newPasos = idsArticulosUnicos.reduce( (acc,idArticulo) => {
        if(idsArticulosPasos.includes(idArticulo)){
          const paso = this.editedItem.pasos.find( (paso) => paso.idArticulo == idArticulo );
          acc.push(paso);
        }
        else {
          acc.push({
            idArticulo,
            idsPasos: [],
            recetas: []
          })
        }
        return acc;
      },[]);

      this.editedItem.pasos = newPasos;
      this.getPasos();
    },
    initialize() {
      this.getMoldes();
      this.getArticulos();
      this.getMaquinasIns();
    },
    showDialog( item = null ){
      this.editedItem = structuredClone(item || this.defaultEditedItem);
      if(item){
        if(item.imagen != null) this.editedItem.imagen = new File([1],item.imagen);
        this.procesarPasos();
      }
      this.dialog = true;
    },
    nombreArticulo: (value) =>
      value.codigo != null
        ? `[${value.codigo}] - ${value.nombre}`
        : `${value.nombre}`,
    nombreMaquina: (maquina) =>
      `${maquina.marca} ${maquina.modelo}`,
    agregarCavidad() {
      if (this.editedItem.numCavidades > this.editedItem.cavidades.length) {
        const diferencia = this.editedItem.numCavidades - this.editedItem.cavidades.length;
        this.editedItem.cavidades.push( ...Array(diferencia).fill( structuredClone(this.cavidadItem) ) );
      } 
      else if (this.editedItem.numCavidades < this.editedItem.cavidades.length) {
        const cantidadBorrar = this.editedItem.cavidades.length - this.editedItem.numCavidades;
        this.editedItem.cavidades.splice(this.editedItem.cavidades.length - 1,cantidadBorrar);
        this.procesarPasos();
      }
    },
    deleteItem(item) {
      this.editedItem = structuredClone(item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.deleting = true;
      axios
        .put(`/Moldes/Desactivar/${this.editedItem.idMolde}`)
        .then(() => {
          this.deleting = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          this.deleting = false;
          console.log(error);
        });
    },

    close() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editedItem = structuredClone(this.defaultEditedItem);
        this.errorCavidades = null;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = structuredClone(this.defaultEditedItem);
        this.errorCavidades = null;
      });
    },
    validate() {
      let error = false;
      let hayErroresCavidades = false;

      if (!this.editedItem.codigo){
        this.editedItem.errorCodigo = "Requerido";
        error = true;
      }
      else this.editedItem.errorCodigo = null;

      if (this.editedItem.numCavidades == null){
        this.editedItem.errorNumCavidades = "Requerido";
        error = true;
      }
      else this.editedItem.errorNumCavidades = null;

      if (this.editedItem.idMaquina == null){
        this.editedItem.errorIdMaquina = "Requerido";
        error = true;
      }
      else this.editedItem.errorIdMaquina = null;

      if (this.editedItem.cavidades.length == 0){
        this.errorCavidades = "Ingrese al menos una cavidad";
        error = true;
      }
      else {
        this.editedItem.cavidades.forEach((cavidad) => {
          if (!cavidad.clave){
            cavidad.errorClave = "Requerido";
            hayErroresCavidades = true;
          }
          else cavidad.errorClave = null;

          if(cavidad.idArticulo == null){
            cavidad.errorIdArticulo = "Requerido";
            hayErroresCavidades = true;
          }
          else cavidad.errorIdArticulo = null;

          if (isNaN(parseFloat(cavidad.numDisparos))){
            cavidad.errorNumDisparos = "Requerido";
            hayErroresCavidades = true;
          }
          else if(parseFloat(cavidad.numDisparos) < 0){
            cavidad.errorNumDisparos = "Invalido";
            hayErroresCavidades = true;
          }
          else {
            cavidad.errorNumDisparos = null;
          }

          if (isNaN(parseFloat(cavidad.numDisparosUltimoMant))){
            cavidad.errorNumDisparosUltimoMant = "Requerido";
            hayErroresCavidades = true;
          }
          else if(parseFloat(cavidad.numDisparosUltimoMant) < 0){
            cavidad.errorNumDisparosUltimoMant = "Invalido";
            hayErroresCavidades = true;
          }
          else {
            cavidad.errorNumDisparosUltimoMant = null;
          }

        });

        if (hayErroresCavidades){
          this.errorCavidades = "Una o más cavidades tienen errores";
          setTimeout(() => {
            this.errorCavidades = null;
          }, 3000);
        }
        else this.errorCavidades = null;
      }

      return !error && !hayErroresCavidades;
    },
    save() {
      if (!this.validate()) return;

      this.saving = true;
      let payload = null;

      if(this.editedItem.idMolde == null){
        payload = new FormData();
  
        payload.append("codigo", this.editedItem.codigo);
        payload.append("numCavidades", this.editedItem.numCavidades);
        payload.append("idMaquina", this.editedItem.idMaquina);
        payload.append("tiempoCambioSeg", this.editedItem.tiempoCambioSeg);
        payload.append("imagen", this.editedItem.imagen);
  
  
        this.editedItem.cavidades.forEach((cavidad, innerIndex) => {
          payload.append(
            `cavidades[${innerIndex}].idMoldeCavidad`,
            cavidad.idMoldeCavidad
          );
          payload.append(`cavidades[${innerIndex}].idArticulo`,cavidad.idArticulo);
          payload.append(`cavidades[${innerIndex}].clave`, cavidad.clave);
          payload.append(
            `cavidades[${innerIndex}].numDisparosIniciales`,
            cavidad.numDisparos
          );
          payload.append(
            `cavidades[${innerIndex}].numDisparosUltimoMantenimiento`,
            cavidad.numDisparosUltimoMant
          );
          payload.append(`cavidades[${innerIndex}].activo`, cavidad.activo);
        });
  
        const pasosValidos = this.editedItem.pasos.filter( (paso) => paso.idsPasos.length > 0 );
        
        pasosValidos.forEach( (paso,indexPaso) => {
          payload.append(`pasos[${indexPaso}].idArticulo`,paso.idArticulo);
          paso.idsPasos.forEach( (idPaso,indexIdPaso) => {
            payload.append(`pasos[${indexPaso}].idsPasos[${indexIdPaso}]`,idPaso);
          });
        });
      }
      else {
        payload = {
          codigo: this.editedItem.codigo,
          pasos: this.editedItem.pasos.map( (paso) => ({
            idArticulo: paso.idArticulo,
            idsPasos: paso.idsPasos
          })),
          cavidades: this.editedItem.cavidades.map( (cavidad) => ({
            idMoldeCavidad: cavidad.idMoldeCavidad,
            clave: cavidad.clave
          }))
        }
      }
      
      
      axios({
        url: this.editedItem.idMolde == null
          ? "/Moldes/Crear"
          : `/Moldes/ActualizarMolde/${this.editedItem.idMolde}`,
        method: this.editedItem.idMolde == null ? "POST" : "PUT",
        data: payload,
        headers: { 
          "Content-Type": this.editedItem.idMolde == null ? "multipart/form-data" : "application/json" 
        }
      })
        .then(() => {
          this.saving = false;
          this.close();
          this.initialize();
        })
        .catch((error) => {
          this.saving = false;
          console.log(error);
          if(error.response?.status == 409){
            this.editedItem.errorCodigo = "Ya existe";
          }
        });
    },
    getMoldes() {
      this.loadingData = true;
      axios
        .get("/Moldes", {
          params: {
            idArticulo: this.parametros.idArticulo,
            idMaquina: this.parametros.idMaquina
          },
        })
        .then((response) => {
          this.moldes = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.moldes = [];
        })
        .finally( () => {
          this.loadingData = false;
        });
    },
    getAreas() {
      this.loadingAreas = true;
      axios.get("/Areas").then((response) => {
        this.loadingAreas = false;
        this.areas = response.data.filter((a) => a.idArea > 1);
      });
    },
    getArticulos() {
      this.loadingArticulos = true;
      axios
        .get("/Articulos/ArticulosNombres?componentes=true")
        .then((response) => {
          this.articulos = response.data;
          this.loadingArticulos = false;
        })
        .catch((error) => {
          this.loadingArticulos = false;
          console.log(error);
        });
    },
    getMaquinasIns() {
      this.loadingMaquinas = true;
      axios
        .get("/Maquinas")
        .then((response) => {
          this.maquinas = response.data;
          this.loadingMaquinas = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingMaquinas = false;
        });
    },
    getRecetasAreas(paso) {
      if (paso.idArticulo == null || this.editedItem.idMaquina == null) return;
      
      this.$set(paso,'loadingRecetas',true);
      
      axios
        .get('/Moldes/ListarRecetasAreas',{
            params: {
              idArticulo: paso.idArticulo,
              idMaquina: this.editedItem.idMaquina
            }
          }
        )
        .then((response) => {
          this.$set(paso,'recetas',response.data);
        })
        .catch((error) => {
          this.$set(paso,'recetas',[]);
          console.log(error);
        })
        .finally( () => {
          this.$set(paso,'loadingRecetas',false);
        });
    },
  },
};
</script>
