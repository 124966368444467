<template>
	<div>
		<v-tabs v-model="tab" background-color="transparent" grow>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.proveedores"
				v-if="permisoVista('mant-proveedores', 'r')"
				@click="proveedoresClick"
			>
				Proveedores
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.categorias"
				v-if="permisoVista('mant-categorias', 'r')"
				@click="categoriasClick"
			>
				Categorias
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.inspecciones"
				v-if="permisoVista('mantenimiento-inspecciones', 'r')"
				@click="inspeccionesClick"
			>
				Inspecciones
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.mantPreventivo"
				v-if="permisoVista('cat-mantenimientopreventivo', 'r')"
				@click="preventivoClick"
			>
				Mantenimiento Preventivo
			</v-tab>
			<v-tab
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.mantCorrectivo"
				v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
				@click="correctivoClick"
			>
				Mantenimiento Correctivo
			</v-tab>
			<v-tab
				v-if="permisoVista('cat-mantenimientoservicio', 'r')"
				v-tooltip.bottom="modoAyuda && $help.mantenimiento.catalogosMantenimiento.servicios"
				@click="serviciosClick"
			>
				Servicios
			</v-tab>

			<v-tabs-items v-model="tab" touchless>
				<v-tab-item
					eager
					v-if="permisoVista('mant-proveedores', 'r')"
				>
					<ProveedoresMantenimiento ref="proveedores"/>
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('mant-categorias', 'r')"
				>
					<CategoriasMantenimiento ref="categorias"/>
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('mantenimiento-inspecciones', 'r')"
				>
					<Inspecciones ref="inspect" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('cat-mantenimientopreventivo', 'r')"
				>
					<CatalogoPreventivo ref="preventivo" />
				</v-tab-item>
				<v-tab-item
					eager
					v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
				>
					<CatalogoCorrectivo ref="correctivo" />
				</v-tab-item>
				<v-tab-item
					v-if="permisoVista('cat-mantenimientoservicio', 'r')"
					eager
				>
					<CatalogoServicios ref="services" />
				</v-tab-item>
			</v-tabs-items>
		</v-tabs>
	</div>
</template>

<script>
import CatalogoPreventivo from "./Catalogos/CatalogoPreventivo.vue";
import CatalogoCorrectivo from "./Catalogos/CatalogoCorrectivo.vue";
import Inspecciones from './Catalogos/Inspecciones.vue';
import { mapState } from 'vuex';
import CatalogoServicios from './Catalogos/CatalogoServicios.vue';
import CategoriasMantenimiento from './Catalogos/CategoriasMantenimiento.vue';
import ProveedoresMantenimiento from './Catalogos/ProveedoresMantenimiento.vue';

export default {
	components: {
		CatalogoPreventivo,
		CatalogoCorrectivo,
		CatalogoServicios,
		Inspecciones,
		CategoriasMantenimiento,
		ProveedoresMantenimiento,
	},
	data: () => ({
		tab: 0,
	}),
	computed:{
		...mapState(["modoAyuda"])
	},
	methods: {
		// cambio(event) {
		// 	if (this.tab == 0) this.$refs.preventivo.cambio(event);
		// 	if (this.tab == 1) this.$refs.correctivo.cambio(event);
		// },
		initialize() {
			this.proveedoresClick();
		},
		proveedoresClick(){
			if(this.$refs.proveedores == null) return;
			this.$refs.proveedores.initialize();
		},
		preventivoClick() {
			if(typeof this.$refs.preventivo === 'undefined') return;
			this.$refs.preventivo.initialize();
		},
		correctivoClick() {
			if(typeof this.$refs.correctivo === 'undefined') return;
			this.$refs.correctivo.initialize();
		},
		serviciosClick(){
			if(typeof this.$refs.services === 'undefined') return;
			this.$refs.services.initialize();
		},
		inspeccionesClick(){
			if(typeof this.$refs.inspect === 'undefined') return;
			this.$refs.inspect.initialize();
		},
		categoriasClick(){
			if(typeof this.$refs.categorias === 'undefined') return;
			this.$refs.categorias.initialize();
		}
	},
};
</script>