import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","hide-overlay":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{attrs:{"id":"projection"}},[_c(VBtn,{staticClass:"v-btn--close",staticStyle:{"position":"absolute","z-index":"100"},attrs:{"color":"blue-grey lighten-2","fab":"","dark":"","bottom":"","left":""},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1),_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tab-1"}},[_c('ReporteRendimientoOperador',{attrs:{"update":_vm.tiempoActualizacion}})],1),_c(VTabItem,{attrs:{"value":"tab-2"}},[_c('ReportePiezasTerminadas',{ref:"piezasterminadas",attrs:{"update":_vm.tiempoActualizacion}})],1),_c(VTabItem,{attrs:{"value":"tab-3"}},[_c('div',{staticClass:"text-center text-h3"},[_vm._v(" Reporte de Avance y Tiempo de Entrega ")]),_c('ReporteAvanceTiempoEntrega',{attrs:{"update":_vm.tiempoActualizacion,"pageInterval":5}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }