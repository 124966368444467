<template>
  <v-col cols="12">
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe un Horario con las mismas características.
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <v-dialog
      v-model="dialog"
      max-width="900px"
      persistent
    >
      <v-card>
        <v-card-title v-if="editedItem.idMantenimientoPreventivo == null">Agregar Mantenimiento Preventivo</v-card-title>
        <v-card-title v-else>{{ tipoEdicion == 1 ? 'Editar' : 'Clonar' }} Mantenimiento Preventivo</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field
                v-model="editedItem.codigo"
                label="Código"
                :error-messages="erroresItem.errorCodigo"
								@focus="erroresItem.errorCodigo = null"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="6" md="6" sm="6">
              <div class="d-flex align-center">
              <div class="text-subtitle-1">
                Color:
              </div>
              <div style="width:15px;"></div>
              <v-swatches
                v-model="editedItem.color"
                swatches="text-advanced" 
              ></v-swatches>
              </div>
            </v-col> -->
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.nombre"
                label="Nombre"
                :error-messages="erroresItem.errorNombre"
								@focus="erroresItem.errorNombre = null"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-textarea
              v-model="editedItem.descripcion"
                label="Descripción"
                auto-grow
                class="pt-5"
                rows="1"
              ></v-textarea>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="mb-0 pb-0">
              <time-picker
                class="py-3"
                v-model="editedItem.duracion"
                label="Duración"
                :error="!!erroresItem.errorDuracion"
								@focus="erroresItem.errorDuracion = null"
              ></time-picker>
            </v-col>
            <v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idMantenimientoCategoria"
								label="Categoria del Mantenimiento"
								:items="categorias"
								item-text="nombre"
								item-value="idMantenimientoCategoria"
								clearable
								:disabled="loadingCategorias"
								:loading="loadingCategorias"
								:error-messages="erroresItem.errorCategoria"
								@focus="erroresItem.errorCategoria = null"
							></v-autocomplete>
						</v-col>
            <v-col cols="6" md="6" sm="6">
              <v-autocomplete
                v-model="editedItem.idMaquina"
                label="Modelo"
                :items="maquinas"
                :item-text="marcaModelo"
                item-value="idMaquina"
                :menu-props="{
									maxWidth: 'min-content'
								}"
                :error-messages="erroresItem.errorModelo"
								@focus="erroresItem.errorModelo = null"
                @change="onChangeModelo"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="6" class="mt-0 pt-0">
              <MaquinaInsAutocomplete
                v-model="editedItem.idsMaquinaIns"
                label="[Opcional] Máquina(s)"
                :items="computedIns"
                :persistent-hint="editedItem.idMaquina == null"
								:hint="editedItem.idMaquina == null ? 'Seleccione un Modelo' : ''"
                :disabled="editedItem.idMaquina == null"
                chips
                multiple
                deletable-chips
                item-text="nombre"
                item-value="idMaquinaIns"
              />
            </v-col>
            <v-col cols="6" v-if="showSubdepto">
              <v-autocomplete
                v-model="editedItem.idSubdepartamento"
                label="Subdepartamento"
                :items="subdepartamentos"
                :item-text="nombreSubdepto"
                item-value="idSubdepartamento"
                clearable
								:error-messages="erroresItem.errorSubdepto"
								@focus="erroresItem.errorSubdepto = null"
              ></v-autocomplete>
            </v-col>
            <v-col v-if="showKilometrajeProgramado" cols="6">
              <v-text-field
                v-model="editedItem.kmProgramado"
                label="Programar cada"
                suffix="Km"
                type="number"
                hide-spin-buttons
                clearable
                :error-messages="erroresItem.errorKmProgramado"
                @focus="erroresItem.errorKmProgramado = null"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
							<p class="text-center mb-0">Tipo de asignación</p>
							<v-radio-group
								v-model="editedItem.tipoAsignacion"
								column
								class="mt-0"
								hide-details
								@change="onChangeTipoAsignacion"
							>
								<v-radio
									label="Personas"
									class="mb-0 text-center"
									:value="1"
								></v-radio>
								<v-radio
									label="Proveedor"
									:value="2"
								></v-radio>
							</v-radio-group>
						</v-col>
            <template v-if="editedItem.tipoAsignacion == 1">
              <v-col cols="3">
                <p class="text-center">Personas requeridas</p>
                <div class="d-flex align-center justify-center">
                  <number-input
                    v-model="editedItem.numPersonas"
                    controls
                    inline
                    size="small"
                    :min="1"
                    center
                  ></number-input>
                </div>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.asignados"
                  label="[Opcional] Asignar por defecto a"
                  :items="personasMantenimiento"
                  :item-text="nombrePersona"
                  item-value="idPersona"
                  multiple
                  chips
                  deletable-chips
                  clearable
                  :error-messages="erroresItem.errorAsignados"
                  @focus="erroresItem.errorAsignados = null"
                ></v-autocomplete>
              </v-col>
            </template>
            <template v-else-if="editedItem.tipoAsignacion == 2">
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.idMantenimientoProveedor"
									label="[Opcional] Proveedor por defecto"
									:items="proveedores"
									:item-text="$utils.mantenimientos.getNombreProveedor"
									item-value="idMantenimientoProveedor"
									clearable
									hide-details
								></v-autocomplete>
							</v-col>
						</template>
          </v-row>
          <v-row class="justify-center">
            <div class="headline" v-if="permisoVista('cat-mantenimientopreventivo','$')" >Costo {{ this.editedItem.costo }}</div>
            <v-btn color="primary" block @click="openInsumos()">Insumos ({{editedItem.insumos.length}})</v-btn>
          </v-row>
          <br>
          <ListadoPorHacer ref="todo" :item="editedItem" />
          <v-row v-if="!showKilometrajeProgramado">
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                :items="editedItem.horarios"
                :headers="headers"
                :items-per-page="-1"
                hide-default-footer
              >
                <template v-slot:top>
                  <v-btn color="primary" small @click="onClickNuevoHorario">Nuevo Horario</v-btn>
                </template>
                <template v-slot:no-data>
                  No hay horarios registrados.
                </template>
                <template v-slot:[`item.enunciado`]="{ item }">
                  {{$refs.horario.leyenda(item)}}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        small
                        @click="editHorario(item)"
                      >
                        edit
                      </v-icon>
                    </template>
                    <span class="white--text">Editar</span>
                  </v-tooltip>
                  
                  <v-tooltip bottom color="black" class="white--text">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                        small
                        @click="deleteHorario(item)"
                      >
                        delete
                      </v-icon>
                    </template>
                    <span class="white--text">Eliminar</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-slide-y-transition>
                <p v-if="erroresItem.errorHorarios" class="mb-0 error--text text-right">{{ erroresItem.errorHorarios }}</p>
              </v-slide-y-transition>
            </v-col>
          </v-row>
          <v-slide-y-transition>
            <v-row v-if="errores.length > 0">
              <v-col class="mt-4" cols="12">
                <ul>
                  <li class="red--text" v-for="(error,idx) in errores" :key="'error'+idx">{{error}}</li>
                </ul>
              </v-col>
            </v-row>
          </v-slide-y-transition>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="green" 
            text 
						:disabled="guardando"
            @click="closeMantenimiento"
            >Cancelar</v-btn
          >
          <v-btn
            color="red"
            text
            :loading="guardando"
						:disabled="guardando" 
            @click="guardarMantenimiento"
            >{{ textButtonSave }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-insumos
      v-model="editedItem.insumos"
      :show="dialogInsumos"
      :name="editedItem.nombre"
      :duracion="editedItem.duracion"
      @close="closeInsumos"
      @update:price="updateCosto"
    ></dialog-insumos>
    <dialog-horario
      @create="newHorario($event)"
      @update="updateHorario($event)"
      ref="horario"
    >
    </dialog-horario>
    <v-dialog
      v-model="dialogEliminar"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <v-card-title class="headline">¿Está seguro que desea eliminar este mantenimiento?</v-card-title>
          <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeEliminarMantenimiento">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="runRemoverMantenimiento" :disabled="deleting" :loading="deleting">
            Eliminar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAtemporal"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-card-title class="headline">Mantenimiento Atemporal</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <HeaderInfoMantenimiento
                  :maquinas="maquinas"
                  :items="[{...atemporalItem}]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <MenuDatepicker
                  v-model="atemporalInfo.inicio"
                  label="Inicio del Mantenimiento"
                  clearable
                  :disabled="saving"
                  :errorMessage="erroresItem.errorFechaInicio"
                  @focus="erroresItem.errorFechaInicio = null"
                />
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <MaquinaInsAutocomplete
                  v-model="atemporalInfo.instancias"
                  label="Máquina(s)"
                  :items="instanciasFiltradas"
                  item-text="nombre"
                  item-value="idMaquinaIns"
                  :disabled="saving"
                  chips
                  multiple
                  deletable-chips
                  :error-message="erroresItem.errorInstancias"
                  @focus="erroresItem.errorInstancias = null"
                />
              </v-col>
            </v-row>
            <v-slide-y-transition>
              <div v-if="errores.length > 0">
                <ul class="mt-3">
                  <li class="red--text" v-for="(error,idx) in errores" :key="'error-'+idx">{{error}}</li>
                </ul>
              </div>
            </v-slide-y-transition>
            <div class="headline text-center">
              {{labelAtemporal}}
            </div>
          </v-card-text>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text :disabled="saving" @click="closeLaunchMantenimiento">Cancelar</v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="mantenimientoAtemporal"
            :disabled="saving"
            :loading="saving"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Recomendaciones ref="recomen" />
    <v-row>
      <v-col cols="12" class="pb-0 d-flex align-center justify-space-between">
        <v-btn v-if="permisoVista('cat-mantenimientopreventivo','c')" color="primary" :disabled="loading" @click="dialog = true">Agregar Mantenimiento Preventivo</v-btn>
        <v-btn v-if="permisoVista('cat-mantenimientopreventivo','c')" color="primary" :disabled="!calculoProxMant || loading" @click="calcularProximosMantenimientos()">
          <span v-if="calculoProxMant" >Calcular Próximos Mantenimientos</span>
          <span v-else>Esta accion puede tardar algunos minutos <br/>en reflejarse los datos, espere por favor</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0 justify-end">
      <v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					:disabled="loading"
				></v-text-field>
			</v-col>
    </v-row>
    <v-data-table
      :items="horarios"
      :headers="headersHorario"
      :loading="loading"
      :search="search"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:[`item.costo`] ="{ item }">
        $ {{item.costo.toFixed(2)}}
      </template>
      <template 
        v-slot:[`item.actions`] ="{ item }"
      >
        <v-tooltip
					v-if="permisoVista('cat-mantenimientopreventivo','c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
              :disabled="loading"
							class="mr-1"
							@click="editMantenimiento(item,2)"
						>
							mdi-content-duplicate
						</v-icon>
					</template>
					<span class="white--text">Clonar</span>
				</v-tooltip>
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','c')"
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          class="mr-1"
          small
          :disabled="loading"
          @click="launchMantenimiento(item)"
        >
          mdi-play
        </v-icon>
         </template>
          <span class="white--text">Programar Mantenimiento Atemporal</span>
        </v-tooltip>
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','u')"
          bottom color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          class="mr-1"
          small
          :disabled="loading"
          @click="editMantenimiento(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <!-- <v-tooltip
					v-if="permisoVista('cat-mantenimientopreventivo','r')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
              :disabled="
								item.recomendaciones == null ||
								item.recomendaciones == undefined ||
                item.recomendaciones.length == 0
							"
							v-on="on"
							small
							@click="showRecomendaciones(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Recomendaciones</span>
				</v-tooltip> -->
        <v-tooltip
          v-if="permisoVista('cat-mantenimientopreventivo','d')"
          bottom
          color="black"
          class="white--text"
        >
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          class="mr-1"
          small
          :disabled="loading"
          @click="removeMantenimiento(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import timepicker from '@/components/TimePicker'
import insumos from '../Insumos.vue'
import horario from '../Horario.vue'
import axios from "axios"
import VSwatches from 'vue-swatches'
import moment from 'moment'
import ListadoPorHacer from './ListadoPorHacer.vue'
import Recomendaciones from './Recomendaciones.vue'
import { mapState } from 'vuex'
import MaquinaInsAutocomplete from '../MaquinaInsAutocomplete.vue'
import HeaderInfoMantenimiento from './HeaderInfoMantenimiento.vue'

export default {
  components:{
    'time-picker':timepicker,
    'dialog-insumos':insumos,
    'dialog-horario': horario,
    VSwatches,
    ListadoPorHacer,
    Recomendaciones,
    MaquinaInsAutocomplete,
    HeaderInfoMantenimiento
  },
  data: () => ({
    search: null,
    loadingProveedores: false,
    proveedores: [],
    loadingCategorias: false,
    categorias: [],
    erroresItem: {
			errorCodigo: null,
			errorNombre: null,
			errorModelo: null,
			errorSubdepto: null,
			errorAsignados: null,
      errorHorarios: null,
      errorCategoria: null,
      errorFechaInicio: null,
      errorInstancias: null,
      errorKmProgramado: null
		},
    calculoProxMant: true,
    tipoEdicion: 1,
    personasMantenimiento: [],
    subdepartamentos: [],
    guardando: false,
    dialog: false,
    dialogInsumos:false,
    editedItem:null,
    defaultEditedItem:{
      idMantenimientoPreventivo: null,
      idMantenimientoCategoria: null,
      idSubdepartamento: null,
      codigo:'',
      nombre:'',
      descripcion:'',
      duracion:0,
      idMaquina: null,
      costo: 0,
      insumos:[],
      horarios:[],
      comprobaciones: [],
      color:"#1976D2",
      idsMaquinaIns: [],
      numPersonas: 1,
      asignados: [],
      inicio:null,
      instancias:[],
      tipoAsignacion: 1,
      idMantenimientoProveedor: null,
      kmProgramado: null
    },
    maquinas:[],
    headers:[
      {
        text: "Enunciado",
        align: "left",
        sortable: false,
        value: "enunciado",
        width: '80%,'
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        width: '20%,'
      },
    ],
    alerta:false,
    errores:[],
    horarios:[],
    loading:false,
    headersHorario:[
      {
        text: "Código",
        align: "center",
        sortable: false,
        value: "codigo",
      },
      {
        text: "Nombre",
        align: "center",
        sortable: false,
        value: "nombre",
      },
      {
        text: "Categoría Mantenimiento",
        align: "center",
        sortable: false,
        value: "nombreCategoria",
      },
      {
        text: "Categoría Máquina",
        align: "center",
        sortable: false,
        value: "categoriaMaquina"
      },
      {
        text: "Modelo",
        align: "center",
        sortable: false,
        value: "maquina",
      },
      {
        text: "Costo",
        align: "center",
        sortable: false,
        value: "costo",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        value: "actions",
        width: '10%,'
      },
    ],
    dialogEliminar:false,
    eliminarItem: null,
    deleting:false,
    dialogAtemporal:false,
    instancias:[],
    atemporalItem: {
      inicio:null,
      instancias:[],
    },
    atemporalInfo: {
      inicio:null,
      instancias:[],
    },
    defaultAtemporalItem: {
      inicio:null,
      instancias:[],
    },
    saving: false,
  }),
  beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
		this.eliminarItem = structuredClone(this.defaultEditedItem);
		this.atemporalItem = structuredClone(this.defaultAtemporalItem);
    this.atemporalInfo = structuredClone(this.defaultAtemporalItem);
	},
  mounted(){
    this.initialize();
    if(!this.permisoVista('cat-mantenimientopreventivo','$')) this.headersHorario = this.headersHorario.filter(x => x.value != 'costo');
  },
  computed:{
    ...mapState(['nombreEmpresa']),
    showKilometrajeProgramado(){
      return this.esIndasa && this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas, 'transporte', this.editedItem.idMaquina)
    },
    esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
    instanciasFiltradas(){
      return this.instancias.filter(instancia => instancia.idMaquina == this.atemporalItem.idMaquina);
    },
    showSubdepto(){
			if(this.esIndasa) return false;
			return true;
		},
    textButtonSave(){
			return this.editedItem.idMantenimientoPreventivo == null
				? 'Agregar'
				: this.tipoEdicion == 1
					? 'Actualizar'
					: 'Clonar'
		},
    computedIns(){
      const ins = this.instancias.filter( instancia =>  instancia.idMaquina === this.editedItem.idMaquina)
      return ins;
    },
    plural(){
      return (this.$utils.isValid(this.atemporalInfo.instancias) && this.atemporalInfo.instancias.length > 1);
    },
    maquinaLabel(){
      var maquina = this.maquinas.find(maquina => maquina.idMaquina == this.atemporalItem.idMaquina);
      return (this.$utils.isValid(maquina))?maquina.marca+" - "+maquina.modelo:null;
    },
    labelAtemporal(){
      if(this.$utils.isValid(this.atemporalInfo.inicio) && this.$utils.isValid(this.atemporalInfo.instancias) && this.atemporalInfo.instancias.length > 0){
        var localLocale = moment(this.atemporalInfo.inicio, "YYYY-MM-DD");
        localLocale.locale('es');
        return "Mantenimiento programado para " +
        localLocale.format('[el día] dddd DD [de] MMMM  [del] YYYY');
      }
      else return null;
    }
  },
  methods:{
    onChangeModelo(){
      this.resetMaquinaIns();
      this.editedItem.kmProgramado = null;
      this.editedItem.horarios = [];
    },
    onChangeTipoAsignacion(){
			this.editedItem.numPersonas = 1;
			this.editedItem.asignados = [];
			this.editedItem.idMantenimientoProveedor = null;
		},
    onClickNuevoHorario(){
      if(this.editedItem.horarios.length == 0){
        this.erroresItem.errorHorarios = null;
      }
      this.$refs.horario.lanzar();
    },
    nombreMaquinaInstancia(maquinaIns){
			if(this.nombreEmpresa.toUpperCase() == 'INDASA'){
				return `No. Serie: ${maquinaIns.nombre}`;
			}
			return maquinaIns.nombre;
		},
    resetErrores(){
      this.errores = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
    nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
    nombreSubdepto: ({ codigo, nombre }) =>
      codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    showRecomendaciones(item){
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
    calcularFraccion(num,x,y,z){
      if(num == 1){
        return this.$utils.numbers.validFloat(x);
      }
      if(num == 2){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y);
      }
      if(num == 3){
        return this.$utils.numbers.validFloat(x) * this.$utils.numbers.validFloat(y) * this.$utils.numbers.validFloat(z);
      }
    },
    guardarMantenimiento(){
      // this.$refs.todo.togglePanel(null);
			this.save();
    },
    resetMaquinaIns(){
      this.editedItem.idsMaquinaIns =[];
    },
    marcaModelo: item => item.marca +' - '+item.modelo,
    initialize(){
      this.getPersonasMantenimiento();
      this.getSubdepartamentos();
      this.getMaquinas();
      this.getMantenimientos();
      this.getInstancias();
      this.getCategoriasMantenimiento();
      this.getProveedoresMantenimiento();
    },
    getProveedoresMantenimiento(){
			this.loadingProveedores = true;

			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingProveedores = false;
				});
		},
    getCategoriasMantenimiento() {
			this.loadingCategorias = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
    getPersonasMantenimiento() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personasMantenimiento = response.data;
				})
				.catch((error) => {
					this.personasMantenimiento = [];
					console.log(error);
				});
		},
    getSubdepartamentos(){
      axios
        .get('/Subdepartamento')
        .then( response => {
          this.subdepartamentos = response.data;
        })
        .catch(console.log)
    },
    openInsumos(){
      this.dialog = false;
      this.dialogInsumos = true;
    },
    closeInsumos(){
      this.dialogInsumos = false;
      this.dialog = true;
    },
    updateCosto(event){
      this.editedItem.costo = event;
    },
    closeMantenimiento(){
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = structuredClone(this.defaultEditedItem);
        this.resetErrores();
        this.tipoEdicion = 1;
        this.$refs.todo.togglePanel(0);
      }, 200);
    },
    getMaquinas(){
      axios
      .get("/Maquinas")
      .then(response =>  {
        this.maquinas = response.data.filter(function(el) {
          return (el.esMaquina == true);
        });
      })
      .catch(error => {
        console.log(error);
      });
    },
    getInstancias(){
      axios
      .get("/MaquinaInstancias")
      .then(response =>  {
        this.instancias = response.data;
      })
      .catch(error => {
        console.log(error);
      });
    },
    getMantenimientos(){
      this.loading = true;
      axios
      .get("/Mantenimientos/Preventivos")
      .then(response =>    {
        this.loading = false;
        this.horarios = response.data;
      })
      .catch(error => {
        this.loading = false;
        console.log(error);
      })
    },
    editMantenimiento(item,tipoEdicion = 1){
      this.editedItem = structuredClone(item);
      this.editedItem.comprobaciones = this.$utils.mantenimientos.mapComprobaciones(structuredClone(item.comprobaciones));
      this.editedItem.insumos = item.insumos.map(i => Object.assign({},i));
      this.editedItem.horarios = item.horarios.map(h => Object.assign({},h));
      this.tipoEdicion = tipoEdicion;
      if(this.tipoEdicion == 2){
        this.editedItem.codigo = null;
        this.editedItem.nombre = null;
      }
      this.dialog = true;
    },
    removeMantenimiento(item){
      this.dialogEliminar = true;
      this.eliminarItem = structuredClone(item);
    },
    runRemoverMantenimiento(){
      this.deleting = true;
      axios
      .put("/Mantenimientos/DesactivarPreventivo/"+this.eliminarItem.idMantenimientoPreventivo)
      .then(response => {
        this.deleting = false;
        this.closeEliminarMantenimiento();
        this.getMantenimientos();
      })
      .catch(error => {
        this.deleting = false;
        console.log(error);
      })
    },
    closeEliminarMantenimiento(){
      this.dialogEliminar = false;
      this.eliminarItem = structuredClone(this.defaultEditedItem);
    },
    launchMantenimiento(item){
      this.dialogAtemporal = true;
      this.atemporalItem = structuredClone(item);
    },
    closeLaunchMantenimiento(){
      this.dialogAtemporal = false;
      setTimeout(() => {
        this.resetErrores();
        this.atemporalItem = structuredClone(this.defaultAtemporalItem);
        this.atemporalInfo = structuredClone(this.defaultAtemporalItem);
      }, 200);
    },
    validateAtemporal(){
      this.resetErrores();
      
      if(this.atemporalInfo.inicio == null){
        this.erroresItem.errorFechaInicio = "Requerido";
      }

      if(this.atemporalInfo.instancias.length == 0){
        this.erroresItem.errorInstancias = "Requerido";
      }

      const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
			if(hayErrores){
				this.errores.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.errores = [];
				}, 2000);
			}

			return !hayErrores;
    },
    mantenimientoAtemporal(){
      if(!this.validateAtemporal()) return;
      var obj = {
        IdMantenimientoPreventivo: this.atemporalItem.idMantenimientoPreventivo,
        IdsMaquinasInstancias: this.atemporalInfo.instancias,
        InicioMantenimiento: this.atemporalInfo.inicio
      }

      this.saving = true;
      
      axios
        .post("/Mantenimientos/PreventivoAtemporal",obj)
        .then(() => {
          this.saving = false;
          this.closeLaunchMantenimiento();
        })
        .catch(error => {
          this.saving = false;
        })
    },
    updateHorario($event){
      var posExist = this.editedItem.horarios.findIndex(h => {
        return this.$utils.arrays.equals(h.diasSemana,$event.diasSemana) &&
          this.$utils.arrays.equals(h.meses,$event.meses) &&
          this.$utils.arrays.equals(h.diasMes,$event.diasMes) &&
          h.horaMinuto == $event.horaMinuto;
      });
      var pos = this.editedItem.horarios.findIndex(h => h.idMantenimientoPreventivoHorario == $event.idMantenimientoPreventivoHorario);
      if(posExist >= 0 && pos != posExist){
        this.alerta = true;
        return;
      }
      if(pos >= 0){
        this.editedItem.horarios[pos] = null;
        this.editedItem.horarios[pos] = $event;
      }
      else{
        this.editedItem.horarios.push($event);
      }
    },
    editHorario(item){
      this.$refs.horario.lanzar(item);
    },
    deleteHorario(item){
      var pos = this.editedItem.horarios.indexOf(item);
      if(pos >= 0)this.editedItem.horarios.splice(pos,1);
    },
    validate(){
      this.resetErrores();

      if(
        !this.editedItem.codigo ||
        this.editedItem.codigo.length < 5 ||
        this.editedItem.codigo.length > 100
      ){
        this.erroresItem.errorCodigo = "Debe tener entre 5 y 100 caracteres";
      }
      
      if(
        !this.editedItem.nombre ||
        this.editedItem.nombre.length < 5 ||
        this.editedItem.nombre.length > 1000
      ){
        this.erroresItem.errorNombre = "Debe tener entre 5 y 100 caracteres";
      }

      if(this.esIndasa){
				if(this.editedItem.idMantenimientoCategoria == null){
					this.erroresItem.errorCategoria = "Requerido";
				}

        if(this.showKilometrajeProgramado){
          const parsedKm = parseFloat(this.editedItem.kmProgramado);
          if(isNaN(parsedKm)) this.erroresItem.errorKmProgramado = "Requerido";
          else if(parsedKm <= 0 ) this.erroresItem.errorKmProgramado = "Debe ser mayor a 0";
        }
			}
      
      if (this.editedItem.idMaquina == null) {
				this.erroresItem.errorModelo = "Requerido";
			}

      if(!this.showKilometrajeProgramado && this.editedItem.horarios.length <= 0){
        this.erroresItem.errorHorarios = "Debe ingresar al menos un Horario";
      }
      
      if(!this.esIndasa){
				if(this.editedItem.idSubdepartamento == null){
					this.erroresItem.errorSubdepto = "Requerido";
				}
			}
      
      if(this.editedItem.numPersonas > 0){
				if(this.editedItem.asignados.length > 0 && this.editedItem.asignados.length != this.editedItem.numPersonas){
					this.erroresItem.errorAsignados = "Debe ser igual al número de personas requeridas";
				}
			}

      const comprobacionesValidas = this.$refs.todo.validate();
      
      const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.errores = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
				setTimeout(() => {
					this.errores = [];
				}, 3000);
			}

			return !hayErrores;
    },
    save(){
      if(!this.validate()) return;
      this.guardando = true;

      if(this.esIndasa){
				this.$utils.mantenimientos.asignarSubdeptoDefault(this.subdepartamentos,this.editedItem);
			}

      if(this.showKilometrajeProgramado){
        this.editedItem.horarios = [];
      }

      const comprobaciones = this.$refs.todo.getListado();
      const data = {
        IdMantenimientoPreventivo: this.editedItem.idMantenimientoPreventivo,
        Codigo: this.editedItem.codigo,
        Color: this.editedItem.color,
        Nombre: this.editedItem.nombre,
        Descripcion: this.editedItem.descripcion,
        Duracion: this.editedItem.duracion,
        IdMaquina: this.editedItem.idMaquina,
        Insumos: this.editedItem.insumos.map(element => {
          return {
            IdArticulo: (typeof element.idArticulo === 'undefined')?null:element.idArticulo,
            IdMaquina: (typeof element.idMaquina === 'undefined')?null:element.idMaquina,
            IdPuesto: (typeof element.idPuesto === 'undefined')?null:element.idPuesto,
            Cantidad: (element.conversion && element.numDimensiones == 1)? 1 : this.$utils.numbers.validFloat(element.cantidad),
            Tiempo: (typeof element.tiempo === 'undefined')?null:element.tiempo,
            Proporcional: element.conversion,
            Fraccion: (element.conversion)? (  (element.numDimensiones == 1)? (this.$utils.numbers.validFloat(element.cantidad)/element.factorConversion) : this.calcularFraccion(element.numDimensiones,element._ejeX,element._ejeY,element._ejeZ)  ) :null,
            EjeX: (element.conversion)? ( element.numDimensiones == 1 ? (this.$utils.numbers.validFloat(element.cantidad)/element.factorConversion) : this.$utils.numbers.validFloat(element._ejeX) ):null,
            EjeY: (element.conversion)?this.$utils.numbers.validFloat(element._ejeY):null,
            EjeZ: (element.conversion)?this.$utils.numbers.validFloat(element._ejeZ):null,
          }
        }),
        Horarios: this.editedItem.horarios.map(h => {
          return {
            IdMantenimientoPreventivoHorario: h.idMantenimientoPreventivoHorario,
            DiasSemana: h.diasSemana,
            Meses: h.meses,
            DiasMes: h.diasMes,
            HoraMinuto: h.horaMinuto
          };
        }),
        idsMaquinaIns: this.editedItem.idsMaquinaIns,
        idSubdepartamento: this.editedItem.idSubdepartamento,
        comprobaciones,
        numPersonas: this.editedItem.numPersonas,
        asignados: this.editedItem.asignados,
        idMantenimientoCategoria: this.editedItem.idMantenimientoCategoria,
        tipoAsignacion: this.editedItem.tipoAsignacion,
				idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor,
        kmProgramado: this.editedItem.kmProgramado ? parseFloat(this.editedItem.kmProgramado) : null
      }

      axios({
        url: this.editedItem.idMantenimientoPreventivo == null || this.tipoEdicion == 2
          ? "/Mantenimientos/CrearPreventivo"
          : "/Mantenimientos/ActualizarPreventivo",
        method: this.editedItem.idMantenimientoPreventivo == null || this.tipoEdicion == 2
          ? "POST"
          : "PUT",
        data
      })
        .then(() => {
          this.closeMantenimiento();
          this.getMantenimientos();
          this.guardando = false;
        })
        .catch(error => {
          console.log(error);
          this.guardando = false;
          if (error.response?.status == 409) {
						this.$set(this.erroresItem, "errorCodigo", "Ya existe");
					}
        });
    },
    calcularProximosMantenimientos(){
     axios
          .post("/Mantenimientos/CalcularMantenimientosPreventivosProgramados")
          .then(response => {
            this.calculoProxMant = !this.calculoProxMant;
          })
          .catch(error => {
            this.calculoProxMant = true;
          });
    }
  }
}
</script>

<style>
  .vue-swatches__trigger__wrapper{
    border: 1px solid rgb(0, 0, 0) !important;
    border-radius: 12px !important;
  }
</style>