<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones','r')">
    <v-dialog v-model="dialogComprobaciones" persistent max-width="85%">
			<v-card>
				<v-card-title style="word-break: break-word">Comprobaciones - Máquina: {{ getNombreMaquina() }}</v-card-title>
				<v-card-text>
					<v-container fluid>
						<v-row>
							<v-col cols="12">
								<ComprobacionesRegistros
									v-if="dialogComprobaciones"
									:comprobaciones="itemComprobaciones.comprobaciones"
									:loading="loadingComprobaciones"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogComprobaciones"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-dialog v-model="dialogInspeccion" persistent max-width="70%">
			<v-card>
				<v-card-title>Inspecciones</v-card-title>
				<v-card-text>
					<v-data-table
						:items="itemInspeccion"
						:headers="headersInspeccion"
						loading-text="Cargando... Espere, Por Favor."
						:loading="loadingInspeccion"
						:footer-props="{
							disableItemsPerPage: true,
							disablePagination: this.loadingInspeccion,
						}"
					>
						<template v-slot:[`item.completo`]="{ item }">
							<p
								class="mb-0 font-weight-bold"
								:class="
									item.completo
										? 'success--text'
										: 'error--text'
								"
								style="font-size: 14px !important"
							>
								{{ item.completo ? "SI" : "NO" }}
							</p>
						</template>
						<template v-slot:[`item.responsable`]="{ item }">
							{{ item.responsable || "N/A" }}
						</template>
						<template v-slot:[`item.fechaInicio`]="{ item }">
							{{
								item.fechaInicio == null
									? "N/A"
									: item.fechaInicio
							}}
						</template>
						<template v-slot:[`item.fechaFin`]="{ item }">
							{{ item.fechaFin == null ? "N/A" : item.fechaFin }}
						</template>
						<template v-slot:[`item.maquina`]="{ item }">
							{{ `${item.marca} - ${item.modelo}` }}
						</template>
						<template v-slot:[`item.actions`]="{ item }">
							<v-tooltip
								bottom
								color="black"
								class="white--text"
							>
								<template v-slot:activator="{ on }">
									<v-icon
										v-if="item.comprobaciones.length > 0"
										v-on="on"
										small
										@click="verComprobaciones(item)"
									>
										mdi-order-bool-ascending-variant
									</v-icon>
								</template>
								<span class="white--text"
									>Ver Comprobaciones</span
								>
							</v-tooltip>
						</template>
					</v-data-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						small
						@click="cerrarDialogInspeccion"
						>Cerrar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col cols="6" class="pt-0">
				<MenuDatepicker
					v-model="filtros.inicio"
					label="Desde"
					:max-date="filtros.fin"
					clearable
					hide-details
					:disabled="loading"
					@input="mantenimientos_update_page_action()"
				/>
			</v-col>
			<v-col cols="6" class="pt-0">
				<MenuDatepicker
					v-model="filtros.fin"
					label="Hasta"
					:min-date="filtros.inicio"
					clearable
					hide-details
					:disabled="loading"
					@input="mantenimientos_update_page_action()"
				/>
			</v-col>
			<v-col cols="6">
				<v-select
					v-model="filtros.completo"
					:items="itemsCompleto"
					item-text="nombre"
					item-value="value"
					label="Completos"
					class="pt-1"
					hide-details
					clearable
					:disabled="loading"
					@change="mantenimientos_update_page_action()"
				></v-select>
			</v-col>
			<v-col cols="6">
				<v-autocomplete
					v-model="filtros.idsInspeccion"
					:items="inspecciones"
					:item-text="nombreInspeccion"
					item-value="idMantenimientoInspeccion"
					label="Inspección"
					class="mt-0 pt-0 mb-3"
					clearable
					hide-details
					multiple
					chips
					deletable-chips
					:disabled="loading"
					@change="mantenimientos_update_page_action()"
				></v-autocomplete>
			</v-col>
		</v-row>
    <v-data-table
			:items="inspeccionCiclos"
			:headers="headersInspeccionCiclos"
			loading-text="Cargando... Espere, Por Favor."
			:loading="loading"
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
      item-key="idMantenimentoInspeccion"
      show-expand
      :expanded.sync="expanded"
		>
      <template v-slot:[`item.porcentaje`]="{ item }">
        <v-progress-linear
					:value="porcentajeInspecciones(item)"
					:color="porcentajeInspecciones(item) == 100 ? 'green' : 'blue'"
					height="25"
					rounded
				>
					<strong :class="porcentajeInspecciones(item) == 100 ? 'white--text' : 'black--text'">
            {{ porcentajeInspecciones(item).toFixed(2) }}%
          </strong>
				</v-progress-linear>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <br>
        <!--
        {{item.ciclos}}
        -->
        <v-data-table
          :items="item.ciclos"
          :headers="headersCiclos"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:[`item.porcentaje`]="{ item }">
			    	<v-progress-linear
			    		:value="item.porcentaje"
			    		:color="item.porcentaje == 100 ? 'green' : 'blue'"
			    		height="25"
			    		rounded
			    	>
			    		<strong
			    			:class="
			    				item.porcentaje == 100
			    					? 'white--text'
			    					: 'black--text'
			    			"
			    			>{{ Math.ceil(item.porcentaje) }}%</strong
			    		>
			    	</v-progress-linear>
			    </template>
			    <template v-slot:[`item.actions`]="{ item }">
			    	<v-tooltip
			    		
			    		bottom
			    		color="black"
			    		class="white--text"
			    	>
			    		<template v-slot:activator="{ on }">
			    			<v-icon v-on="on" small @click="verInspecciones(item)">
			    				mdi-eye
			    			</v-icon>
			    		</template>
			    		<span class="white--text">Ver Inspecciones</span>
			    	</v-tooltip>
			    </template>
        </v-data-table>
        <br>
        <br>
      </td>
    </template>
    </v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import ComprobacionesRegistros from './ComprobacionesRegistros.vue';
import MenuDatepicker from '../../MenuDatepicker.vue';

export default {
	components: { ComprobacionesRegistros, MenuDatepicker },
	data: () => ({
	expanded: [],
    inspecciones:[],
    loading: false,
		filtros: {
			completo: null,
			idsInspeccion: [],
			inicio: null,
			fin: null,
		},
    inspeccionCiclos:[],
    headersInspeccionCiclos:[
      {
				text: "Código Inspección",
				align: "center",
				sort: false,
				value: "codigo",
			},
      {
				text: "Nombre Inspeccíon",
				align: "center",
				sort: false,
				value: "nombre",
			},
      {
				text: "Porcentaje Completado",
				align: "center",
				sort: false,
				value: "porcentaje",
        width: "50%"
			},
      { text: '', value: 'data-table-expand' },
    ],
    itemsCompleto: [
			{
				value: true,
				nombre: "SI"
			},
			{
				value: false,
				nombre: "NO"
			}
		],
    headersCiclos: [
			{
				text: "# Ciclo",
				align: "center",
				sort: false,
				value: "numero",
			},
			{
				text: "%",
				align: "center",
				sort: false,
				value: "porcentaje",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "fechaInicio",
			},
			{
				text: "Acciones",
				align: "center",
				sort: false,
				value: "actions",
			},
		],
    dialogInspeccion: false,
		itemInspeccion: [],
    headersInspeccion: [
			{
				text: "Completo",
				align: "center",
				sort: false,
				value: "completo",
			},
			{
				text: "Categoría Mantenimiento",
				align: "center",
				sort: false,
				value: "categoriaMantenimiento",
			},
			{
				text: "Responsable",
				align: "center",
				sort: false,
				value: "responsable",
			},
			{
				text: "Categoría Máquina",
				align: "center",
				sort: false,
				value: "categoriaMaquina",
			},
			{
				text: "Modelo",
				align: "center",
				sort: false,
				value: "maquina",
			},
			{
				text: "Maquina",
				align: "center",
				sort: false,
				value: "maquinaInstancia",
			},
			{
				text: "Inicio",
				align: "center",
				sort: false,
				value: "fechaInicio",
			},
			{
				text: "Fin",
				align: "center",
				sort: false,
				value: "fechaFin",
			},
			{
				text: "Acciones",
				align: "center",
				sort: false,
				value: "actions",
			},
		],
    loadingInspeccion: false,
    itemComprobaciones: {
			maquinaInstancia: "",
			marca: "",
			modelo: "",
		},
		defaultItemComprobaciones: {
			maquinaInstancia: "",
			marca: "",
			modelo: "",
		},
    dialogComprobaciones: false,
    loadingComprobaciones: false,
    
  }),
  mounted() {
		this.initialize();
	},
	methods: {
    nombreInspeccion: ins => `[${ins.codigo}] ${ins.nombre}`,
		initialize() {
			this.mantenimientos_update_page_action();
			this.getInspecciones();
		},
		getStatusReparacion(reparacion) {
			if (
				reparacion.fechaInicioRealizo == null &&
				reparacion.fechaFinRealizo == null
			) {
				return "Programada";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo == null
			) {
				return "En Progreso";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo != null
			) {
				return "Realizada";
			}
		},
		getInspecciones(){
			axios
				.get("/Mantenimientos/GetMantenimientoInspeccion")
				.then( response => {
					this.inspecciones = response.data;
				})
				.catch( error => {
					console.log( error );
				})
		},
    mantenimientos_update_page_action() {
		this.loading = true;
    	let idsInspecciones = "";
		if(this.filtros.idsInspeccion.length > 0){
			this.filtros.idsInspeccion.forEach((ins)=>{
				idsInspecciones += `&IdsInspeccion=${ins}`;
			});
		}
		axios
		.get(`/Mantenimientos/EstadoInspeccionPorcentajes?${idsInspecciones}`,{
			params: {
				Completo: this.filtros.completo,
				Inicio: this.filtros.inicio,
				Fin: this.filtros.fin
			}
		})
		.then((response) => {
			this.inspeccionCiclos = response.data;
		})
		.catch(error => {
			console.log(error)
		})
		.finally( () => {
			this.loading = false;
		});
	},
    porcentajeInspecciones(item){
      var completados = 0;
      var total = 0;
      item.ciclos.forEach(ciclo => {
        completados += ciclo.inspecciones.filter(x => x.completo == true).length;
        total += ciclo.inspecciones.length;
      })
      if(total == 0) return 0.0;

      return parseFloat(((completados / total)*100.0).toFixed(2));
    },
    verInspecciones(item) {
			this.itemInspeccion = item.inspecciones;
			this.dialogInspeccion = true;
		},
    cerrarDialogInspeccion() {
			this.itemInspeccion = [];
			this.dialogInspeccion = false;
		},
    verComprobaciones(item) {
			this.itemComprobaciones = Object.assign({}, item);
			this.dialogComprobaciones = true;
		},
    cerrarDialogComprobaciones() {
			this.itemComprobaciones = Object.assign({},this.defaultItemComprobaciones);
			this.dialogComprobaciones = false;
		},
    getNombreMaquina() {
			return `[${this.itemComprobaciones.maquinaInstancia}] - ${this.itemComprobaciones.marca.trim()} ${this.itemComprobaciones.modelo.trim()}`;
		},
	},
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>