<template>
	<v-col cols="12" class="px-8" v-if="permisoVista('sensores','r')">
		<v-dialog v-model="dialogEditarClaves" max-width="25%" persistent>
			<v-card>
				<v-card-title>Agregar Traducción</v-card-title>
				<v-card-text>
					<v-text-field v-model="editItem.nombre"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="loadingTraduccion"
						@click="cancelarEditar"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="loadingTraduccion"
						:loading="loadingTraduccion"
						@click="agregarTraduccion"
						>Agregar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogDeleteConfig" max-width="35%" persistent>
			<v-card>
				<v-card-title>¿Está seguro de eliminar la gráfica?</v-card-title>
				<v-card-text>
					<p class="text-subtitle-1">Esta accion no se podrá revertir.</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="deletingConfig"
						@click="cancelarDialogDelete"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="deletingConfig"
						:loading="deletingConfig"
						@click="eliminarGrafica"
						>Eliminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogGuardarConfig" max-width="35%" persistent>
			<v-card>
				<v-card-title>Guardar Gráfica</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="saveItem.nombre"
						label="Nombre de la configuracion"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						:disabled="savingConfig"
						@click="cancelarDialogGuardar"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						:disabled="savingConfig"
						:loading="savingConfig"
						@click="guardarConfiguracion"
						>Guardar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col
				cols="4"
				md="4"
				sm="4"
				class="align-self-center"
				style="overflow: visible !important"
			>
				<VueCtkDateTimePicker
					id="inicio"
					v-model="filtros.inicio"
					label="Desde (Obligatorio)"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="cambioFiltros"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-center">
				<VueCtkDateTimePicker
					id="fin"
					v-model="filtros.fin"
					:min-date="filtros.inicio"
					label="Hasta (Opcional)"
					format="YYYY-MM-DD HH:mm"
					formatted="YYYY-MM-DD HH:mm"
					noHeader
					overlay
					buttonNowTranslation="Ahora"
					@input="cambioFiltros"
				/>
			</v-col>
			<v-col cols="4" md="4" sm="4" class="align-self-center">
				<v-autocomplete
					v-model="filtros.instancia"
					class="mt-2"
					label="Máquina (Obligatorio)"
					:items="instancias"
					:item-text="marcaModelo"
					item-value="idMaquinaIns"
					@change="cambioFiltros"
				>
					<template v-slot:item="data">
						<v-list-item-content>
							<v-list-item-title>{{
								data.item.marca.trim() +
								" - " +
								data.item.modelo.trim()
							}}</v-list-item-title>
							<v-list-item-subtitle>{{
								data.item.nombre.trim()
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row class="ma-2">
			<v-tabs v-model="tab" background-color="transparent" grow>
				<v-tab
					v-if="permisoVista('sensores','c')"
					@click="
						resetFiltrosConfig();
						getKeys();
					"
				>
					Nueva Gráfica
				</v-tab>
				<v-tab
					@click="
						resetFiltrosConfig();
						getConfiguraciones();
					"
				>
					Graficas Guardadas
				</v-tab>

				<v-tabs-items v-model="tab" touchless>
					<v-tab-item eager class="ma-2" v-if="permisoVista('sensores','c')">
						<v-row
							v-if="
								filtros.inicio !== null &&
								filtros.instancia !== null
							"
						>
							<!--
							<v-col
								cols="auto"
								v-for="(clave, idx) in keys"
								:key="idx"
							>
								<v-checkbox
									v-model="clavesSeleccionadas"
									:label="clave)"
									:value="clave"
									hide-details
									class="d-flex align-center mt-0"
									@change="getSeries()"
								>
									<template v-slot:append>
										<v-icon
											v-if="permisoVista('sensores','u')"
											small
											@click="editarClave(clave)"
											>mdi-pencil</v-icon
										>
									</template>
								</v-checkbox>
							</v-col>
							-->
							<v-col
								cols="6"
							>
								<v-autocomplete
            		  v-model="clavesSeleccionadas"
            		  :items="keys"
									label="Seleccione los Indicadores a Mostrar"
            		  chips
            		  multiple
									clearable
									deletable-chips
									hide-details
									:loading="loadingClaves"
									:disabled="loadingClaves"
									@change="getSeries()"
            		>
									<template v-slot:item="data">
										<v-checkbox
											:label="getTraduccion(data.item)"
											hide-details
											dense
											v-model="data.attrs.inputValue"
										>
										</v-checkbox>
										<v-spacer></v-spacer>
										<v-list-item-action @click.stop>
											<v-btn
												icon
												color="grey"
												@click.stop.prevent="editarClave(data.item)"
											>
												<v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
											</v-btn>
										</v-list-item-action>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="operation"
									label="Operación"
									:items="operations"
									@change="getSeries()"
								>
								</v-autocomplete>
							</v-col>
							<v-col cols="3">
								<v-autocomplete
									v-model="group"
									label="Agrupar Cada"
									:items="groups"
									@change="getSeries()"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row
							v-if="
								filtros.inicio !== null &&
								filtros.instancia !== null &&
								clavesSeleccionadas.length > 0
							"
							class="text-center"
						>
							<v-col :cols="numColumnas">
								<v-select
									v-model="tipoGrafico"
									:items="listadoGraficos"
									item-value="value"
									item-text="nombre"
									label="Tipo de Gráfico"
									hide-details
									@change="refrescarGrafica"
								></v-select>
							</v-col>
							<v-col v-if="showBtnGuardar" class="d-flex align-center justify-end">
								<v-btn
									color="primary"
									@click="showDialogGuardar"
									class="mr-4"
									>Guardar Gráfica</v-btn
								>
								<div class="d-flex flex-column align-center">
									<p class="mb-0 text--secondary" style="font-size: 16px !important">Auto-refrescar</p>
									<v-switch
										v-model="autorefresh"
										:label="autorefresh ? 'Activado' : 'Desactivado'"
										hide-details
										class="mt-0"
									></v-switch>
								</div>
							</v-col>
							<v-col cols="12" v-if="loadingSeries">
								<v-progress-linear
									indeterminate
									color="primary"
								></v-progress-linear>
							</v-col>
							<v-col cols="12">
								<apexchart
									v-if="chartLoaded"
									ref="chart"
									height="348"
									:options="chartOptions"
									:series="series"
								></apexchart>
							</v-col>
						</v-row>
						<v-row
							v-if="
								filtros.inicio == null ||
								filtros.instancia == null ||
								keys.length == 0
							"
						>
							<v-col cols="12">
								<v-alert color="blue-grey" text class="mb-0">
									<div
										v-if="
											filtros.inicio == null ||
											filtros.instancia == null
										"
										class="text-center"
										style="font-size: 14px"
									>
										Debe seleccionar una Fecha de Inicio y
										una Máquina
									</div>
									<div
										v-if="
											filtros.inicio !== null &&
											filtros.instancia !== null &&
											keys.length == 0
										"
										class="text-center"
										style="font-size: 14px"
									>
										No hay datos disponibles
									</div>
								</v-alert>
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item eager class="ma-2">
						<v-row
							v-if="
								filtros.inicio !== null &&
								filtros.instancia !== null &&
								listadoConfigs.length > 0
							"
							class="text-center"
						>
							<v-col
								:cols="
									(configSelected == null ||
									configSelected == '')
									? '12'
									: '8'
								"
							>
								<v-select
									v-model="configSelected"
									:items="listadoConfigs"
									item-value="idSensorDatoGrafica"
									item-text="nombre"
									label="Seleccionar configuración"
									@change="cargarConfiguracion"
									hide-details
								></v-select>
							</v-col>
							<v-col
								v-if="
									configSelected !== null &&
									configSelected != ''
								" 
								cols="2"
								class="px-0"
							>
								<v-btn
									v-if="permisoVista('sensores','d')"
									color="primary"
									@click="showDialogEliminar"
									>Borrar Gráfica</v-btn
								>
							</v-col>
							<v-col cols="2" class="align-self-center">
								<v-switch
									v-model="autorefresh"
									:label="autorefresh ? 'Auto-refrescar: Activado' : 'Auto-refrescar: Desactivado'"
									hide-details
									class="mt-0"
								></v-switch>
							</v-col>
						</v-row>
						<v-row
							v-if="
								filtros.inicio !== null &&
								filtros.instancia !== null &&
								configSelected !== null &&
								configSelected != '' &&
								chartLoaded
							"
						>
							<v-col
								cols="auto"
								v-for="(clave, idx) in clavesConfig"
								:key="idx"
							>
								<v-checkbox
									v-model="clavesSeleccionadas"
									:label="getTraduccion(clave)"
									:value="clave"
									hide-details
									class="d-flex align-center mt-0"
									readonly
									@change="getSeries()"
								>
									<template v-slot:append>
										<v-icon
											v-if="permisoVista('sensores','u')"
											small
											@click="editarClave(clave)"
											>mdi-pencil</v-icon
										>
									</template>
								</v-checkbox>
							</v-col>
						</v-row>
						<v-row
							v-if="
								filtros.inicio !== null &&
								filtros.instancia !== null &&
								clavesSeleccionadas.length > 0
							"
						>
							<v-col cols="12">
								<v-select
									v-model="tipoGrafico"
									:items="listadoGraficos"
									item-value="value"
									item-text="nombre"
									label="Tipo de Gráfico"
									@change="refrescarGrafica"
									readonly
									hide-details
								></v-select>
							</v-col>
							<v-col cols="12" v-if="loadingSeries">
								<v-progress-linear
									indeterminate
									color="primary"
								></v-progress-linear>
							</v-col>
							<v-col cols="12">
								<apexchart
									v-if="chartLoaded && series.length > 0"
									ref="chart"
									height="348"
									:options="chartOptions"
									:series="series"
								></apexchart>
							</v-col>
						</v-row>
						<v-row
							v-if="
								filtros.inicio == null ||
								filtros.instancia == null ||
								listadoConfigs.length == 0 ||
								(configSelected !== null &&
									configSelected !== '' &&
									series.length == 0)
							"
						>
							<v-col cols="12">
								<v-alert color="blue-grey" text class="mb-0">
									<div
										v-if="
											filtros.inicio == null ||
											filtros.instancia == null
										"
										class="text-center"
										style="font-size: 14px"
									>
										Debe seleccionar una Fecha de Inicio y
										una Máquina
									</div>
									<div
										v-if="
											filtros.inicio !== null &&
											filtros.instancia !== null &&
											listadoConfigs.length == 0
										"
										class="text-center"
										style="font-size: 14px"
									>
										No hay graficas guardadas
									</div>
									<div
										v-if="
											filtros.inicio !== null &&
											filtros.instancia !== null &&
											configSelected !== null &&
											configSelected !== '' &&
											series.length == 0
										"
										class="text-center"
										style="font-size: 14px"
									>
										No hay datos disponibles
									</div>
								</v-alert>
							</v-col>
						</v-row>
					</v-tab-item>
				</v-tabs-items>
			</v-tabs>
		</v-row>
	</v-col>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
	data() {
		return {
			loadingClaves: false,
			deletingConfig: false,
			deleteItem: {
				idSensorDatoGrafica: -1
			},
			defaultDeleteItem: {
				idSensorDatoGrafica: -1
			},
			dialogDeleteConfig: false,
			listadoConfigs: [],
			configSelected: null,
			savingConfig: false,
			saveItem: {
				nombre: "",
			},
			defaultSaveItem: {
				nombre: "",
			},
			dialogGuardarConfig: false,
			tab: 0,
			maquinas: [],
			instancias: [],
			loadingTraduccion: false,
			series: [],
			chartLoaded: false,
			listadoGraficos: [
				{
					nombre: "Gráfica Linear",
					value: "line",
				},
				{
					nombre: "Gráfica de Área",
					value: "area",
				},
				{
					nombre: "Gráfica de Barras",
					value: "bar",
				},
				{
					nombre: "Gráfica de Dispersión",
					value: "scatter",
				},
				{
					nombre: "Gráfica de Flancos",
					value: "stepline",
				},
			],
			dialogEditarClaves: false,
			editItem: {
				idGraficaTraduccion: -1,
				clave: "",
				nombre: "",
			},
			defaultEditItem: {
				idGraficaTraduccion: -1,
				clave: "",
				nombre: "",
			},
			filtros: {
				inicio: null,
				fin: null,
				instancia: null,
			},
			defaultFiltros: {
				inicio: null,
				fin: null,
				instancia: null,
			},
			keys: [],
			traducciones: [],
			clavesSeleccionadas: [],
			clavesConfig: [],
			tipoGrafico: "",
			columnConfig: {
				chart: {
					type: "bar",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
				fill: {
					opacity: 1,
				},
			},
			areaConfig: {
				chart: {
					type: "area",
					height: 350,
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
				yaxis: {
					opposite: true,
				},
				legend: {
					horizontalAlign: "left",
				},
			},
			lineConfig: {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
			},
			scatterConfig: {
				chart: {
					height: 350,
					type: "scatter",
					zoom: {
						enabled: true,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
				},
				yaxis: {
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
			},
			steplineConfig: {
				chart: {
					height: 350,
					type: "line",
					zoom: {
						enabled: true,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "stepline",
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					type: "datetime",
					labels: {
						datetimeUTC: false,
					},
				},
				tooltip: {
					x: {
						formatter: function (val) {
							return moment(val).format("YYYY-MM-DD HH:mm:ss");
						},
					},
				},
			},
			chartOptions: {},
			autorefresh: false,
			interval: null,
			loadingSeries: false,
			operation: 1,
			group: 3600,
			operations: [
				{
					value: 1,
					text: "Sumar"
				},
				{
					value: 2,
					text: "Promediar"
				}
			],
			groups: [
				{
					value: 1,
					text: "1 Segundo"
				},
				{
					value: 60,
					text: "1 Minuto"
				},
				{
					value: 120,
					text: "2 Minutos"
				},
				{
					value: 300,
					text: "5 Minutos"
				},
				{
					value: 600,
					text: "10 Minutos"
				},
				{
					value: 1800,
					text: "30 Minutos"
				},
				{
					value: 3600,
					text: "1 Hora"
				},
				{
					value: 43200,
					text: "12 Hora"
				},
				{
					value: 86400,
					text: "1 Día"
				}
			],
			editing: null,
      editingIndex: -1,
		};
	},
	computed: {
		series() {
			return this.series;
		},
		numColumnas() {
			return this.clavesSeleccionadas.length > 0 && this.tipoGrafico != ""
				? "8"
				: "12";
		},
		showBtnGuardar() {
			return (
				this.clavesSeleccionadas.length > 0 && this.tipoGrafico != ""
			);
		},
	},
	mounted() {
		this.initialize();
		this.filtros = Object.assign({}, this.defaultFiltros);
		this.interval = setInterval(() => {
			if(this.autorefresh) this.getSeries();
		},10000);
	},
	beforeDestroy(){
    if(this.interval != null) clearInterval(this.interval);
  },
	methods: {
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		cambioFiltros() {
			this.getKeys();
			if (this.configSelected !== null && this.configSelected !== "") {
				this.getConfiguraciones();
				this.cargarConfiguracion();
			}
		},
		resetFiltrosConfig() {
			this.saveItem = Object.assign({}, this.defaultSaveItem);
			this.configSelected = "";
			this.tipoGrafico = "";
			this.clavesSeleccionadas = [];
			this.chartOptions = {};
			this.chartLoaded = false;
		},
		eliminarGrafica(){
			this.deletingConfig = true;
			axios
				.put( `/SensorDatos/EliminarGrafica/${this.deleteItem.idSensorDatoGrafica}` )
				.then( () => {
					this.deletingConfig = false;
					this.cancelarDialogDelete();
					this.getConfiguraciones();
					this.resetFiltrosConfig();
				})
				.catch( error => {
					this.deletingConfig = false;
					console.log( error );
				})	
		},
		guardarConfiguracion() {
			const config = {
				nombre: this.saveItem.nombre,
				keys: this.clavesSeleccionadas,
				tipo: this.tipoGrafico,
				operacion: this.operation,
				agrupar: this.group
			};

			axios
				.post("/SensorDatos/CrearGrafica", config)
				.then(() => {
					this.cancelarDialogGuardar();
					this.getConfiguraciones();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cancelarDialogDelete() {
			this.deleteItem = Object.assign({}, this.defaultDeleteItem);
			this.dialogDeleteConfig = false;
		},
		cancelarDialogGuardar() {
			this.saveItem = Object.assign({}, this.defaultSaveItem);
			this.dialogGuardarConfig = false;
		},
		showDialogGuardar() {
			this.saveItem = Object.assign({}, this.defaultSaveItem);
			this.dialogGuardarConfig = true;
		},
		showDialogEliminar() {
			this.deleteItem.idSensorDatoGrafica = this.configSelected;
			this.dialogDeleteConfig = true;
		},
		initialize() {
			this.getTraducciones();
			this.getMaquinas();
			this.getInstancias();
			this.getConfiguraciones();
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((el) => el.esMaquina == true);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getSeries() {
			this.loadingSeries = true;
			let keys = "";
			this.clavesSeleccionadas.forEach((clave, index) => {
				keys += `${(index == 0) ? "?" : "&"}keys=${clave}`;
			});
			axios
				.get(`/SensorDatos/Series${keys}`, {
					params: {
						inicio: this.filtros.inicio,
						fin: this.filtros.fin,
						idMaquinaIns: this.filtros.instancia,
						operacion: this.operation,
						agrupar: this.group
					},
				})
				.then((response) => {
					this.series = response.data;
					this.loadingSeries = false;
				})
				.catch((error) => {
					console.log(error);
					this.loadingSeries = false;
				});
		},
		getTraducciones() {
			axios
				.get("/SensorDatos/GetGraficaTraduccion")
				.then((response) => {
					this.traducciones = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getKeys() {
			if (
				this.filtros.inicio !== null &&
				this.filtros.instancia !== null
			) {
				this.loadingClaves = true;
				axios
					.get("/SensorDatos/Keys", {
						params: {
							inicio: this.filtros.inicio,
							fin: this.filtros.fin,
							idMaquinaIns: this.filtros.instancia,
						},
					})
					.then((response) => {
						this.keys = response.data;
						this.loadingClaves = false;
						if (
							this.configSelected == "" ||
							this.configSelected == null
						)
							this.clavesSeleccionadas = [];
					})
					.catch((error) => {
						this.loadingClaves = false;
						console.log(error);
					});
			}
		},
		getConfiguraciones() {
			axios
				.get("/SensorDatos/GetGraficas")
				.then((response) => {
					this.listadoConfigs = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		cargarConfiguracion() {
			this.chartLoaded = false;

			const busq = this.listadoConfigs.find(
				(config) => config.idSensorDatoGrafica == this.configSelected
			);

			this.clavesConfig = busq.keys;
			this.clavesSeleccionadas = busq.keys;
			this.tipoGrafico = busq.tipo;
			this.group = busq.agrupar;
			this.operation = busq.operacion;

			if (this.tipoGrafico == "line") {
				this.chartOptions = this.lineConfig;
			}
			if (this.tipoGrafico == "area") {
				this.chartOptions = this.areaConfig;
			}
			if (this.tipoGrafico == "bar") {
				this.chartOptions = this.columnConfig;
			}
			if (this.tipoGrafico == "scatter") {
				this.chartOptions = this.scatterConfig;
			}
			if(this.tipoGrafico == "stepline") {
				this.chartOptions = this.steplineConfig;
			}

			if (
				this.filtros.inicio !== null &&
				this.filtros.instancia !== null
			) {
				this.getSeries();
			}

			this.$nextTick(() => {
				this.chartLoaded = true;
			});
		},
		refrescarGrafica() {
			this.chartLoaded = false;

			if (this.tipoGrafico == "line") {
				this.chartOptions = this.lineConfig;
			}
			if (this.tipoGrafico == "area") {
				this.chartOptions = this.areaConfig;
			}
			if (this.tipoGrafico == "bar") {
				this.chartOptions = this.columnConfig;
			}
			if (this.tipoGrafico == "scatter") {
				this.chartOptions = this.scatterConfig;
			}
			if(this.tipoGrafico == "stepline") {
				this.chartOptions = this.steplineConfig;
			}

			this.$nextTick(() => {
				this.chartLoaded = true;
			});
		},
		agregarTraduccion() {
			this.loadingTraduccion = true;
			const obj = {
				clave: this.editItem.clave,
				nombre: this.editItem.nombre,
			};
			if (this.editItem.idGraficaTraduccion == -1) {
				axios
					.post("/SensorDatos/CrearGraficaTraduccion", obj)
					.then(() => {
						this.loadingTraduccion = false;
						this.cancelarEditar();
						this.getTraducciones();
					})
					.catch((error) => {
						this.loadingTraduccion = false;
						console.log(error);
					});
			} else {
				axios
					.put(
						`/SensorDatos/ActualizarGraficaTraduccion/${this.editItem.idGraficaTraduccion}`,
						obj
					)
					.then(() => {
						this.loadingTraduccion = false;
						this.cancelarEditar();
						this.getTraducciones();
					})
					.catch((error) => {
						this.loadingTraduccion = false;
						console.log(error);
					});
			}
		},
		cancelarEditar() {
			this.editItem = Object.assign({}, this.defaultEditItem);
			this.dialogEditarClaves = false;
		},
		editarClave(clave) {
			const search = this.traducciones.find(
				(nombre) => nombre.clave == clave
			);
			if (search !== undefined) {
				this.editItem = Object.assign({}, search);
			} else {
				this.editItem.clave = clave;
				this.editItem.nombre = clave;
			}
			this.dialogEditarClaves = true;
		},
		getTraduccion(clave) {
			const busqueda = this.traducciones.find(
				(traduccion) => traduccion.clave == clave.toLowerCase()
			);
			return busqueda !== undefined ? busqueda.nombre : clave;
		},
	}
};
</script>