<template>
  <v-dialog
    v-model="value"
    fullscreen
    hide-overlay
  >
    <v-card id="projection">
        <v-btn
          color="blue-grey lighten-2"
          fab
          dark
          bottom
          left
          class="v-btn--close"
          @click="close"
          style="position: absolute; z-index: 100;"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      <v-card-text>
        <v-tabs-items v-model="tab">

          <v-tab-item
            value="tab-1"
          >
            <ReporteRendimientoOperador
              :update="tiempoActualizacion"
            >
            </ReporteRendimientoOperador>
          </v-tab-item>

          <v-tab-item
            value="tab-2"
          >
            <ReportePiezasTerminadas
              :update="tiempoActualizacion"
              ref="piezasterminadas"
            >
            </ReportePiezasTerminadas>
            
          </v-tab-item>

          <v-tab-item
            value="tab-3"
          >
            <div class="text-center text-h3">
              Reporte de Avance y Tiempo de Entrega
            </div>
            <ReporteAvanceTiempoEntrega
              :update="tiempoActualizacion"
              :pageInterval="5"
            >
            </ReporteAvanceTiempoEntrega>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ReportePiezasTerminadas from "./Proyeccion/ReportePiezasTerminadas.vue"
import ReporteRendimientoOperador from "./Proyeccion/ReporteRendimientoOperador.vue"
import ReporteAvanceTiempoEntrega from "./Proyeccion/ReporteAvanceTiempoEntrega.vue"


export default {
  components:{
    ReportePiezasTerminadas,
    ReporteRendimientoOperador,
    ReporteAvanceTiempoEntrega
  },
  props:{
    value:{
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    tiempoActualizacion: 1, //minutos
  }),
  watch: {
    pos(){
      if(this.pos == 2){
        if(typeof this.$refs.piezasterminadas === 'undefined') return;
        this.$refs.piezasterminadas.initialize();
      }
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      if(this.pos == 3) this.pos = 1;
      else this.pos += 1;
    },30 * 1000);//100000);
  },
  destroyed(){
    clearInterval(this.interval);
  },
  computed:{
    tab(){
      return `tab-${this.pos}`;
    },
  },
  data: () => ({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    interval: null,
    pos: 1,
  }),
  methods:{
    close(){
      this.$emit('input',false);
    }
  }
}
</script>