import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{class:{ 'shake-horizontal': !!_vm.item.animar },attrs:{"elevation":"3"}},[_c(VCardText,{class:_vm.computedBorderColor},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"pa-0 text-left",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-0 text-body-2",class:{
							'blink-2': !!_vm.item.animarTexto,
							'red--text': !!_vm.item.animarTexto,
							'text--primary': !!!_vm.item.animarTexto
						}},[_c('span',{staticClass:"text-body-2 font-weight-bold",class:{
								'blink-2': !!_vm.item.animarTexto,
								'red--text': !!_vm.item.animarTexto,
								'text--primary': !!!_vm.item.animarTexto
							}},[_vm._v(_vm._s(_vm.computedTipoText)+":")]),_vm._v(" "+_vm._s(_vm.getNombreTarea)+" ")]),_c('p',{staticClass:"mb-0 text-body-2 text--primary"},[_c('span',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v("Máquina:")]),_vm._v(" "+_vm._s(_vm.item.maquinaIns)+" ")]),_c('p',{staticClass:"mb-0 text-body-2 text--primary"},[_c('span',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v("Subdepartamento:")]),_vm._v(" "+_vm._s(_vm.item.nombreSubdepartamento || "N/D")+" ")]),_c('p',{staticClass:"mb-0 text-body-2 text--primary"},[_c('span',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v("Inicio:")]),_vm._v(" "+_vm._s(_vm.item.inicio)+" ")]),_c('p',{staticClass:"mb-0 text-body-2 text--primary"},[_c('span',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v("Duración:")]),_vm._v(" "+_vm._s(_vm.item.duracion)+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }