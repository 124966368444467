<template>
	<v-col cols="12" v-if="permisoVista('mantenimiento-inspecciones','r')">
		<v-dialog v-model="dialogTerminar" persistent max-width="60%">
			<v-card>
				<v-card-title>Terminar Inspección</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...terminarItem}]"
							/>
						</v-col>
					</v-row>
					<v-row v-if="esIndasa">
						<v-col
							v-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'transporte',terminarItem.idMaquina)"
							cols="12"
						>
							<KilometrajeTextfield
								v-if="dialogTerminar"
								v-model="terminarItem.kilometraje"
								:kilometrajeMin.sync="terminarItem.kilometrajeMin"
								:idMaquinaInstancia="terminarItem.idMaquinaInstancia"
								:error-messages.sync="erroresItem.errorKilometraje"
								:loadingKilometrajeMin.sync="loadingKilometrajeMin"
								:disabled="loadingFinish"
							/>
						</v-col>
						<v-col
							v-else-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'montacargas',terminarItem.idMaquina)"
							cols="12"
						>
							<v-text-field
								v-model="terminarItem.horasTrabajadas"
								label="Horas Trabajadas"
								type="number"
								hide-spin-buttons
								clearable
								:disabled="loadingFinish"
								:error-messages="erroresItem.errorHorasTrabajadas"
								@focus="erroresItem.errorHorasTrabajadas = null"
							></v-text-field>
						</v-col>
					</v-row>
					<Comprobaciones
						v-if="dialogTerminar"
						ref="checklist"
						class="mb-4"
						:tipoReporte="3"
						:disabled="loadingFinish"
						:idMaquina="terminarItem.idMaquina"
						:idMantenimientoInspeccion="terminarItem.idMantenimientoInspeccion"
						:loadingComprobaciones.sync="loadingComprobaciones"
					/>
					<v-row>
						<v-col cols="12">
							<v-autocomplete-textarea
								v-if="dialogTerminar"
								v-model="terminarItem.recomendacion"
								label="Descripción o Recomendación"
								outlined
								auto-grow
								rows="1"
								:items="recomendaciones"
								:loading="loadingRecomendaciones"
								:disabled="loadingFinish"
								:error-message="erroresItem.errorRecomendacion"
								@click:refresh="getRecomendaciones"
								@update:error-message="erroresItem.errorRecomendacion = $event"
							></v-autocomplete-textarea>
						</v-col>
					</v-row>
					<v-slide-y-transition>
						<v-row v-if="errores.length > 0">
							<v-col cols="12">
								<ul class="mt-3">
									<li class="red--text" v-for="ex in errores" :key="ex">
										{{ ex }}
									</li>
								</ul>
							</v-col>
						</v-row>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="loadingFinish || loadingComprobaciones"
						small
						@click="cerrarDialogTerminar"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						small
						:disabled="loadingFinish || loadingComprobaciones"
						:loading="loadingFinish"
						@click="terminarTarea"
						>Terminar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="headers"
			:items="inspecciones"
			:loading="loading"
			loading-text="Cargando... Espere, Por Favor."
			:footer-props="{
				disableItemsPerPage: true,
				disablePagination: this.loading,
			}"
			:disable-sort="true"
		>
			<template v-slot:[`item.tipoAsignacion`]="{ item }">
				{{ $utils.mantenimientos.getNombreTipoAsignacion(item.tipoAsignacion) }}
			</template>
			<template v-slot:[`item.realizadoPor`]="{ item }">
				<template v-if="item.tipoAsignacion == 1">
					<ul>
						<li
							v-for="(mecanico, idx) in item.mecanicos"
							:key="'mec-' + idx"
						>
							{{ mecanico }}
						</li>
					</ul>
				</template>
				<template v-else>
					{{ $utils.mantenimientos.findNombreProveedor(item.idMantenimientoProveedor,proveedores) }}
				</template>
			</template>
			<template v-slot:[`item.inicioCronometro`]="{ item }">
				<chronometer :time="item.inicioCronometro"></chronometer>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('mantenimiento-inspecciones','u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							@click="terminarMantenimiento(item)"
						>
							stop
						</v-icon>
					</template>
					<span class="white--text">Terminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import chronometer from "@/components/Chronometer.vue";
import Comprobaciones from '../Comprobaciones.vue';
import HeaderInfoMantenimiento from '../Catalogos/HeaderInfoMantenimiento.vue';
import KilometrajeTextfield from '../KilometrajeTextfield.vue';
import { mapState } from 'vuex';

export default {
	components: {
		chronometer,
		Comprobaciones,
		HeaderInfoMantenimiento,
		KilometrajeTextfield,
	},
	data() {
		return {
			loadingRecomendaciones: false,
			recomendaciones: [],
			loadingKilometrajeMin: false,
			erroresItem: {
				errorKilometraje: null,
				errorHorasTrabajadas: null,
				errorRecomendacion: null
			},
			maquinas: [],
			loadingComprobaciones: false,
			loading: false,
            terminarItem: null,
            defaultTerminarItem: {
                idMantenimientoCalendarioInspeccion: 0,
				kilometraje: null,
				kilometrajeMin: null,
				idMaquina: null,
				idMaquinaInstancia: null,
				horasTrabajadas: null,
				recomendacion: null,
            },
			errores: [],
			recomendacion: "",
			inspecciones: [],
			headers: [
				{
					text: "Código",
					align: "center",
					sort: false,
					value: "codigo",
				},
				{
					text: "Nombre",
					align: "center",
					sort: false,
					value: "nombre",
				},
				{
					text: "Categoria Mantenimiento",
					align: "center",
					sort: false,
					value: "categoriaMantenimiento",
				},
				{
					text: "Responsable",
					align: "center",
					sort: false,
					value: "responsable",
				},
				{
					text: "Tipo Asignación",
					align: "center",
					sort: false,
					value: "tipoAsignacion",
				},
				{
					text: "Realizado Por",
					align: "center",
					sort: false,
					value: "realizadoPor",
				},
				{
					text: "Categoria Maquina",
					align: "center",
					sort: false,
					value: "categoriaMaquina",
				},
				{
					text: "Modelo",
					align: "center",
					sort: false,
					value: "maquina",
				},
				{
					text: "Máquina",
					align: "center",
					sort: false,
					value: "maquinaIns",
				},
				{
					text: "Inicio",
					align: "center",
					sort: false,
					value: "inicio",
				},
                {
					text: "Tiempo Transcurrido",
					align: "center",
					sort: false,
					value: "inicioCronometro",
				},
				{
					text: "Acciones",
					align: "center",
					sort: false,
					value: "actions",
				},
			],
			dialogTerminar: false,
			loadingFinish: false,
		};
	},
	computed: {
		...mapState(["nombreEmpresa"]),
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
	},
	beforeMount(){
		this.terminarItem = structuredClone(this.defaultTerminarItem);
	},
	mounted() {
		this.initialize();
	},
	methods: {
		resetErrores(){
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
			this.errores = [];
		},
		cerrarDialogTerminar(){
			this.dialogTerminar = false;
			setTimeout(() => {
				this.resetErrores();
				this.terminarItem = structuredClone(this.defaultTerminarItem);
			}, 250);
		},
		validar(){
			this.resetErrores();

			if(this.esIndasa){
				if (this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.terminarItem.idMaquina)) {
					this.erroresItem.errorKilometraje = this.$utils.mantenimientos.validateKilometraje(this.terminarItem);
				}
	
				if(this.terminarItem.horasTrabajadas != null){
					const parsedHorasTrabajadas = parseFloat(this.terminarItem.horasTrabajadas);
					if(isNaN(parsedHorasTrabajadas)) this.erroresItem.errorHorasTrabajadas = "Invalido";
					else if (parsedHorasTrabajadas < 0) this.erroresItem.errorHorasTrabajadas = "Debe ser mayor o igual a 0";
				}

				if(!this.terminarItem.recomendacion) this.erroresItem.errorRecomendacion = "Requerido"
			}

			const comprobacionesValidas = this.$refs.checklist.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;

			if(hayErrores){
				this.errores = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}
			
			return !hayErrores;
		},
		terminarTarea(){
			if ( !this.validar() ) return;
			const item = this.$refs.checklist.terminarTarea();

            const obj = {
                idMantenimientoCalendarioInspeccion: this.terminarItem.idMantenimientoCalendarioInspeccion,
                comprobaciones: item.comprobaciones,
				recomendacion: this.terminarItem.recomendacion,
				kilometraje: parseFloat(this.terminarItem.kilometraje) || null,
				horasTrabajadas: parseFloat(this.terminarItem.horasTrabajadas) || null,
            };

			this.loadingFinish = true;
			axios
				.put("/Mantenimientos/TerminarInspeccion", obj)
				.then(() => {
					this.loadingFinish = false;
					this.cerrarDialogTerminar();
					this.initialize();
				})
				.catch((error) => {
					this.loadingFinish = false;
					console.log(error);
				});
		},
		initialize() {
			this.getMantenimientos();
			this.getProveedoresMantenimiento();
			this.getMaquinas();
			this.getRecomendaciones();
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter((maquina) => maquina.esMaquina);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getProveedoresMantenimiento(){
			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch( (error) => {
					this.proveedores = [];
					console.log(error);
				});
		},
		getMantenimientos() {
			this.loading = true;

			axios
				.get("/Mantenimientos/GetInspeccionesEnCurso")
				.then((response) => {
					this.inspecciones = response.data;
				})
				.catch((error) => {
					console.log(error);
				})
				.finally( () => {
					this.loading = false;
				});
		},
		terminarMantenimiento(item) {
			this.terminarItem = structuredClone(item);
			this.terminarItem.nombreCategoria = item.categoriaMantenimiento;
			this.dialogTerminar = true;
		},
	},
};
</script>