<template>
	<v-col cols="12">
		<v-dialog v-model="dialog" max-width="800px" persistent>
			<v-card>
				<v-card-title v-if="editedItem.idMantenimientoCorrectivo == null"
					>Agregar Mantenimiento Correctivo</v-card-title
				>
				<v-card-title v-else
					>{{ tipoEdicion == 1 ? 'Editar' : 'Clonar' }} Mantenimiento Correctivo</v-card-title
				>
				<v-card-text>
					<v-row>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.codigo"
								label="Código"
								:error-messages="erroresItem.errorCodigo"
								@focus="erroresItem.errorCodigo = null"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-text-field
								v-model="editedItem.nombre"
								label="Nombre"
								:error-messages="erroresItem.errorNombre"
								@focus="erroresItem.errorNombre = null"
							></v-text-field>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-textarea
								v-model="editedItem.descripcion"
								label="Descripción"
								hide-details
								auto-grow
								rows="1"
							></v-textarea>
						</v-col>
						<v-col cols="6">
							<v-autocomplete
								v-model="editedItem.idMantenimientoCategoria"
								label="Categoria del Mantenimiento"
								:items="categorias"
								item-text="nombre"
								item-value="idMantenimientoCategoria"
								clearable
								:disabled="loadingCategorias"
								:loading="loadingCategorias"
								:error-messages="erroresItem.errorCategoria"
								@focus="erroresItem.errorCategoria = null"
							></v-autocomplete>
						</v-col>
						<v-col cols="6" md="6" sm="6">
							<v-autocomplete
								v-model="editedItem.idMaquina"
								label="Modelo"
								:items="maquinas"
								:item-text="marcaModelo"
								item-value="idMaquina"
								:menu-props="{
									maxWidth: 'min-content'
								}"
								:error-messages="erroresItem.errorModelo"
								@focus="erroresItem.errorModelo = null"
								@change="resetMaquinaIns"
							>
								<template v-slot:item="data">
									<v-list-item-content>
										<v-list-item-title>{{data.item.marca+' - '+data.item.modelo}}</v-list-item-title>
										<v-list-item-subtitle>{{data.item.categoria}}</v-list-item-subtitle>
										<v-list-item-subtitle v-if="data.item.descripcionGeneral!=null">{{$utils.strings.truncate(data.item.descripcionGeneral,50)}}</v-list-item-subtitle>
									</v-list-item-content>
								</template>
							</v-autocomplete>
						</v-col>
						<v-col cols="6" v-if="showSubdepto">
							<v-autocomplete
								v-model="editedItem.idSubdepartamento"
								label="Subdepartamento"
								:items="subdepartamentos"
								:item-text="nombreSubdepto"
								item-value="idSubdepartamento"
								clearable
								:error-messages="erroresItem.errorSubdepto"
								@focus="erroresItem.errorSubdepto = null"
							></v-autocomplete>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="3">
							<p class="text-center mb-0">Tipo de asignación</p>
							<v-radio-group
								v-model="editedItem.tipoAsignacion"
								column
								class="mt-0"
								hide-details
								@change="onChangeTipoAsignacion"
							>
								<v-radio
									label="Personas"
									class="mb-0 text-center"
									:value="1"
								></v-radio>
								<v-radio
									label="Proveedor"
									:value="2"
								></v-radio>
							</v-radio-group>
						</v-col>
						<template v-if="editedItem.tipoAsignacion == 1">
							<v-col cols="3">
								<p class="text-center">Personas requeridas</p>
								<div class="d-flex align-center justify-center">
									<number-input
										v-model="editedItem.numPersonas"
										controls
										inline
										size="small"
										:min="1"
										center
									></number-input>
								</div>
							</v-col>
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.asignados"
									label="[Opcional] Asignar por defecto a"
									:items="personas"
									:item-text="nombrePersona"
									item-value="idPersona"
									multiple
									chips
									deletable-chips
									clearable
									:error-messages="erroresItem.errorAsignados"
									@focus="erroresItem.errorAsignados = null"
								></v-autocomplete>
							</v-col>
						</template>
						<template v-else-if="editedItem.tipoAsignacion == 2">
							<v-col cols="6">
								<v-autocomplete
									v-model="editedItem.idMantenimientoProveedor"
									label="[Opcional] Proveedor por defecto"
									:items="proveedores"
									:item-text="$utils.mantenimientos.getNombreProveedor"
									item-value="idMantenimientoProveedor"
									clearable
									hide-details
								></v-autocomplete>
							</v-col>
						</template>
					</v-row>
					<ListadoPorHacer class="mt-10" ref="todo" :item="editedItem" />
					<v-slide-y-transition>
						<v-row v-if="errores.length > 0"  class="pt-4">
							<ul>
								<li
									class="red--text"
									v-for="(error, idx) in errores"
									:key="'error' + idx"
								>
									{{ error }}
								</li>
							</ul>
						</v-row>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						:disabled="guardando"
						@click="closeMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red"
						text
						:loading="guardando"
						:disabled="guardando"
						@click="guardarMantenimiento"
						>{{ textButtonSave }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogEliminar" max-width="800px">
			<v-card>
				<v-card-title>
					<v-card-title class="headline"
						>¿Está seguro que desea eliminar este
						mantenimiento?</v-card-title
					>
					<v-card-text
						>Esta acción no se puede revertir y será
						permanente.</v-card-text
					>
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="green"
						text
						@click="closeEliminarMantenimiento"
						>Cancelar</v-btn
					>
					<v-btn
						color="red darken-1"
						text
						@click="runRemoverMantenimiento"
						:disabled="deleting"
						:loading="deleting"
					>
						Eliminar
						<template v-slot:loader>
							<v-progress-circular
								indeterminate
								:width="2"
								:size="24"
								color="red"
							></v-progress-circular>
						</template>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTarea" max-width="55%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte Extemporaneo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								:disabled="savingReported"
								label="Responsable"
								:error-messages="erroresItem.errorResponsable"
								@focus="erroresItem.errorResponsable = null"
							>
							</v-autocomplete>
						</v-col>
						<AsignacionMantenimiento
							:tipoAsignacion.sync="reportedItem.tipoAsignacion"
							:mecanicos.sync="reportedItem.mecanicos"
							:idMantenimientoProveedor.sync="reportedItem.idMantenimientoProveedor"
							:error-mecanicos.sync="erroresItem.errorMecanicos"
							:error-proveedor.sync="erroresItem.errorProveedor"
							:disabled="savingReported"
							:items="{personas, proveedores}"
						/>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="savingReported || loadingKilometrajeMin"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
								@change="onChangeMaquinaIns"
							/>
						</v-col>
						<template v-if="esIndasa">
							<v-col :cols="computeColImporteNeto">
								<v-text-field
									v-model="reportedItem.importeNeto"
									label="Importe Neto"
									type="number"
									hide-spin-buttons
									prefix="$"
									clearable
									:error-messages="erroresItem.errorImporteNeto"
									@focus="erroresItem.errorImporteNeto = null"
								></v-text-field>
							</v-col>
							<v-col
								v-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'transporte',reportedItem.idMaquina)"
								cols="6"
							>
								<KilometrajeTextfield
									ref="kilometrajeTextfield"
									v-model="reportedItem.kilometraje"
									:kilometrajeMin.sync="reportedItem.kilometrajeMin"
									:idMaquinaInstancia="reportedItem.instancia"
									:error-messages.sync="erroresItem.errorKilometraje"
									:loadingKilometrajeMin.sync="loadingKilometrajeMin"
									:disabled="savingReported"
								/>
							</v-col>
							<v-col v-else-if="$utils.mantenimientos.esCategoriaEspecifica(maquinas,'montacargas',reportedItem.idMaquina)" cols="6">
								<v-text-field
									v-model="reportedItem.horasTrabajadas"
									label="Horas Trabajadas"
									type="number"
									hide-spin-buttons
									clearable
									:error-messages="erroresItem.errorHorasTrabajadas"
									@focus="erroresItem.errorHorasTrabajadas = null"
								></v-text-field>
							</v-col>
						</template>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.inicio"
								label="Inicio"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:disabled="savingReported"
								buttonNowTranslation="Ahora"
								@input="erroresItem.errorFechaInicio = null"
							/>
							<v-slide-y-transition>
								<p
									v-if="erroresItem.errorFechaInicio"
									class="mt-1 ml-1 mb-0 error--text"
									style="font-size: 12px !important"
								>
									{{erroresItem.errorFechaInicio}}
								</p>
							</v-slide-y-transition>
						</v-col>
						<v-col cols="12" md="12" sm="12">
							<VueCtkDateTimePicker
								v-model="reportedItem.fin"
								:min-date="reportedItem.inicio"
								label="Termino"
								format="YYYY-MM-DD HH:mm"
								formatted="YYYY-MM-DD HH:mm"
								noHeader
								overlay
								:disabled="savingReported"
								buttonNowTranslation="Ahora"
								@input="erroresItem.errorFechaFin = null"
							/>
							<v-slide-y-transition>
								<p
									v-if="erroresItem.errorFechaFin"
									class="mt-1 ml-1 mb-0 error--text"
									style="font-size: 12px !important"
								>
									{{erroresItem.errorFechaFin}}
								</p>
							</v-slide-y-transition>
						</v-col>
					</v-row>
					<v-row>
						<Comprobaciones
							v-if="dialogTarea"
							ref="checklist"
							:disabled="savingReported"
							:idMantenimientoCorrectivo="reportedItem.idMantenimientoCorrectivo"
							:loadingComprobaciones.sync="loadingComprobaciones"
						/>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete-textarea
								v-if="dialogTarea"
                                v-model="reportedItem.recomendacion"
                                label="Descripción o Recomendación"
                                outlined
                                auto-grow
                                rows="1"
								:items="recomendaciones"
								:loading="loadingRecomendaciones"
								:disabled="savingReported"
								:error-message="erroresItem.errorRecomendacion"
								@click:refresh="getRecomendaciones"
								@update:error-message="erroresItem.errorRecomendacion = $event"
                            ></v-autocomplete-textarea>
						</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul class="mt-3" v-if="erroresReported.length > 0">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported || loadingComprobaciones || loadingKilometrajeMin"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeReported"
						:disabled="savingReported || loadingComprobaciones || loadingKilometrajeMin"
						:loading="savingReported"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogTarea2" max-width="50%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Reporte En Tiempo Real
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<v-autocomplete
								v-model="reportedItem.responsable"
								:items="personas"
								item-value="idPersona"
								:item-text="nombrePersona"
								label="Responsable"
								:disabled="savingReported"
								:error-messages="erroresItem.errorResponsable"
								@focus="erroresItem.errorResponsable = null"
							></v-autocomplete>
						</v-col>
						<AsignacionMantenimiento
							:tipoAsignacion.sync="reportedItem.tipoAsignacion"
							:mecanicos.sync="reportedItem.mecanicos"
							:idMantenimientoProveedor.sync="reportedItem.idMantenimientoProveedor"
							:error-mecanicos.sync="erroresItem.errorMecanicos"
							:error-proveedor.sync="erroresItem.errorProveedor"
							:disabled="savingReported"
							:items="{personas, proveedores}"
						/>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="savingReported"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
							/>
						</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul v-if="erroresReported.length > 0" class="mt-3">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeReported"
						:disabled="savingReported"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="completeReported2"
						:disabled="savingReported"
						:loading="savingReported"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogProgramar" max-width="45%" persistent>
			<v-card>
				<v-card-title class="pa-4 pb-4 pt-5">
					Programar Mantenimiento Correctivo
				</v-card-title>
				<v-card-text style="min-height: 300px">
					<v-row>
						<v-col cols="12">
							<HeaderInfoMantenimiento
								:maquinas="maquinas"
								:items="[{...reportedItem}]"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="12" sm="12">
							<MaquinaInsAutocomplete
								v-model="reportedItem.instancia"
								label="Máquina"
								:items="computedIns"
								item-text="nombre"
								item-value="idMaquinaIns"
								:disabled="savingProgrammed"
								:error-message="erroresItem.errorInstancia"
								@focus="erroresItem.errorInstancia = null"
							/>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6" sm="6" class="my-3">
							<MenuDatepicker
								v-model="reportedItem.fechaInicioPlaneado"
								label="Fecha de Inicio planeado"
								clearable
								:disabled="savingProgrammed"
								:errorMessage="erroresItem.errorFechaInicio"
								@focus="erroresItem.errorFechaInicio = null"
							/>
						</v-col>
						<v-col cols="6" md="6" sm="6" class="my-3">
							<v-autocomplete
								v-model="reportedItem.tiempoEstimado"
								label="Tiempo estimado"
								:items="bloques"
								:disabled="savingProgrammed"
								:error-messages="erroresItem.errorTiempoEstimado"
								@focus="erroresItem.errorTiempoEstimado = null"
							></v-autocomplete>
      					</v-col>
					</v-row>
					<v-slide-y-transition>
						<ul class="mt-3" v-if="erroresReported.length > 0">
							<li
								class="red--text"
								v-for="(error, idx) in erroresReported"
								:key="'error-' + idx"
							>
								{{ error }}
							</li>
						</ul>
					</v-slide-y-transition>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						text
						color="green"
						@click="closeProgrammed()"
						:disabled="savingProgrammed"
						>Cancelar</v-btn
					>
					<v-btn
						text
						color="red"
						@click="programarMantenimiento"
						:disabled="savingProgrammed"
						:loading="savingProgrammed"
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Recomendaciones ref="recomen" />
		<v-row class="justify-space-between">
			<v-col cols="4">
				<v-btn
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					color="primary"
					:disabled="loading"
					@click="dialog = true"
					>Agregar Mantenimiento Correctivo</v-btn
				>
			</v-col>
			<v-col cols="4">
				<v-text-field
					v-model="search"
					label="Buscar"
					prepend-inner-icon="mdi-magnify"
					clearable
					:disabled="loading"
				></v-text-field>
			</v-col>
		</v-row>
		<v-data-table
			:items="horarios"
			:headers="headersHorario"
			:loading="loading"
			:search="search"
			loading-text="Cargando... Espere, Por Favor."
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							class="mr-1"
							:disabled="loading"
							@click="editMantenimiento(item,2)"
						>
							mdi-content-duplicate
						</v-icon>
					</template>
					<span class="white--text">Clonar</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" :disabled="loading" class="mr-1" small @click="showProgramar(item)">
							mdi-clock
						</v-icon>
					</template>
					<span class="white--text">Programar Mantenimiento</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" class="mr-1" :disabled="loading" small @click="reportarTarea(item)">
							mdi-clipboard-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Extemporaneo</span>
				</v-tooltip>
								<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'c')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" class="mr-1" :disabled="loading" small @click="reportarTarea2(item)">
							mdi-script-text-play
						</v-icon>
					</template>
					<span class="white--text">Reporte Tiempo Real</span>
				</v-tooltip>
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'u')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="editMantenimiento(item)"
						>
							edit
						</v-icon>
					</template>
					<span class="white--text">Editar</span>
				</v-tooltip>
				<!-- <v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'r')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							:disabled="
								item.recomendaciones == null ||
								item.recomendaciones == undefined ||
								item.recomendaciones.length == 0
							"
							v-on="on"
							small
							@click="showRecomendaciones(item)"
						>
							mdi-eye
						</v-icon>
					</template>
					<span class="white--text">Ver Recomendaciones</span>
				</v-tooltip> -->
				<v-tooltip
					v-if="permisoVista('cat-mantenimientocorrectivo', 'd')"
					
					bottom
					color="black"
					class="white--text"
				>
					<template v-slot:activator="{ on }">
						<v-icon
							v-on="on"
							small
							:disabled="loading"
							class="mr-1"
							@click="removeMantenimiento(item)"
						>
							delete
						</v-icon>
					</template>
					<span class="white--text">Eliminar</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-col>
</template>

<script>
import axios from "axios";
import Lista from '../../OTS/Lista.vue';
import ListadoPorHacer from './ListadoPorHacer.vue';
import Comprobaciones from '../Comprobaciones.vue';
import Recomendaciones from './Recomendaciones.vue';
import { mapState } from 'vuex';
import MaquinaInsAutocomplete from '../MaquinaInsAutocomplete.vue';
import HeaderInfoMantenimiento from './HeaderInfoMantenimiento.vue';
import MenuDatepicker from '../../../MenuDatepicker.vue';
import VAutocompleteTextarea from '../../../VAutocompleteTextarea.vue';
import AsignacionMantenimiento from '../AsignacionMantenimiento.vue';
import KilometrajeTextfield from '../KilometrajeTextfield.vue';

export default {
	components: {
		Lista,
		ListadoPorHacer,
		Comprobaciones,
		Recomendaciones,
		MaquinaInsAutocomplete,
		HeaderInfoMantenimiento,
		MenuDatepicker,
		VAutocompleteTextarea,
		AsignacionMantenimiento,
		KilometrajeTextfield
	},
	data: () => ({
		search: null,
		loadingKilometrajeMin: false,
		loadingComprobaciones: false,
		loadingProveedores: false,
    	proveedores: [],
		loadingRecomendaciones: false,
		recomendaciones: [],
		proveedores: [],
		loadingCategorias: false,
    	categorias: [],
		erroresItem: {
			errorCodigo: null,
			errorNombre: null,
			errorModelo: null,
			errorSubdepto: null,
			errorAsignados: null,
			errorHorarios: null,
			errorCategoria: null,
			errorInstancia: null,
			errorFechaInicio: null,
			errorTiempoEstimado: null,
			errorFechaFin: null,
			errorResponsable: null,
			errorMecanicos: null,
			errorImporteNeto: null,
			errorKilometraje: null,
			errorHorasTrabajadas: null,
			errorRecomendacion: null,
			errorProveedor: null
		},
		tipoEdicion: 1,
		subdepartamentos: [],
		savingProgrammed: false,
		dialogTarea2: false,
		instancias: [],
		savingReported: false,
		erroresReported: [],
		dialogTarea: false,
		guardando: false,
		dialog: false,
		dialogInsumos: false,
		editedItem: null,
		defaultEditedItem: {
			idMantenimientoCorrectivo: null,
			idMantenimientoCategoria: null,
			idSubdepartamento: null,
			codigo: "",
			nombre: "",
			descripcion: "",
			idMaquina: null,
			comprobaciones: [],
			numPersonas: 1,
			asignados: [],
			label: "",
			responsable: null,
			mecanicos: [],
			instancia: null,
			inicio: null,
			fin: null,
			recomendacion: "",
			fechaInicioPlaneado: null,
			tiempoEstimado: null,
			idProveedor: null,
			importeNeto: null,
			kilometraje: null,
			horasTrabajadas: null,
			tipoAsignacion: 1,
      		idMantenimientoProveedor: null,
		},
		reportedItem: null,
		personas: [],
		maquinas: [],
		headers: [
			{
				text: "Enunciado",
				align: "left",
				sortable: false,
				value: "enunciado",
				width: "80%,",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "20%,",
			},
		],
		alerta: false,
		errores: [],
		horarios: [],
		loading: false,
		headersHorario: [
			{
				text: "Código",
				align: "center",
				sortable: false,
				value: "codigo",
			},
			{
				text: "Nombre",
				align: "center",
				sortable: false,
				value: "nombre",
			},
			{
				text: "Categoría Mantenimiento",
				align: "center",
				sortable: false,
				value: "nombreCategoria",
			},
			{
				text: "Categoría Máquina",
				align: "center",
				sortable: false,
				value: "categoriaMaquina"
			},
			{
				text: "Modelo",
				align: "center",
				sortable: false,
				value: "maquina",
			},
			{
				text: "Acciones",
				align: "center",
				sortable: false,
				value: "actions",
				width: "10%,",
			},
		],
		dialogEliminar: false,
		eliminarItem: null,
		deleting: false,
		saving: false,
		bloques:[
			{
				value:1,
				text: "1 Minuto"
			},
			{
				value:2,
				text: "2 Minutos"
			},
			{
				value:5,
				text: "5 Minutos"
			},
			{
				value:10,
				text: "10 Minutos"
			},
			{
				value:30,
				text: "30 Minutos"
			},
			{
				value:60,
				text: "1 Hora"
			},
		],
	}),
	beforeMount(){
		this.editedItem = structuredClone(this.defaultEditedItem);
		this.eliminarItem = structuredClone(this.defaultEditedItem);
		this.reportedItem = structuredClone(this.defaultEditedItem);
	},
	mounted() {
		this.initialize();
	},
	computed: {
		...mapState(['nombreEmpresa']),
		computeColImporteNeto(){
			return this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'basculas',this.reportedItem.idMaquina)
				? 12
				: 6;
		},
		esIndasa(){
			return this.nombreEmpresa.toUpperCase() == "INDASA";
		},
		today(){
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10);
		},
		showSubdepto(){
			if(this.esIndasa) return false;
			return true;
		},
		textButtonSave(){
			return this.editedItem.idMantenimientoCorrectivo == null
				? 'Agregar'
				: this.tipoEdicion == 1
					? 'Actualizar'
					: 'Clonar'
		},
		computedIns() {
			const ins = this.instancias.filter(
				(instancia) =>
					instancia.idMaquina === this.reportedItem.idMaquina
			);
			return ins;
		},
	},
	methods: {
		nombreSubdepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : nombre.trim(),
    	nombrePersona: ({ nombre, paterno, materno }) =>
			`${nombre ? `${nombre} ` : ""}${paterno ? `${paterno} ` : ""}${
				materno ? `${materno} ` : ""
			}`.trim(),
		onChangeMaquinaIns(){
			if(this.esIndasa && this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.reportedItem.idMaquina)){
				this.erroresItem.errorKilometraje = null;
				this.$refs.kilometrajeTextfield.getUltimoKilometrajeReportado(this.reportedItem.instancia);
			}
		},
		onChangeTipoAsignacion(){
			this.editedItem.numPersonas = 1;
			this.editedItem.asignados = [];
			this.editedItem.idMantenimientoProveedor = null;
		},
		getRecomendaciones(){
			this.loadingRecomendaciones = true;

			axios
				.get('/Mantenimientos/GetRecomendacionesUnicas')
				.then( (response) => {
					this.recomendaciones = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingRecomendaciones = false;
				});
		},
		resetErrores(){
			this.erroresReported = [];
			this.errores = [];
			Object.keys(this.erroresItem).forEach( (key) => this.erroresItem[key] = null );
		},
		showProgramar(item){
			this.reportedItem = structuredClone(item);
			this.dialogProgramar = true;
		},
		showRecomendaciones(item){
			this.$refs.recomen.showDialogRecomendaciones(item);
		},
		guardarMantenimiento(){
			// this.$refs.todo.togglePanel(null);
			this.save();
		},
		getInstancias() {
			axios
				.get("/MaquinaInstancias")
				.then((response) => {
					this.instancias = response.data;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		validateTipoAsignacion(){
			if(this.reportedItem.tipoAsignacion == 1){
				if (
					this.reportedItem.mecanicos == null ||
					this.reportedItem.mecanicos?.length == 0
				) {
					this.$set(this.erroresItem, "errorMecanicos", "Requerido al menos uno");
				}
			}
			else {
				if(this.reportedItem.idMantenimientoProveedor == null){
					this.$set(this.erroresItem, "errorProveedor", "Requerido");
				}
			}
		},
		validReported() {
			this.resetErrores();

			if (this.reportedItem.responsable == null) {
				this.erroresItem.errorResponsable = "Requerido";
			}
			
			this.validateTipoAsignacion();
			
			if (this.reportedItem.inicio == null) {
				this.erroresItem.errorFechaInicio = "Requerido";
			}
			if (this.reportedItem.fin == null) {
				this.erroresItem.errorFechaFin = "Requerido";
			}

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}

			if(this.esIndasa){
				if(this.reportedItem.importeNeto != null){
					const parsedImporteNeto = parseFloat(this.reportedItem.importeNeto);
					if(isNaN(parsedImporteNeto)) this.erroresItem.errorImporteNeto = "Invalido";
					else if (parsedImporteNeto < 0) this.erroresItem.errorImporteNeto = "Debe ser mayor o igual a 0";
				}
	
				if (this.$utils.mantenimientos.esCategoriaEspecifica(this.maquinas,'transporte',this.reportedItem.idMaquina)) {
					this.erroresItem.errorKilometraje = this.$utils.mantenimientos.validateKilometraje(this.reportedItem);
				}
	
				if(this.reportedItem.horasTrabajadas != null){
					const parsedHorasTrabajadas = parseFloat(this.reportedItem.horasTrabajadas);
					if(isNaN(parsedHorasTrabajadas)) this.erroresItem.errorHorasTrabajadas = "Invalido";
					else if (parsedHorasTrabajadas < 0) this.erroresItem.errorHorasTrabajadas = "Debe ser mayor o igual a 0";
				}

				if(!this.reportedItem.recomendacion) this.erroresItem.errorRecomendacion = "Requerido"
			}

			const comprobacionesValidas = this.$refs.checklist.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.erroresReported = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		validReported2() {
			this.resetErrores();

			if (this.reportedItem.responsable == null) {
				this.erroresItem.errorResponsable = "Requerido";
			}

			this.validateTipoAsignacion();

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
			if(hayErrores){
				this.erroresReported = ["Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo"];
			}

			return !hayErrores;
		},
		validProgrammed(){
			this.resetErrores();

			if ( this.reportedItem.instancia == null ){
				this.erroresItem.errorInstancia = "Requerido";
			}
			
			if (this.reportedItem.fechaInicioPlaneado == null){
				this.erroresItem.errorFechaInicio = "Requerido";
			}

			if (
				this.reportedItem.tiempoEstimado === null ||
				this.reportedItem.tiempoEstimado == undefined
			) {
				this.erroresItem.errorTiempoEstimado = "Requerido";
			}

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null );
			if(hayErrores){
				this.erroresReported.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.erroresReported = [];
				}, 2000);
			}

			return !hayErrores;
		},
		programarMantenimiento(){
			if( !this.validProgrammed() ) return;
			const obj = {
				idMantenimientoCorrectivo: this.reportedItem.idMantenimientoCorrectivo,
				idMaquinaIns: this.reportedItem.instancia,
				inicioPlaneado: this.reportedItem.fechaInicioPlaneado,
				tiempoEstimado: this.reportedItem.tiempoEstimado
			};
			this.savingProgrammed = true;
			
			axios
				.post("/Mantenimientos/ProgramarCorrectivo",obj)
				.then( () => {
					this.savingProgrammed = false;
					this.closeProgrammed();
					this.initialize();
				})
				.catch( error => {
					this.savingProgrammed = false;
					console.log( error );
				});
		},
		completeReported() {
			if (!this.validReported()) return;
      		const item = this.$refs.checklist.terminarTarea();
			const obj = {
				idMantenimientoCorrectivo: this.reportedItem.idMantenimientoCorrectivo,
				idsMaquinaIns: this.reportedItem.instancia,
				idPersonaResponsable: this.reportedItem.responsable,
				mecanicos: this.reportedItem.mecanicos,
				fechaInicioRealizo: this.reportedItem.inicio,
				fechaFinRealizo: this.reportedItem.fin,
				recomendacion: this.reportedItem.recomendacion,
				comprobaciones: item.comprobaciones,
				importeNeto: parseFloat(this.reportedItem.importeNeto) || null,
				kilometraje: parseFloat(this.reportedItem.kilometraje) || null,
				horasTrabajadas: parseFloat(this.reportedItem.horasTrabajadas) || null,
				tipoAsignacion: this.reportedItem.tipoAsignacion,
				idMantenimientoProveedor: this.reportedItem.idMantenimientoProveedor
			};

			this.savingReported = true;
			axios
				.post(
					"/Mantenimientos/CreaMantenimientoCorrectivoTarea",
					obj
				)
				.then(() => {
					this.savingReported = false;
					this.closeReported();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.savingReported = false;
				});
		},
		completeReported2() {
			if (!this.validReported2()) return; 
			var obj = {
				idMantenimientoCorrectivo: this.reportedItem.idMantenimientoCorrectivo,
				idMaquinaIns: this.reportedItem.instancia,
				idPersonaResponsable: this.reportedItem.responsable,
				mecanicos: this.reportedItem.mecanicos,
				tipoAsignacion: this.reportedItem.tipoAsignacion,
				idMantenimientoProveedor: this.reportedItem.idMantenimientoProveedor
			};

			this.savingReported = true;
			axios
				.post(
					"/Mantenimientos/IniciarTareaMantenimientoCorrectivo",
					obj
				)
				.then(() => {
					this.savingReported = false;
					this.closeReported();
					this.initialize();
				})
				.catch((error) => {
					console.log(error);
					this.savingReported = false;
				});
		},
		getPersonas() {
			axios
				.get("/Mantenimientos/PersonasMantenimiento")
				.then((response) => {
					this.personas = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.personas = [];
				});
		},
		reportarTarea(item) {
			this.reportedItem = structuredClone(item);
			this.reportedItem.mecanicos = item.asignados.slice();
			this.dialogTarea = true;
		},
		reportarTarea2(item){
			this.reportedItem = structuredClone(item);
			this.reportedItem.mecanicos = item.asignados.slice();
			this.reportedItem.label = this.reportedLabel();
			this.dialogTarea2 = true;
		},
		maquinaLabel(idMaquina) {
			const maquina = this.maquinas.find(
				(maquina) => maquina.idMaquina === idMaquina
			);
			return this.$utils.isValid(maquina)
				? maquina.marca + " - " + maquina.modelo
				: "N/A";
		},
		reportedLabel() {
			return `[${this.reportedItem.codigo}] ${
				this.reportedItem.nombre
			} - ${this.maquinaLabel(this.reportedItem.idMaquina)}`;
		},
		marcaModelo: (item) => item.marca + " - " + item.modelo,
		initialize() {
			this.getMaquinas();
			this.getMantenimientos();
			this.getPersonas();
			this.getInstancias();
			this.getSubdepartamentos();
			this.getCategoriasMantenimiento();
			this.getRecomendaciones();
			this.getProveedoresMantenimiento();
		},
		getProveedoresMantenimiento(){
			this.loadingProveedores = true;

			axios
				.get('/MantenimientoProveedores')
				.then( (response) => {
					this.proveedores = response.data;
				})
				.catch(console.log)
				.finally( () => {
					this.loadingProveedores = false;
				});
		},
		getCategoriasMantenimiento() {
			this.loadingCategorias = true;

			axios
				.get("/MantenimientoCategorias")
				.then((response) => {
					this.categorias = response.data;
				})
				.catch((error) => {
					console.log(error);
					this.categorias = [];
				})
				.finally(() => {
					this.loadingCategorias = false;
				});
		},
		getSubdepartamentos(){
			axios
				.get('/Subdepartamento')
				.then( response => {
					this.subdepartamentos = response.data;
				})
				.catch(console.log)
		},
		closeMantenimiento(){
			this.dialog = false;
			setTimeout(() => {
				this.editedItem = structuredClone(this.defaultEditedItem);
				this.resetErrores();
				this.tipoEdicion = 1;
				this.$refs.todo.togglePanel(0);
			}, 200);
		},
		closeReported() {
			this.dialogTarea = false;
			this.dialogTarea2 = false;
			this.reportedItem = structuredClone(this.defaultEditedItem);
			this.resetErrores();
		},
		closeProgrammed() {
			this.dialogProgramar = false;
			this.reportedItem = structuredClone(this.defaultEditedItem);
			this.resetErrores();
		},
		getMaquinas() {
			axios
				.get("/Maquinas")
				.then((response) => {
					this.maquinas = response.data.filter(function (el) {
						return el.esMaquina == true;
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getMantenimientos() {
			this.loading = true;
			axios
				.get("/Mantenimientos/MantenimientoCorrectivos")
				.then((response) => {
					this.loading = false;
					this.horarios = response.data;
				})
				.catch((error) => {
					this.loading = false;
					console.log(error);
				});
		},
		editMantenimiento(item,tipoEdicion = 1) {
			this.editedItem = structuredClone(item);
			this.editedItem.comprobaciones = this.$utils.mantenimientos.mapComprobaciones(structuredClone(item.comprobaciones));
			this.tipoEdicion = tipoEdicion;
			if(this.tipoEdicion == 2){
				this.editedItem.codigo = null;
				this.editedItem.nombre = null;
			}
			this.dialog = true;
		},
		removeMantenimiento(item) {
			this.dialogEliminar = true;
			this.eliminarItem = structuredClone(item);
		},
		runRemoverMantenimiento() {
			this.deleting = true;
			axios
				.put(
					"/Mantenimientos/EliminarMantenimientoCorrectivo/" +
						this.eliminarItem.idMantenimientoCorrectivo
				)
				.then(() => {
					this.deleting = false;
					this.closeEliminarMantenimiento();
					this.getMantenimientos();
				})
				.catch((error) => {
					this.deleting = false;
					console.log(error);
				});
		},
		closeEliminarMantenimiento() {
			this.dialogEliminar = false;
			this.eliminarItem = structuredClone(this.defaultEditedItem);
		},
		validate() {
			this.resetErrores();

			if (
				!this.editedItem.codigo ||
				this.editedItem.codigo.length < 5 ||
				this.editedItem.codigo.length > 100
			) {
				this.erroresItem.errorCodigo = "Debe tener entre 5 y 100 caracteres";
			}
			if (
				!this.editedItem.nombre ||
				this.editedItem.nombre.length < 5 ||
				this.editedItem.nombre.length > 1000
			) {
				this.erroresItem.errorNombre = "Debe tener entre 5 y 100 caracteres";
			}

			if(this.esIndasa){
				if(this.editedItem.idMantenimientoCategoria == null){
					this.erroresItem.errorCategoria = "Requerido";
				}
			}
			
			if (this.editedItem.idMaquina == null) {
				this.erroresItem.errorModelo = "Requerido";
			}

			if(!this.esIndasa){
				if(this.editedItem.idSubdepartamento == null){
					this.erroresItem.errorSubdepto = "Requerido";
				}
			}

			if(this.editedItem.numPersonas > 0){
				if(this.editedItem.asignados.length > 0 && this.editedItem.asignados.length != this.editedItem.numPersonas){
					this.erroresItem.errorAsignados = "Debe ser igual al número de personas requeridas";
				}
			}

			const comprobacionesValidas = this.$refs.todo.validate();

			const hayErrores = Object.values(this.erroresItem).some( (value) => value != null ) || !comprobacionesValidas;
			if(hayErrores){
				this.errores.push("Hay uno o mas errores, porfavor reviselos y vuelva a intentarlo");
				setTimeout(() => {
					this.errores = [];
				}, 3000);
			}

			return !hayErrores;
		},
		save() {
			if (!this.validate()) return; 
			this.guardando = true;

			if(this.esIndasa){
				this.$utils.mantenimientos.asignarSubdeptoDefault(this.subdepartamentos,this.editedItem);
			}
			
			const comprobaciones = this.$refs.todo.getListado();
			
			axios({
				url: this.editedItem.idMantenimientoCorrectivo == null || this.tipoEdicion == 2
					? "/Mantenimientos/CrearMantenimientoCorrectivo"
					: "/Mantenimientos/ActualizarMantenimientoCorrectivo",
				method: this.editedItem.idMantenimientoCorrectivo == null || this.tipoEdicion == 2
					? "POST"
					: "PUT",
				data: {
					idMantenimientoCorrectivo: this.editedItem.idMantenimientoCorrectivo,
					idMaquina: this.editedItem.idMaquina,
					codigo: this.editedItem.codigo,
					nombre: this.editedItem.nombre,
					descripcion: this.editedItem.descripcion,
					idSubdepartamento: this.editedItem.idSubdepartamento,
					comprobaciones,
					listaComprobacion: comprobaciones,
					numPersonas: this.editedItem.numPersonas,
					asignados: this.editedItem.asignados,
        			idMantenimientoCategoria: this.editedItem.idMantenimientoCategoria,
					tipoAsignacion: this.editedItem.tipoAsignacion,
					idMantenimientoProveedor: this.editedItem.idMantenimientoProveedor
				}
			})
				.then(() => {
					this.guardando = false;
					this.closeMantenimiento();
					this.getMantenimientos();
				})
				.catch((error) => {
					console.log(error);
					this.guardando = false;
					if (error.response?.status == 409) {
						this.$set(this.erroresItem, "errorCodigo", "Ya existe");
					}
				});
		},
	},
};
</script>