<template>
	<v-data-table
		:items="comprobaciones"
		:headers="headerComprobaciones"
		:loading="loading"
		loading-text="Cargando... Espere, Por Favor."
		hide-default-footer
		:items-per-page="-1"
		:footer-props="{
			disableItemsPerPage: true,
			disablePagination: true,
		}"
		:disable-sort="true"
	>
        <template v-slot:[`item.respuesta`]="{ item }">
            {{ item.respuesta || "N/A" }}
        </template>
		<template v-slot:[`item.satisfactoria`]="{ item }">
			<p
				class="mb-0 font-weight-bold"
				:class="{
					'red--text': !item.satisfactoria,
					'green--text': item.satisfactoria,
				}"
			>
				{{ item.satisfactoria ? "SI" : "NO" }}
			</p>
		</template>
        <template v-slot:[`item.resuelta`]="{ item }">
			<p
				class="mb-0 font-weight-bold"
				:class="{
					'red--text': !item.resuelta,
					'green--text': item.resuelta,
				}"
			>
				{{ item.resuelta ? "SI" : "NO" }}
			</p>
		</template>
		<template v-slot:[`item.comentario`]="{ item }">
			<p class="mb-0">
				{{ item.comentario == null ? "N/A" : item.comentario }}
			</p>
		</template>
		<template v-slot:[`item.documentos`]="{ item }">
            <template v-if="item.documentos == null || item.documentos.length == 0">
                N/A
            </template>
            <v-chip-group column class="centered-chips">
                <v-chip
                    v-for="(documento, idx) in item.documentos"
                    :key="`docs-${idx}`"
                    outlined
                    color="blue"
                    :href="`/api/Documentos/Descargar/${(typeof documento == 'string') ? documento : documento.nombre}?access_token=${token}`"
                >
                    <v-icon left>mdi-file-outline</v-icon>
                    {{ (typeof documento == "string") ? $utils.strings.truncateElipsis(documento) : $utils.strings.truncateElipsis(documento.nombre) }}
                    <v-icon right size="18" class="pr-1">mdi-download</v-icon>
                </v-chip>
            </v-chip-group>
		</template>
		<template v-slot:[`item.reparacion`]="{ item }">
			<p v-if="item.reparacion == null" class="mb-0">N/A</p>
			<template v-else>
                <v-container fluid class="pa-0">
                    <v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="
								item.reparacion.tipo == null
									? 'N/A'
									: item.reparacion.tipo
							"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Tipo</p>
							</template>
						</v-text-field>
					</v-col>
                    <v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="getStatusReparacion(item.reparacion)"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Estado</p>
							</template>
						</v-text-field>
					</v-col>
                    <v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="`[${item.reparacion.codigo}] ${item.reparacion.nombre}`"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Nombre</p>
							</template>
						</v-text-field>
					</v-col>
                    <v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="
								item.reparacion.fechaInicioProgramada == null
									? 'N/A'
									: item.reparacion.fechaInicioProgramada
							"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Fecha Programada</p>
							</template>
						</v-text-field>
					</v-col>
                    <v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="
								item.reparacion.fechaInicioRealizo == null
									? 'N/A'
									: item.reparacion.fechaInicioRealizo
							"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Inicio Realizo</p>
							</template>
						</v-text-field>
					</v-col>
					<v-col cols="12" class="pa-0 py-1">
						<v-text-field
							:value="
								item.reparacion.fechaFinRealizo == null
									? 'N/A'
									: item.reparacion.fechaFinRealizo
							"
							readonly
							hide-details
						>
							<template v-slot:label>
								<p>Fin Realizo</p>
							</template>
						</v-text-field>
					</v-col>
                </v-container>
			</template>
		</template>
	</v-data-table>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		comprobaciones: {
			type: Array,
			default: () => [],
		},
        loading: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {
			headerComprobaciones: [
				{
					text: "Comprobación",
					align: "center",
					sort: false,
					value: "comprobacion",
				},
                {
					text: "Respuesta",
					align: "center",
					sort: false,
					value: "respuesta",
				},
				{
					text: "Satisfactoria",
					align: "center",
					sort: false,
					value: "satisfactoria",
				},
				{
					text: "Comentario",
					align: "center",
					sort: false,
					value: "comentario",
				},
				{
					text: "Evidencias",
					align: "center",
					sort: false,
					value: "documentos",
				},
                {
					text: "Resuelta",
					align: "center",
					sort: false,
					value: "resuelta",
				},
				{
					text: "Reparación",
					align: "center",
					sort: false,
					value: "reparacion",
				},
			],
		};
	},
    computed: {
        ...mapState(['token'])
    },
    methods: {
        getStatusReparacion(reparacion) {
			if (
				reparacion.fechaInicioRealizo == null &&
				reparacion.fechaFinRealizo == null
			) {
				return "Programada";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo == null
			) {
				return "En Progreso";
			} else if (
				reparacion.fechaInicioRealizo != null &&
				reparacion.fechaFinRealizo != null
			) {
				return "Realizada";
			}
		},
    }
};
</script>
<style scoped>
.centered-chips >>> .v-slide-group__content {
	justify-content: center;
}
</style>